<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"
      ><h1>Reporte de Variación de Costo de Inventario</h1></v-row
    >
    <v-row>
      <v-col>
        <v-row>Condiciones</v-row>
        <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
        <v-row>Suc: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="varcostinvlst"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="500"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">Total: ${{ total.toFixed(2) }} </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headers: [
      { text: "Itemno Ini", value: "ITEMNO_INI" },
      { text: "InvIni", value: "INV_INI" },
      { text: "MontoIni", value: "MONTO_INI" },
      { text: "Itemno Mov", value: "ITEMNO_MOV" },
      { text: "InvMov", value: "INV_MOV" },
      { text: "MontoMov", value: "MONTO_MOV" },
      { text: "Itemno Fin", value: "ITEMNO_FIN" },
      { text: "InvFin", value: "INV_FIN" },
      { text: "MontoFin", value: "MONTO_FIN" },
      { text: "InvTotal", value: "INV_TOTAL" },
      { text: "Monto", value: "MONTO_TOTAL" },
    ],
    varcostinvlst: [],
    total: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    date1Format() {
      var fecha1 = "";
      fecha1 = moment(this.bodyForFetch.dtFechaInicio).format("YYYY/MM/DD");
      return fecha1;
    },
    date2Format() {
      var fecha2 = "";
      fecha2 = moment(this.bodyForFetch.dtFechaFin).format("YYYY/MM/DD");
      return fecha2;
    },
    downloadexcel() {
      //change
      let arr = this.varcostinvlst;
      let data = [
        {
          sheet: "Variacion de Costos de Inventario",
          columns: [
            { label: "Itemno Ini", value: "ITEMNO_INI" },
            { label: "InvIni", value: "INV_INI" },
            { label: "MontoIni", value: "MONTO_INI" },
            { label: "Itemno Mov", value: "ITEMNO_MOV" },
            { label: "InvMov", value: "INV_MOV" },
            { label: "MontoMov", value: "MONTO_MOV" },
            { label: "Itemno Fin", value: "ITEMNO_FIN" },
            { label: "InvFin", value: "INV_FIN" },
            { label: "MontoFin", value: "MONTO_FIN" },
            { label: "InvTotal", value: "INV_TOTAL" },
            { label: "Monto", value: "MONTO_TOTAL" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "VariacionCostoInventario", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    const response = await fetch(
      this.url + "/inventario/variacion_costos_inventario",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      }
    );
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      for (var counter = 0; counter < value.length; counter++) {
        this.total += value[counter].MONTO_TOTAL;
      }
      this.varcostinvlst = value;
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
