<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col>
        <h2 align="center">Reporte Contra Recibo</h2>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",
  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      respuestaDeApi: [],
      headers: [
        {
          text: "Clave",
          value: "Clave",
        },
        {
          text: "Sucursal",
          value: "sucursal",
        },
        {
          text: "Codigo del Proveedor",
          value: "codigodelprov",
        },
        {
          text: "Item No",
          value: "itemno",
        },
        {
          text: "Descripcion",
          value: "descripcion",
        },
        {
          text: "Cantidad Facturada",
          value: "cantfacturada",
        },
        {
          text: "Precio a pagar",
          value: "preciopagar",
        },
        {
          text: "Cantidad Surtida",
          value: "cantsurtida",
        },
        {
          text: "Costo Unitario",
          value: "costounit",
        },
        {
          text: "Fecha de Facturacion",
          value: "FechaFactura",
        },
        {
          text: "Fecha de Recibido",
          value: "FechaRecibo",
        },
        {
          text: "Cantidad pedida",
          value: "cantpedida",
        },
        {
          text: "Razon Social",
          value: "razonsocial",
        },
        {
          text: "Folio de Factura",
          value: "FolioFactura",
        },
        {
          text: "Folio de Compra",
          value: "FolioCompra",
        },
        {
          text: "Folio de recibo de la compra",
          value: "FolioReciboCompra",
        },
      ],
    };
  },
  computed: {
    ...mapState(["bodyForFetch"]),
  },
  methods: {
    ...mapActions("reportereimpcontrarecibo", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReporteReimpContraRecibo",
          columns: [
            { label: "Clave", value: "Clave" },
            { label: "Sucursal", value: "sucursal" },
            { label: "Codigo del Proveedor", value: "codigodelprov" },
            { label: "Item No", value: "itemno" },
            { label: "Descripcion", value: "descripcion" },
            { label: "Cantidad Facturada", value: "cantfacturada" },
            { label: "Precio a Pagar", value: "preciopagar" },
            { label: "Cantidad Surtida", value: "cantsurtida" },
            { label: "Costo Unitario", value: "costounit" },
            { label: "Fecha de Factura", value: "FechaFactura" },
            { label: "Fecha de Recibido", value: "FechaRecibo" },
            { label: "Cantidad Pedida", value: "cantpedida" },
            { label: "Razon Social", value: "razonsocial" },
            { label: "Folio de Factura", value: "FolioFactura" },
            { label: "Folio de Compra", value: "FolioCompra" },
            { label: "Folio de Recibo de compra", value: "FolioReciboCompra" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteReimpContraRecibo", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    //console.log(arr);
    //console.log(this.bodyForFetch);
    if (arr.resultado.length > 0) {
      this.loadingt = false;
      this.respuestaDeApi = arr.resultado;
    } else {
      this.loadingt = false;
      this.respuestaDeApi = [];
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
