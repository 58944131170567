<template lang="">
  <v-container>
    <v-row><h1>Articulos 3 Meses sin Venta</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-subtitle>Sucursal</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group dense v-model="radioSelected" column>
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <v-list
                      :disabled="radioSelected == 1"
                      dense
                      :color="radioSelected == 1 ? 'grey lighten-2' : ''"
                    >
                      <v-list-item-group
                        multiple
                        v-model="sucLstSel"
                        color="primary"
                      >
                        <v-list-item v-for="(item, i) in sucursales" :key="i">
                          <v-list-item-content>
                            <v-list-item-title
                              :class="{ listtext: radioSelected == 0 }"
                              v-text="item.sucursal"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-subtitle>Categoria</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group dense v-model="todocatRadio" column>
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <v-list
                      :disabled="todocatRadio == 1"
                      dense
                      :color="todocatRadio == 1 ? 'grey lighten-2' : ''"
                    >
                      <v-list-item-group
                        multiple
                        v-model="catLstSel"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(item, i) in categoriaList"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              :class="{ listtext: todocatRadio == 0 }"
                              v-text="item.Categorias"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-subtitle>Almacen</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group dense v-model="todoAlmRadio" column>
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <v-list
                      :disabled="todoAlmRadio == 1"
                      dense
                      :color="todoAlmRadio == 1 ? 'grey lighten-2' : ''"
                    >
                      <v-list-item-group
                        multiple
                        v-model="almLstSel"
                        color="primary"
                      >
                        <v-list-item v-for="(item, i) in almacenList" :key="i">
                          <v-list-item-content>
                            <v-list-item-title
                              :class="{ listtext: todoAlmRadio == 0 }"
                              v-text="item.Almacenes"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">Meses Sin Movimiento</v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="meses"
                  solo
                  hide-details
                  single-line
                  dense
                  type="number"
                  disabledº
                ></v-text-field>
              </v-col>
              <v-col cols="1">Desde la Fecha</v-col>
              <v-col cols="3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn @click="abrirReporte()">Reporte</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    sucLstSel: [],
    catLstSel: [],
    todocatRadio: 1,
    categoriaList: [],
    almLstSel: [],
    todoAlmRadio: 1,
    almacenList: [],
    meses: 3,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "radioSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
  },
  methods: {
    async traerListaCategorias() {
      try {
        const response = await fetch(
          this.url + "/inventario/lista_Categorias",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.categoriaList = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async traerListaAlmacenes() {
      try {
        const response = await fetch(this.url + "/inventario/lista_Almacenes", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.almacenList = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    abrirReporte() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteArticulosSinMov",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      var selCatList = "";
      var selAlmList = "";
      var selSucList = "";
      var catArr = [];
      var almArr = [];
      for (var i = 0; i < this.catLstSel.length; i++) {
        catArr = this.categoriaList[this.catLstSel[i]].Categorias.split("-");
        selCatList += catArr[0] + ",";
      }
      for (var i = 0; i < this.almLstSel.length; i++) {
        almArr = this.almacenList[this.almLstSel[i]].Almacenes.split(" - ");
        selAlmList += almArr[0] + ",";
      }
      for (var i = 0; i < this.sucLstSel.length; i++) {
        selSucList += this.sucursales[this.sucLstSel[i]].sucursal + ",";
      }
      this.bodyForFetch = {
        TodaSucursal: this.radioSelected,
        strSucursal: this.radioSelected == 1 ? "TODOS" : selSucList,
        TodaCategoria: this.todocatRadio,
        strCategoria: this.todocatRadio == 1 ? "TODOS" : selCatList,
        TodoAlmacen: this.todoAlmRadio,
        strAlmacen: this.todoAlmRadio == 1 ? "TODOS" : selAlmList,
        intMeses: this.meses,
        rangoFecha: this.date.replaceAll("-", ""),
      };
      //console.log(this.bodyForFetch);
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setradioSelected", 1);
    this.traerListaCategorias();
    this.traerListaAlmacenes();
  },
};
</script>
<style scoped>
.v-list {
  height: 250px; /* or any height you want */
  overflow-y: auto;
}
.toprow {
  height: 100px;
}
.listtext {
  color: #616161;
}
</style>
