<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"><h1>Reporte Gastos de Sucursal</h1></v-row>
    <v-row justify="end">Fecha de Impresion: {{ dateNow }}</v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="gastoslst"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="gastoslst.length"
          sort-by="Sucursal"
          group-by="Sucursal"
          show-group-by
          hide-default-footer
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">Suma Total: ${{ total.toFixed(2) }} </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY"),
    headers: [
      { text: "Suc", value: "Sucursal" },
      { text: "Fecha", value: "Fecha" },
      { text: "Proveedor", value: "Proveedor" },
      { text: "Factura", value: "Factura" },
      { text: "Descripción", value: "Descripcion" },
      { text: "Motivo", value: "Motivo" },
      { text: "Monto", value: "Monto" },
    ],
    gastoslst: [],
    total: 0,
    subtotal: {},
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    downloadexcel() {
      let arr = this.gastoslst;
      let data = [
        {
          sheet: "Gastos de Sucursal",
          columns: [
            { label: "Suc", value: "Sucursal" },
            { label: "Fecha", value: "Fecha" },
            { label: "Proveedor", value: "Proveedor" },
            { label: "Factura", value: "Factura" },
            { label: "Descripción", value: "Descripcion" },
            { label: "Motivo", value: "Motivo" },
            { label: "Monto", value: "Monto" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteGastosContado", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    const response = await fetch(this.url + "/compras/trae_gastos_contados", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": this.token,
      },
      body: JSON.stringify(this.bodyForFetch),
    });
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      for (var counter = 0; counter < value.length; counter++) {
        if (!this.subtotal.hasOwnProperty(value[counter].Sucursal)) {
          this.subtotal[value[counter].Sucursal] = 0;
        }
        this.subtotal[value[counter].Sucursal] += value[counter].Monto;
        this.total += value[counter].Monto;
      }

      for (let personalSucursal in this.subtotal) {
        value.push({
          Descripcion: null,
          Factura: null,
          Fecha: null,
          Monto: this.subtotal[personalSucursal].toFixed(2),
          Motivo: "Sub Total",
          Proveedor: null,
          Saldo: null,
          Sucursal: personalSucursal,
          maquina: null,
        });
        this.gastoslst = value;
      }
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
