<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card color="#EEF2F2">
            <v-card-text>
              <v-form>
                <v-card-title>
                  <h1>Reporte de Ventas CAT</h1>
                </v-card-title>
                <h3>Sucursales:</h3>
                <v-autocomplete
                  v-model="sucSelected"
                  :items="sucursales"
                  item-text="sucursal"
                  item-value="sucursal"
                  :rules="[(v) => !!v || 'Seleccione sucursal']"
                  solo
                  label="Sucursales"
                  required
                  dense
                >
                </v-autocomplete>
                <v-card-actions>
                  <v-row cols="6">
                    <v-menu
                      v-model="menuFechaInicio"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="fechaIn"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaIn"
                        @input="menuFechaInicio = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      v-model="menuTiempoInicio"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="tiempoInicio"
                          label="Hora inicial"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="tiempoInicio"
                        :allowed-hours="allowedHours"
                        :allowed-minutes="allowedMinutes"
                        :allowed-seconds="allowedSeconds"
                        format="ampm"
                        min="00:00:00"
                        max="23:59:59"
                        scrollable
                        use-seconds
                      ></v-time-picker>
                    </v-menu>
                  </v-row>
                </v-card-actions>
                <v-card-actions>
                  <v-row cols="6">
                    <v-menu
                      v-model="menuFechaFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="fechaFn"
                          label="Fecha Fin"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaFn"
                        @input="menuFechaFin = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      v-model="menuTiempoFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="tiempoFin"
                          label="Hora final"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="tiempoFin"
                        :allowed-hours="allowedHours"
                        :allowed-minutes="allowedMinutes"
                        :allowed-seconds="allowedSeconds"
                        format="ampm"
                        min="00:00:00"
                        max="23:59:59"
                        scrollable
                        use-seconds
                      ></v-time-picker>
                    </v-menu>
                  </v-row>
                </v-card-actions>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="abrirReporteVentasCAT()"
                ><v-icon dense>mdi-printer</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaVentasCAT",
  data: () => ({
    fechaIn: "",
    fechaFn: "",
    menuFechaInicio: "",
    menuFechaFin: "",
    tiempoInicio: "00:00:00",
    tiempoFin: "",
    menuTiempoInicio: "",
    menuTiempoFin: "",
  }),
  computed: {
    ...mapState(["sucursales"]),
    computedDateFormattedMomentjs() {
      return this.fechaIn ? moment(this.fechaIn).format("DD/MMMM/YYYY") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    computedDateFormattedMomentjss() {
      return this.fechaFn ? moment(this.fechaFn).format("DD/MMMM/YYYY") : "";
    },
  },
  methods: {
    abrirReporteVentasCAT() {
      //console.log(moment(this.fechaInicio).format("YYYYmmdd") + "00:00:00");
      this.bodyForFetch = {
        sucursal: this.sucSelected,
        fechaIn: moment(this.fechaIn).format("YYYYMMDD"),
        fechaFn: moment(this.fechaFn).format("YYYYMMDD"),
      };
      let routeData = this.$router.resolve({
        name: "ReporteVentasCAT",
      });
      window.open(routeData.href, "_blank");
    },
    printTime: function () {
      return new Date().toLocaleTimeString();
    },
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
  },
  async mounted() {
    this.$store.dispatch("getSucursales");
    this.tiempoFin = this.printTime();
    this.fechaIn = this.printDate();
    this.fechaFn = this.printDateFn();
  },
};
</script>
<style></style>
