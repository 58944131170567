<template>
  <v-autocomplete
    v-model="sucSelected"
    dense
    solo
    :items="sucursales"
    item-text="sucursal"
    item-value="sucursal"
    :disabled="radioSelected == 1"
    hide-details
  ></v-autocomplete>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapState(["sucursales", "userInfo", "radioSelected"]),
    sucSelected: {
      get: function () {
        return this.$store.state.sucSelected;
      },
      set: function (val) {
        this.$store.commit("setsucSelected", val);
      },
    },
  },
  //   async mounted() {
  //     //console.log(this.userInfo);
  //     await this.$store.dispatch("getSucursales", true);
  //     this.$store.commit("setsucSelected", this.userInfo.sucursal);
  //   },
};
</script>
<style></style>
