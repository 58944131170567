<template lang="">
  <v-container>
    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col xl="3" lg="3" md="6" sm="6" cols="12">
            <v-card>
              <v-card-subtitle>Sucursal</v-card-subtitle>
              <v-card-text>
                <v-radio-group
                  dense
                  :disabled="sucursalIsolate()"
                  v-model="radioSelected"
                  column
                >
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Seleccionado" :value="0"></v-radio>
                </v-radio-group>
                <sucursal-simple></sucursal-simple>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xl="3" lg="3" md="6" sm="6" cols="12">
            <v-card>
              <v-card-subtitle>Repartidores</v-card-subtitle>
              <v-card-text>
                <v-radio-group dense v-model="repartSelected" column>
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Seleccionado" :value="0"></v-radio>
                </v-radio-group>
                <v-autocomplete
                  hide-details
                  v-model="repSelect"
                  dense
                  solo
                  :items="repartidores"
                  item-text="nombre"
                  item-value="idRepartidor"
                  :disabled="repartSelected == 1"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xl="3" lg="3" md="6" sm="6" cols="12">
            <v-card>
              <v-card-subtitle>Rango de Fechas</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="5">Fecha Inicial: </v-col>
                  <v-col cols="7">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Picker in menu"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">Fecha Final: </v-col>
                  <v-col cols="7">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="date1"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date1"
                          label="Picker in menu"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date1" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu3.save(date1)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xl="3" lg="3" md="6" sm="6" cols="12">
            <v-checkbox
              v-model="folioCheck"
              label="Folio Factura: "
            ></v-checkbox>
            <v-text-field
              v-model="folio"
              :disabled="folioCheck == false"
            ></v-text-field>
            <br />
            <v-btn dark :color="color" block @click="busquedaDatos()"
              >Buscar</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="datosPost"
              :items-per-page="15"
              dense
              :loading="loaginTable"
              loading-text="Cargando... Por favor, espere"
              mobile-breakpoint="0"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :dark="item.status == 'Entregado'"
                  :disabled="
                    item.status == 'Sin Asignar' || item.status == 'En ruta'
                  "
                  :color="color"
                  @click="AbrirRutaUsada(item)"
                >
                  Ruta Usada
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog transition="fade-transition" v-model="dmapa" max-width="1000px">
      <v-card>
        <div
          ref="mapContainer"
          id="map"
          style="height: 60vh; width: 100%; overflow: hidden"
        >
          <v-skeleton-loader
            v-if="skeleton"
            type="image, image, image"
            height="60vh"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    headers: [
      { text: "Folio Peticion", value: "folioPeticion" },
      { text: "Sucursal", value: "sucursal" },
      { text: "Tipo Peticion", value: "idTipoPeticion" },
      { text: "Folio", value: "folio" },
      { text: "Estatus", value: "status" },
      { text: "Repartidor", value: "nombre" },
      { text: "Fecha Alta", value: "fechaAlta" },
      { text: "Fecha Asignada", value: "fechaAsignada" },
      { text: "Fecha Iniciada", value: "fechaInicio" },
      { text: "Fecha Entregada", value: "fechaTermino" },
      { text: "Ruta Usada", value: "actions", sortable: false },
    ],
    datosPost: [],
    folio: "",
    folioCheck: false,
    repSelect: "",
    repartidores: [],
    repartSelected: 1,

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    loaginTable: false,
    TokenMap:
      "pk.eyJ1Ijoiam9tYXJpbmR1c3RyaWFzIiwiYSI6ImNsNTlzY3llYTJmNWEza3FxcHJyMDZ4bjkifQ.GcFBgAtC6I4vN5eSH49LeA",
    lat: 0,
    lng: 0,
    map: null,
    dmapa: false,
    skeleton: true,
    marker: null,
    marker2: null,
  }),
  computed: {
    ...mapState([
      "sucursales",
      "url",
      "token",
      "userInfo",
      "sucSelected",
      "color",
    ]),
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    let esglobal = 0;
    if (this.userInfo.ESGLOBAL) {
      esglobal = 1;
    }
    this.$store.commit("setradioSelected", esglobal);
  },
  watch: {
    radioSelected(newValue) {
      this.llamaRepartidores();
    },
    sucSelected(newValue) {
      this.llamaRepartidores();
    },
    dmapa(value) {
      if (!value) {
        this.map.remove();
      }
    },
  },
  methods: {
    sucursalIsolate() {
      if (this.userInfo.ESGLOBAL == true) {
        return false;
      } else {
        this.radioSelected = 0;
        this.sucSelected = this.userInfo.sucursal;
        return true;
      }
    },
    formatDate(datos) {
      for (var i = 0; i < datos.length; i++) {
        var numAl = datos[i].fechaAlta;
        numAl = numAl.replace("T", " ");
        numAl = numAl.replace("Z", "");
        numAl = moment(numAl).format("DD/MM/YYYY HH:mm:ss");
        datos[i].fechaAlta = numAl;
        var numAs = datos[i].fechaAsignada;
        numAs = numAs.replace("T", " ");
        numAs = numAs.replace("Z", "");
        numAs = moment(numAs).format("DD/MM/YYYY HH:mm:ss");
        datos[i].fechaAsignada = numAs;
        var numTe = datos[i].fechaTermino;
        numTe = numTe.replace("T", " ");
        numTe = numTe.replace("Z", "");
        numTe = moment(numTe).format("DD/MM/YYYY HH:mm:ss");
        datos[i].fechaTermino = numTe;
        var numIn = datos[i].fechaInicio;
        numIn = numIn.replace("T", " ");
        numIn = numIn.replace("Z", "");
        numIn = moment(numIn).format("DD/MM/YYYY HH:mm:ss");
        datos[i].fechaInicio = numIn;
      }
      this.datosPost = datos;
    },
    async busquedaDatos() {
      this.datosPost = [];
      this.loaginTable = true;
      const response = await fetch(
        this.url + "/administracion/consulta_peticiones",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            sucursal: this.sucSelected,
            todasSuc: this.radioSelected,
            idRepartidor: this.repSelect,
            todosRep: this.repartSelected,
            fechaIn: this.date.replaceAll("-", ""),
            fechaFn:
              this.date == this.date1
                ? this.addDay()
                : this.date1.replaceAll("-", ""),
            FolioFactura: this.folioCheck ? this.folio : 0,
          }),
        }
      );
      this.loaginTable = false;
      if (response.status == 200) {
        const tableFill = await response.json();
        //console.log(tableFill);
        await this.formatDate(tableFill.respuesta);
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      //console.log(datef);
      this.date1 = datef;
      datef = datef.replaceAll("-", "");

      return datef;
    },
    async llamaRepartidores() {
      const response = await fetch(
        this.url + "/administracion/consulta_repartidores",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            global: this.userInfo.ESGLOBAL ? 1 : 0,
            sucursal: this.sucSelected,
            todasSuc: this.radioSelected,
          }),
        }
      );
      if (response.status == 200) {
        const boxFill = await response.json();
        //console.log(boxFill);
        this.repartidores = boxFill.respuesta;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    AbrirRutaUsada(value) {
      this.dmapa = true;
      this.skeleton = true;
      var paresCoordenadas = value.RutaUsada.split("|");
      //console.log(paresCoordenadas);
      var coordenadasFiltradas = paresCoordenadas.filter(function (coordenada) {
        return coordenada !== "" && coordenada !== "0,0";
      });
      var coordenadasArray = coordenadasFiltradas.map(function (par) {
        var coordenadas = par.split(",");
        return [parseFloat(coordenadas[0]), parseFloat(coordenadas[1])];
      });
      //console.log(coordenadasArray);
      setTimeout(() => {
        this.skeleton = false;
        this.initializeMap();
        this.AgregarRutaMapa(coordenadasArray);
        this.map.setCenter(coordenadasArray[0]);
        this.AgregarMarcaIncial(coordenadasArray[0]);
        this.AgregarMarcaFinal(coordenadasArray[coordenadasArray.length - 1]);
      }, 800);
    },
    initializeMap() {
      const div = this.$refs.mapContainer;
      mapboxgl.accessToken = this.TokenMap;
      this.map = new mapboxgl.Map({
        container: div,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [this.userInfo.Longitud, this.userInfo.Latitud],
        zoom: 16,
        projection: "globe",
      });
    },
    AgregarRutaMapa(value) {
      this.map.on("load", () => {
        this.map.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: value,
            },
          },
        });
        this.map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#003479",
            "line-width": 8,
          },
        });
      });
    },
    AgregarMarcaIncial(value) {
      const popup = new mapboxgl.Popup({ offset: 25 }).setText(
        "Inicio de la ruta"
      );

      const el = document.createElement("div");
      el.id = "marker";

      this.marker = new mapboxgl.Marker()
        .setLngLat(value)
        .setPopup(popup) // sets a popup on this marker
        .addTo(this.map);
    },
    AgregarMarcaFinal(value) {
      const popup = new mapboxgl.Popup({ offset: 25 }).setText(
        "Fin de la ruta"
      );

      const el = document.createElement("div");
      el.id = "marker";

      this.marker2 = new mapboxgl.Marker()
        .setLngLat(value)
        .setPopup(popup) // sets a popup on this marker
        .addTo(this.map);
    },
  },
};
</script>
<style>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
