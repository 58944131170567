<template lang="">
  <v-container>
    <v-card>
      <v-card-text>
        <v-card-title>
          <h1>Reporte de Ventas por precio</h1>
        </v-card-title>
        <v-card>
          <v-card-subtitle>Fechas</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="2">Fecha Inicial: </v-col>
              <v-col>
                <v-menu
                  v-model="menuFechaInicio"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="fechaIn"
                        label="Fecha Inicio"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="fechaIn"
                      @input="menuFechaInicio = false"
                    ></v-date-picker>
                </v-menu>
              </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">Fecha Final: </v-col>
              <v-col>
                <v-menu
                    v-model="menuFechaFin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="fechaFn"
                        label="Fecha Fin"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fechaFn"
                      @input="menuFechaFin = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br />
        <v-card>
          <v-card-subtitle>Tipos de Venta</v-card-subtitle>
          <v-card-text>
            <v-radio-group v-model="tipo" column>
              <v-radio label="Credito" value="1"></v-radio>
              <v-radio label="Contado" value="2"></v-radio>
              <v-radio label="Credito/Contado" value="3"></v-radio>
              <v-radio label="Por vendedor" value="4"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn @click="abrirReporteVentasPorPrecio()">Imprimir</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    fechaIn: "",
    fechaFn: "",
    menuFechaInicio: "",
    menuFechaFin: "",
    tipo: "",
  }),
  computed: {
    ...mapState(["sucursales"]),
    computedDateFormattedMomentjs() {
      return this.fechaIn ? moment(this.fechaIn).format("YYYY/MM/DD") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    computedDateFormattedMomentjss() {
      return this.fechaFn ? moment(this.fechaFn).format("YYYY/MM/DD") : "";
    },
  },
  methods: {
    abrirReporteVentasPorPrecio() {
      this.bodyForFetch = {
        FechaIn: moment(this.fechaIn).format("YYYYMMDD"),
        FechaFn: moment(this.fechaFn).format("YYYYMMDD"),
      };
      let routeData = this.$router.resolve({
        name: "ReporteVentasCredito",
      });
      window.open(routeData.href, "_blank");
    },
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.$store.dispatch("getSucursales");
    this.fechaIn = this.printDate();
    this.fechaFn = this.printDateFn();
  },
};
</script>
<style lang=""></style>
