<template lang="">
  <v-container>
    <v-row><h1>Valuacion de Inventario</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="2">Fecha: </v-col>
              <v-col cols="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">Sucursal:</v-col>
              <v-col cols="4">
                <sucursal-simple></sucursal-simple>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">Valuacion</v-col>
              <v-col>
                <v-text-field
                  v-model="valuacion"
                  outlined
                  dense
                  readonly
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block @click="Consulta()">Consultar</v-btn>
              </v-col>
              <v-col>
                <v-btn block @click="tableView = !tableView"
                  >Valoración por Nivel</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="tableView">
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="valList"
                  class="row-height-50 elevation-1"
                  :mobile-breakpoint="0"
                  :items-per-page="24"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    headers: [
      { text: "Sucursal", value: "SUCURSAL" },
      { text: "Nivel", value: "NIVEL" },
      { text: "Valoracion", value: "TOTAL" },
    ],
    valList: [],
    valuacion: 0,
    tableView: false,
  }),
  computed: {
    ...mapState(["sucSelected", "url", "userInfo", "token"]),
  },
  methods: {
    addDay() {
      var datef = new Date(this.date);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      //console.log(datef);
      datef = datef.replaceAll("-", "");

      return datef;
    },
    async summonList() {
      try {
        const response = await fetch(
          this.url + "/inventario/lista_valoracion_niveles",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.valList = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async Consulta() {
      const response = await fetch(
        this.url + "/inventario/inventario_consulta",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            dtFechaInicio: this.date.replaceAll("-", ""),
            dtFechaFin: this.addDay(),
            strSucursal: this.sucSelected,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.valuacion = value[0].Monto;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.summonList();
  },
};
</script>
<style lang=""></style>
