<template>
  <div class="noPrint">
    <v-app-bar app :color="color" dark dense>
      <v-app-bar-nav-icon
        @click="navDrawerToggle = !navDrawerToggle"
      ></v-app-bar-nav-icon>
      JomarWeb

      <v-spacer></v-spacer>
      <v-btn plain>
        <v-icon large color="darken-2" @click="logout()">
          mdi-exit-to-app
        </v-icon></v-btn
      >
    </v-app-bar>
    <v-navigation-drawer
      v-model="navDrawerToggle"
      absolute
      temporary
      width="285"
    >
      <v-list-item>
        <v-list-item-content>
          <h5>
            {{ userInfo.sucursal }} - {{ userInfo.idUsuario }} -
            {{ userInfo.cuenta }}
          </h5>
          <h5>{{ userInfo.nombre }}</h5>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-group
          sub-group
          no-action
          v-for="opcion in navDrawerOpciones"
          :key="opcion.name"
          style="padding-left: 6px"
          ><template v-slot:activator>
            <v-list-item-title>{{ opcion.name }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="child in opcion.children"
            :key="child.url"
            @click="goRoute(child.url)"
            style="padding-left: 36px"
          >
            <v-list-item-title v-text="child.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navbar",

  data: () => ({}),

  computed: {
    ...mapState(["navDrawerOpciones", "color"]),
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        this.$store.commit("setNavbarToggle", val);
      },
    },
    navDrawerToggle: {
      get: function () {
        return this.$store.state.navDrawerToggle;
      },
      set: function (val) {
        this.$store.commit("setNavDrawerToggle", val);
      },
    },
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.commit("setIsAuthorized", val);
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.commit("setUserInfo", val);
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.commit("setToken", val);
      },
    },
  },

  mounted() {
    this.$root.$on("Exit", () => {
      return this.logout();
    });
  },

  methods: {
    goRoute(ruta) {
      this.$router.push(ruta);
    },
    logout() {
      this.$store.commit("ExitApp");
      // this.userInfo = {};
      // this.token = "";
      // this.isAuthorized = false;
      // localStorage.clear();
      // this.$router.push("/login");
      // window.location.reload();
    },
  },
};
</script>

<style></style>
