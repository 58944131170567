<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <div>
      <v-row justify="center" style="font-size: 17px"
        >Jomar Industrias S.A. de C.V.</v-row
      >
      <v-row justify="center" style="font-size: 17px">Kardex</v-row>
      <v-row justify="end">{{ dateNow }}</v-row>
      <v-row>Sucursal: {{ bodyForFetch.sucursal }}</v-row>
      <v-row>Articulo: {{ bodyForFetch.articulo }}</v-row>
      <v-row justify="center" style="font-size: 12px"
        >*Tipo: C-N.Cred, D-DevProv, E-Ajus.Entr, F-FactureVta, I-Trasp.Ent,
        J-Consumo, M-InicioMes, N-N.Carg, P-CambCtoProm, R-RecibComp,
        S-Ajus.Sal, T-TraspSal</v-row
      >
    </div>
    <br />

    <div>
      <v-data-table
        :headers="headers"
        :items="respuesta"
        :items-per-page="respuesta.length"
        hide-default-footer
        dense
        class="elevation-1 row-height-50"
      >
        <template v-slot:item.fechaC="{ item }">
          {{ formatDate(item.FechaMov) }}
        </template>
        <template v-slot:item.FechaMov="{ item }">
          {{ formatHours(item.FechaMov) }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
//var moment = require("moment");
import moment from "moment-timezone";
export default {
  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      respuesta: [],
      headers: [
        { text: "Fecha", value: "fechaC", align: "center", sortable: false },
        { text: "Hora", value: "FechaMov", align: "center", sortable: false },
        { text: "Tipo", value: "TipoMov", align: "center", sortable: false },
        { text: "Folio", value: "Folio", align: "center", sortable: false },
        {
          text: "Cliente/Prov",
          value: "Clte_Prov",
          align: "center",
          sortable: false,
        },
        { text: "Costo Unitario", align: "center", sortable: false },
        { text: "Costo Anterior", align: "center", sortable: false },
        { text: "Nvo. Costo Unitario", align: "center", sortable: false },
        { text: "Cantidad Anterior", align: "center", sortable: false },
        {
          text: "Cantidad",
          value: "Cantidad",
          align: "center",
          sortable: false,
        },
        { text: "Cantidad Nueva", align: "center", sortable: false },
        { text: "Usuario", value: "Cuenta", align: "center", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  async mounted() {
    //console.log(this.bodyForFetch);
    try {
      //console.log(this.bodyForFetch);
      const response = await fetch(
        this.url +
          `/inventario/kardex_cascos?todaSucursal=${this.bodyForFetch.todaSucursal}&sucursal=${this.bodyForFetch.sucursal}&rangoFecha=${this.bodyForFetch.rangoFecha}&fechaIni=${this.bodyForFetch.fechaIni}&fechaFin=${this.bodyForFetch.fechaFin}&articulo=${this.bodyForFetch.articulo}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      //console.log(response);
      const obj = await response.json();
      //console.log(obj);
      this.respuesta = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
  },
  methods: {
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    formatHours(value) {
      var hora = value.slice(11, 16);
      return hora;
    },
    downloadexcel() {
      let arr = this.information;

      let data = [
        {
          sheet: "KardexCascos",
          columns: [
            { label: "Fecha", value: "fechaC", align: "center" },
            { label: "Hora", value: "FechaMov", align: "center" },
            { label: "Tipo", value: "TipoMov", align: "center" },
            { label: "Folio", value: "Folio", align: "center" },
            { label: "Cliente/Prov", value: "Clte_Prov", align: "center" },
            { label: "Costo Unitario", align: "center" },
            { label: "Costo Anterior", align: "center" },
            { label: "Nvo. Costo Unitario", align: "center" },
            { label: "Cantidad Anterior", align: "center" },
            { label: "Cantidad", value: "Cantidad", align: "center" },
            { label: "Cantidad Nueva", align: "center" },
            { label: "Usuario", value: "Cuenta", align: "center" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "KardexCascos", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
