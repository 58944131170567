<template lang="">
  <v-container>
    <v-card>
      <v-card-title>Redondeo</v-card-title>
      <v-card-text>
        <v-card>
          <v-card-subtitle>Sucursal</v-card-subtitle>
          <v-container>
            <!--<v-autocomplete
              v-model="sucursalDeHoy"
              dense
              solo
              label="Sucursal: "
              :items="sucursales"
              item-text="sucursal"
              item-value="sucursal"
            ></v-autocomplete>-->
            <sucursal-simple></sucursal-simple>
          </v-container>
        </v-card>
        <br />
        <v-card>
          <v-card-subtitle>Fechas</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="2">Fecha Inicial: </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu2"
                  v-model="timeIn"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time1"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time1"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeIn"
                    v-model="time1"
                    full-width
                    no-title
                    @click:minute="$refs.menu2.save(time1)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">Fecha Final: </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu4"
                  v-model="timeOut"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time2"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeOut"
                    v-model="time2"
                    full-width
                    no-title
                    @click:minute="$refs.menu4.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br />
        <v-card>
          <v-card-subtitle>Reporte</v-card-subtitle>
          <v-card-text>
            <v-radio-group v-model="detalle" column>
              <v-radio label="Detallado" value="detail"></v-radio>
              <v-radio label="Consolidado" value="compact"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn @click="abrirReporteRedondeo()">Imprimir</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    detalle: "",
    //sucursalDeHoy: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    time1: "00:00",
    timeIn: false,
    menu3: false,
    menu4: false,
    time2: "23:59",
    timeOut: false,
  }),
  methods: {
    abrirReporteRedondeo() {
      this.printInfo();
      let name =
        this.detalle == "detail" ? "ReporteRedondeoD" : "ReporteRedondeoC";
      let routeData = this.$router.resolve({ name });
      window.open(routeData.href, "_blank");
    },

    printInfo() {
      this.bodyForFetch = {
        fecIni: this.date.replaceAll("-", "") + " " + this.time1,
        fecFin: this.date1.replaceAll("-", "") + " " + this.time2,
        sucursal: this.sucSelected == "TODOS" ? "NULL" : `${this.sucSelected}`,
      };
      // //console.log(this.bodyForFetch);
      // //console.log(this.sucursalDeHoy);
    },
  },
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.$store.dispatch("getTipoMov");
    this.$store.dispatch("getArea");
  },
};
</script>
<style lang=""></style>
