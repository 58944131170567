<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de impresion: {{ dateNow }} </v-row>
    <v-row>
      <v-col cols="12" style="font-size: 17px">
        <v-row>Fecha de corte: {{ fechaIn }} </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Reportes de Valoracion por Categoria</h1>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      fechaIn: "",
      respuestaDeApi: [],
      headers: [
        {
          text: "IdCategoria",
          value: "IdCategoria",
        },
        {
          text: "Categoria",
          value: "Categoria",
        },
        {
          text: "Valoracion",
          value: "Valoracion",
        },
      ],
    };
  },
  computed: {
    ...mapState(["bodyForFetch"]),
  },
  methods: {
    ...mapActions("reportevaloracionporcategoria", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReporteVentasCategoria",
          columns: [
            { label: "IdCategoria", value: "IdCategoria" },
            { label: "Categoria", value: "categoria" },
            { label: "Valoracion", value: "Valoracion" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteValoracionPorCategoria", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    //console.log(this.bodyForFetch);
    this.fechaIn = this.bodyForFetch.FECHAINICIO;
    if (arr.resultado.length > 0) {
      arr.resultado.forEach((element) => {
        element.FechaIn = moment(element.fechaInicio).format(
          "DD/MM/YYYY hh:mm a"
        );
      });
      this.loadingt = false;
      this.respuestaDeApi = arr.resultado;
    } else {
      this.loadingt = false;
      this.respuestaDeApi = [];
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
