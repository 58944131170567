<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"><h1>Reporte Articulos No Contados</h1></v-row>
    <v-row>
      <v-col>
        <v-row justify="start">Sucursal: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ fechaFormat() }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="articulosNoCont"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="articulosNoCont.length"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    headers: [
      { text: "Id Cat", value: "IdCategoria" },
      { text: "Categoria", value: "Categoria" },
      { text: "Sku", value: "Itemno" },
      { text: "Descripcion", value: "Descripcion" },
      { text: "Localizacion", value: "Localizacion" },
      { text: "Existencia", value: "Existencia" },
      { text: "Ultima Entrada", value: "ultimaentrada" },
      { text: "Ultima Salida", value: "ultimasalida" },
      { text: "Ultima Venta", value: "ULTIMAVENTA" },
    ],
    articulosNoCont: [],
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  async mounted() {
    const response = await fetch(
      this.url + "/inventario/trae_articulos_no_contado",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      }
    );
    if (response.status == 200) {
      const value = await response.json();
      this.articulosNoCont = value;
      //console.log(this.articulosNoCont);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
  methods: {
    fechaFormat() {
      var dateString;
      var fechaDeInicio;
      var fechaDeFin;
      fechaDeInicio = this.bodyForFetch.strFechaIn;
      fechaDeFin = this.bodyForFetch.strFechaFin;
      fechaDeInicio = moment(fechaDeInicio).format("YYYY/MM/DD");
      fechaDeFin = moment(fechaDeFin).format("YYYY/MM/DD");
      var dateString = "Fecha: " + fechaDeInicio + " a " + fechaDeFin;
      return dateString;
    },
    downloadexcel() {
      let arr = this.articulosNoCont;
      let data = [
        {
          sheet: "Reporte de Inventario no Contado",
          columns: [
            { label: "Id Cat", value: "IdCategoria" },
            { label: "Categoria", value: "Categoria" },
            { label: "Sku", value: "Itemno" },
            { label: "Descripcion", value: "Descripcion" },
            { label: "Localizacion", value: "Localizacion" },
            { label: "Existencia", value: "Existencia" },
            { label: "Ultima Entrada", value: "ultimaentrada" },
            { label: "Ultima Salida", value: "ultimasalida" },
            { label: "Ultima Venta", value: "ULTIMAVENTA" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ArticulosNoCont", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
