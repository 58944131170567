<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="4" align="stretch" style="font-size: 12px">
        <v-row> Condiciones</v-row>
        <v-row>
          Localizacion:
          {{ respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].Localizacion }}
        </v-row>
        <v-row>
          Almacen:
          {{ respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].Area }}
        </v-row>
        <v-row>
          Sucursal:
          {{ respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].sucursal }}
        </v-row>
        <v-row>
          No. Articulo:
          {{ respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].itemno }}
        </v-row>
      </v-col>
      <v-col cols="4" style="font-size: 17px">
        <v-row justify="center">Jomar Industrias S.A. de C.V.</v-row>
        <v-row justify="center">Kardex</v-row>
      </v-col>
      <v-col cols="4" style="font-size: 12px">
        <v-row justify="end"> Impresion: {{ dateNow }} </v-row>
        <v-row justify="end">
          {{ respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].idcategoria }}
          {{
            respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].categoria
          }}</v-row
        >
        <v-row justify="end">
          <v-col style="text-align: end; padding-right: 0">
            {{
              respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].descripcion
            }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" style="font-size: 12px"
      >*Tipo: C-N.Cred, D-DevProv, E-Ajus.Entr, F-FactureVta, I-Trasp.Ent,
      J-Consumo, M-InicioMes, N-N.Carg, P-CambCtoProm, R-RecibComp, S-Ajus.Sal,
      T-TraspSal</v-row
    >
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
        >
          <template v-slot:item.cantSuma="{ item }">
            {{ item.cantidad + item.cantidadant }}
          </template>
          <template v-slot:item.fechamov="{ item }">
            {{ formatDate(item.fechamov) }}
          </template>
          <template v-slot:item.fechah="{ item }">
            {{ formatHours(item.fechamov) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row style="font-size: 12px">
      <v-col>
        <v-row justify="end"
          >Existencia:
          {{
            respuestaDeApi.length == 0 ? "" : respuestaDeApi[0].existencia
          }}</v-row
        >
        <v-row justify="end"
          >Comprometido:
          {{
            respuestaDeApi.length == 0
              ? ""
              : respuestaDeApi[0].existencia -
                (disponibilidad.length == 0 ? 0 : disponibilidad[0].Existencia)
          }}</v-row
        >
        <v-row justify="end"
          >Disponible:
          {{
            disponibilidad.length == 0 ? "" : disponibilidad[0].Existencia
          }}</v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      respuestaDeApi: [],
      disponibilidad: [],
      headers: [
        {
          text: "Fecha",
          align: "start",
          value: "fechamov",
        },
        {
          text: "Hora",
          value: "fechah",
        },
        {
          text: "Tipo",
          value: "tipomov",
        },
        {
          text: "Folio",
          value: "folio",
        },
        {
          text: "Cte/Prov",
          value: "clte_prov",
        },
        {
          text: "Cost Uni",
          value: "costo",
        },
        {
          text: "Cost Ant",
          value: "costoant",
        },
        {
          text: "Nvo Cost Uni",
          value: "costonvo",
        },
        {
          text: "Cant Ant",
          value: "cantidadant",
        },
        {
          text: "Cant",
          value: "cantidad",
        },
        {
          text: "Cant Nva",
          value: "cantSuma",
        },
        {
          text: "Id Usu",
          value: "cuenta",
        },
      ],
    };
  },

  methods: {
    ...mapActions("reporteKardex", ["tableFill", "getDisponibilidad"]),
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    formatHours(value) {
      return moment(value).format("HH:mm");
    },
    downloadexcel() {
      let arr = this.respuestaDeApi;
      arr.forEach((element) => {
        element.fechamov = moment(element.fechamov).format("DD/MM/YYYY HH:mm");
      });

      let data = [
        {
          sheet: "Kardex",
          columns: [
            { label: "Sucursal", value: "sucursal" },
            { label: "Tipo Movimiento", value: "tipomov" },
            { label: "Folio", value: "folio" },
            { label: "Itemno", value: "itemno" },
            {
              label: "Fecha Movimiento",
              value: "fechamov",
            },
            { label: "Clte Prov", value: "clte_prov" },
            { label: "Cantidad", value: "cantidad" },
            { label: "Cantidad Ant", value: "cantidadant" },
            { label: "Costo", value: "costo" },
            { label: "Costo Ant", value: "costoant" },
            { label: "Costo Nvo", value: "costonvo" },
            { label: "Id Usuario", value: "idusuario" },
            { label: "Cuenta", value: "cuenta" },
            { label: "Categoria", value: "categoria" },
            { label: "Existencia", value: "existencia" },
            { label: "Descripcion", value: "descripcion" },
            { label: "Id Categoria", value: "idcategoria" },
            { label: "Area", value: "Area" },
            { label: "Localizacion", value: "Localizacion" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "Kardex", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async created() {
    this.$store.commit("setNavbarToggle", 1);

    const obj = await this.tableFill();
    this.respuestaDeApi = obj.Respuesta;
    //console.log(this.respuestaDeApi);
    const obj2 = await this.getDisponibilidad();
    this.disponibilidad = obj2.Respuesta;
  },
};
</script>

<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
