<template>
  <v-container>
    <v-card>
      <v-card-title> Listado Categoria de Almacen </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Sucursal</v-card-subtitle>
              <v-card-text>
                <sucursal-simple></sucursal-simple>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-subtitle>Almacen</v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="Radioalmacen" column>
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Seleccionado" :value="0"></v-radio>
                </v-radio-group>
                <v-autocomplete
                  v-model="almselect"
                  :items="listaAutoAlmacenes"
                  item-text="Almacenes"
                  item-value="Almacenes"
                  :disabled="Radioalmacen == 1 ? true : false"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-subtitle>Categorias</v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="Radiocategoria" column>
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Seleccionado" :value="0"></v-radio>
                </v-radio-group>
                <v-combobox
                  v-model="catselect"
                  :items="listaAutoCategorias"
                  item-text="Categorias"
                  item-value="Categorias"
                  multiple
                  :disabled="Radiocategoria == 1 ? true : false"
                ></v-combobox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn @click="abrirReporteListadoCatAlmacen()">Imprimir</v-btn>
          <v-btn>Salir</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaListadoCatAlmacen",
  components: {
    sucursalSimple,
  },
  data: () => ({
    Radioalmacen: 1,
    almselect: [],
    listaAutoAlmacenes: [],
    Radiocategoria: 1,
    catselect: [],
    listaAutoCategorias: [],
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.traerListaAlmacenes();
    this.traerListaCategorias();
  },
  methods: {
    async traerListaAlmacenes() {
      try {
        const response = await fetch(this.url + "/inventario/lista_Almacenes", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.listaAutoAlmacenes = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async traerListaCategorias() {
      try {
        const response = await fetch(
          this.url + "/inventario/lista_Categorias",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.listaAutoCategorias = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    abrirReporteListadoCatAlmacen() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteListadoCatAlmacen",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      this.bodyForFetch = {
        Sucursal: this.sucSelected,
        Almacen: this.Radioalmacen == 1 ? "" : this.almselect.slice(0, 4),
        TodoAlmacen: this.Radioalmacen,
        xmlCategoria: this.DatosSeleccionadosCat(),
        TodaCategoria: this.Radiocategoria,
      };
      //console.log(this.bodyForFetch);
    },
    DatosSeleccionadosCat() {
      //console.log(this.catselect)
      const xmlDoc = document.implementation.createDocument(
        null,
        "CategoriasList"
      );
      var elements = xmlDoc.getElementsByTagName("CategoriasList");
      this.catselect.forEach((value) => {
        const rootElement = xmlDoc.createElement("Categorias");
        rootElement.setAttribute("categoria", value.Categorias.slice(0, 3));
        elements[0].appendChild(rootElement);
      });
      var xmlText = new XMLSerializer().serializeToString(xmlDoc);
      //console.log(xmlText);
      return xmlText;
    },
  },
};
</script>
<style></style>
