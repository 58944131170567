<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de consulta: {{ dateNow }} </v-row>
    <v-row>Traspasa: {{ respuestaDeApi[0].CveSucursalOrigen }} </v-row>
    <v-row>Pide/Recibe: {{ respuestaDeApi[0].CveSucursalDestino }} </v-row>
    <v-row>No. Traspaso: {{ respuestaDeApi[0].NoTraspaso }} </v-row>
    <v-row>Fecha Traspaso: {{ respuestaDeApi[0].FechaTraspaso }} </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Jomar Industrias S.A. de C.V.</h1>
        <h2 align="center">Comprobante de Traspasos</h2>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      respuestaDeApi: [],
      headers: [
        {
          text: "Cantidad",
          value: "Cantidad",
        },
        {
          text: "Articulo",
          value: "Articulo",
        },
        {
          text: "Almacen Localizacion",
          value: "Almacen",
        },
        {
          text: "Categoria",
          value: "Categoria",
        },
        {
          text: "Descripcion",
          value: "Descripcion",
        },
        {
          text: "Empaque",
          value: "Empaque",
        },
      ],
    };
  },
  methods: {
    ...mapActions("reportetraspasossucursal", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReporteTraspasosSucursal",
          columns: [
            { label: "Cantidad", value: "Cantidad" },
            { label: "Articulo", value: "Articulo" },
            { label: "Almacen Localizacion", value: "Almacen" },
            { label: "Categoria", value: "Categoria" },
            { label: "Descripcion", value: "Descripcion" },
            { label: "Empaque", value: "Empaque" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteTraspasosSucursal", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    arr.resultado.forEach((element) => {
      element.FechaIn = moment(element.fechaInicio).format(
        "DD/MM/YYYY hh:mm a"
      );
    });
    this.loadingt = false;
    this.respuestaDeApi = arr.resultado;
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
