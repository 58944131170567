<template>
  <div>
    <v-container>
      <v-row><h1>Monitor de Peticiones para LTH</h1></v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <!--<v-row align="center">
                <v-col lg="2" md="3" sm="4" cols="6">
                  <v-autocomplete
                    dense
                    v-model="tecnico"
                    hide-details
                    :items="tecnicos"
                    item-text="nombre"
                    item-value="idTecnico"
                    @change="peticionesAsignadas()"
                  ></v-autocomplete>
                </v-col>
                <v-col> </v-col>
              </v-row>-->
              <v-row>
                <v-col>
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="petAbiertas"
                    class="row-height-50 elevation-1"
                    :mobile-breakpoint="0"
                    :items-per-page="50"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-btn
                          fab
                          small
                          dark
                          @click="peticionesAbiertas()"
                          :color="color"
                        >
                          <v-icon> mdi-refresh </v-icon>
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.acciones="{ item }">
                      <v-btn
                        class="tablebutton"
                        small
                        dark
                        @click="mostrar(item.idPeticion)"
                        >mostrar</v-btn
                      >
                      <v-btn
                        class="tablebutton"
                        small
                        dark
                        @click="acude(item.idPeticion)"
                        >cliente acude</v-btn
                      >
                      <v-btn
                        class="tablebutton"
                        small
                        dark
                        @click="editar(item.idPeticion)"
                        >editar</v-btn
                      >
                      <v-btn
                        class="tablebutton"
                        small
                        dark
                        @click="dialogOpen(4, item.idPeticion)"
                        >eliminar</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <!--<v-row>
                <v-col cols="2" v-for="(item, i) in petAsignadas" :key="i">
                  <v-card>
                    <v-card-subtitle>{{ item.nombre }}</v-card-subtitle>
                    <v-card-text>
                      <v-row>
                        <v-col cols="10"
                          >{{ item.idPeticion }} - {{ item.folioManual }} -
                          {{ item.nombreCliente }}</v-col
                        >
                        <v-col cols="2">
                          <div @click="dialogOpen(0, item.idPeticion)">X</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>-->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="seguro" max-width="400" persistent>
      <v-card>
        <v-card-title>Ingresa Clave Supervisor</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                hide-details
                v-model="usuario"
                label="Usuario"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                v-model="password"
                label="Contraseña"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="motivo"
                label="Motivo"
                auto-grow
                outlined
                rows="3"
                row-height="25"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ feedback }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :color="color" text @click="seguro = false"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="color" text @click="aceptar()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="tecOverview" max-width="420">
      <v-card>
        <v-card-text>
          <v-row class="header">
            <v-col class="hd">
              <v-card color="#dbdbdb">
                <v-card-text class="txt">
                  <div class="titulo">
                    <div>
                      #Petición: <span>{{ tecOvData.idPet }}</span>
                    </div>
                    <div @click="tecOverview = false">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="lstcol">
              <v-row class="lst"
                >Consecutivo Manual: {{ tecOvData.folio }}</v-row
              >
              <v-row class="lst">Nombre Cliente: {{ tecOvData.nombre }}</v-row>
              <v-row class="lst"
                >Servicio a realizar: {{ tecOvData.servicio }}</v-row
              >
              <v-row class="lst">Codigo Bateria: {{ tecOvData.codigo }}</v-row>
              <v-row class="lst">Precio: {{ tecOvData.precio }}</v-row>
              <v-row class="lst">Metodo de Pago: {{ tecOvData.pago }}</v-row>
              <v-row class="lst">Notas: {{ tecOvData.notas }}</v-row>
              <v-row class="lst">Programacion: {{ tecOvData.prog }}</v-row>
              <v-row class="lst">Telefono: {{ tecOvData.telefono }}</v-row>
              <v-row class="lst">Marca: {{ tecOvData.marca }}</v-row>
              <v-row class="lst">Modelo: {{ tecOvData.modelo }}</v-row>
              <v-row class="lst">Año: {{ tecOvData.anio }}</v-row>
              <v-row class="lst">Direccion: {{ tecOvData.direccion }}</v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    seguro: false,
    motivo: "",
    usuario: "",
    password: "",
    tecnico: "",
    feedback: "",
    tecnicos: [],
    show: false,
    tipodeEliminacion: 2,
    tempidpeti: 0,
    headers: [
      { text: "#Peticion", value: "idPeticion" },
      { text: "Consecutivo", value: "folioManual" },
      { text: "Sucursal", value: "sucursal" },
      { text: "Tecnico", value: "nombre" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Fecha Alta", value: "fechaAlta" },
      { text: "", value: "acciones" }, //botones de Asignar, Cliente Acude, y Eliminar, que saca el dialogue box tambien
    ],
    //usar store procedure con logPeticionesLTH
    petAsignadas: [],
    petAbiertas: [],
    tecOverview: false,
    tecOvData: {
      idPet: 0,
      folio: "",
      nombre: "",
      servicio: "",
      codigo: "",
      precio: 0,
      pago: "",
      notas: "",
      prog: "",
      telefono: "",
      marca: "",
      modelo: "",
      anio: "",
      direccion: "",
    },
    tiposCobro: [],
  }),
  computed: {
    ...mapState(["url", "userInfo", "token", "color"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    dialogOpen(value1, value2) {
      this.motivo = "";
      this.usuario = "";
      this.password = "";
      this.tipodeEliminacion = value1;
      this.tempidpeti = value2;
      this.seguro = true;
    },
    async aceptar() {
      this.feedback = "";
      const response = await fetch(
        this.url + "/administracion/log_peticion_cambio_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            usuarioSup: this.usuario,
            password: this.password,
            idUserPantalla: this.userInfo.idUsuario,
            motivo: this.motivo,
            idPeti: this.tempidpeti,
            statusPeti: this.tipodeEliminacion,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        if (value[0].Mensaje == "PEDIDO ELIMINADO") {
          this.seguro = false;
          this.peticionesAbiertas();
          this.peticionesAsignadas();
        } else {
          this.feedback = value[0].Mensaje;
        }
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async summonUsuarios() {
      const response = await fetch(
        this.url + "/administracion/lista_usuarios_suc_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            sucursal: this.userInfo.sucursal,
          }),
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        console.log(value);
        //value.resultado;
        for (var i = 0; i < value.length; i++) {
          if (value[i].activo == 1) {
            this.tecnicos.push(value[i]);
          }
        }
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }

      this.buscarBtnswitch = false;
    },
    async peticionesAbiertas() {
      const response = await fetch(
        this.url + "/administracion/peticiones_lth_abiertas",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        for (var i = 0; i < value.resultado.length; i++) {
          value.resultado[i].fechaAlta = moment(value.resultado[i].fechaAlta)
            .utc()
            .format("YYYY/MM/DD HH:mm:ss");
        }
        this.petAbiertas = value.resultado;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async peticionesAsignadas() {
      const response = await fetch(
        this.url + "/administracion/peticiones_lth_asignadas",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idTec: this.tecnico,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.petAsignadas = value;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async editar(idPeticionItem) {
      this.bodyForFetch = {
        idPeti: idPeticionItem,
      };
      let routeData = this.$router.resolve({
        name: "PantallaPeticionesLTH",
      });
      window.open(routeData.href, "_self");
    },
    async acude(idPeticionItem) {
      const response = await fetch(
        this.url + "/administracion/asigna_peticion_acude_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeti: idPeticionItem,
            idUsuario: this.userInfo.idUsuario,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.peticionesAbiertas();
        this.peticionesAsignadas();
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async mostrar(idPeticionItem) {
      const response = await fetch(
        this.url + "/administracion/info_completa_pedido_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeti: idPeticionItem,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.tecOvData.idPet = idPeticionItem;
        this.tecOvData.nombre = value[0].nombreCliente;
        this.tecOvData.folio = value[0].folioManual;
        this.tecOvData.servicio = value[0].servicio;
        this.tecOvData.notas = value[0].notas;
        this.tecOvData.prog = value[0].Programacion;
        this.tecOvData.codigo = value[0].codigoBateria;
        this.tecOvData.precio = value[0].precio;
        this.tecOvData.pago = value[0].nTipoCobro;
        this.tecOvData.telefono = value[0].telefono;
        this.tecOvData.marca = value[0].MakeName;
        this.tecOvData.modelo = value[0].modelo;
        this.tecOvData.anio = value[0].anio;
        this.tecOvData.direccion = value[0].direccion;
        this.tecOverview = true;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
  },
  mounted() {
    this.summonUsuarios();
    this.peticionesAbiertas();
    this.bodyForFetch = {};
  },
};
</script>
<style scoped>
.v-data-table.row-height-50 td {
  height: 36px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.tablebutton {
  margin: 5px;
}
.titulo {
  display: flex;
  justify-content: space-between;
}
.header {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
.hd {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 12px !important;
}
.txt {
  padding-top: 20px !important;
  padding-right: 24px !important;
  padding-bottom: 20px !important;
  padding-left: 24px !important;
}
.lst {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 12px !important;
}
.lstcol {
  padding: 14px !important;
}
.v-icon:hover {
  background-color: grey;
}
.row + .row {
  margin-top: 0px !important;
}
</style>
