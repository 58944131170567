<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de impresion: {{ dateNow }} </v-row>
    <v-row>
      <v-col cols="12" style="font-size: 17px">
        <v-row>Desde: {{ fechaIn }} </v-row>
        <v-row>Hasta: {{ fechaFn }} </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Reportes Ventas por Vendedor</h1>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      fechaIn: "",
      fechaFn: "",
      respuestaDeApi: [],
      headers: [
        {
          text: "Sucursal",
          value: "Sucursal",
        },
        {
          text: "Vendedor",
          value: "Vendedor",
        },
        {
          text: "CondPago",
          value: "CondPago",
        },
        {
          text: "Venta",
          value: "Venta",
        },
        {
          text: "Costo",
          value: "Costo",
        },
        {
          text: "Dev",
          value: "Dev",
        },
        {
          text: "Dto",
          value: "Dto",
        },
        {
          text: "Cas",
          value: "Cas",
        },
        {
          text: "PrecioCancDev",
          value: "PrecioCancDev",
        },
        {
          text: "PrecioCancDto",
          value: "PrecioCancDto",
        },
        {
          text: "PrecioCancCas",
          value: "PrecioCancCas",
        },
        {
          text: "TotNC",
          value: "TotNC",
        },
      ],
    };
  },
  methods: {
    ...mapActions("reporteventasvendedores", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReporteVentasVendedor",
          columns: [
            { label: "Sucursal", value: "Sucursal" },
            { label: "Vendedor", value: "Vendedor" },
            { label: "CondPag", value: "CondPag" },
            { label: "Venta", value: "Venta" },
            { label: "Costo", value: "Costo" },
            { label: "Dev", value: "Dev" },
            { label: "Dto", value: "Dto" },
            { label: "Cas", value: "Cas" },
            { label: "PrecioCancDev", value: "PrecioCancDev" },
            { label: "PrecioCancDto", value: "PrecioCancDto" },
            { label: "PrecioCancCas", value: "PrecioCanCas" },
            { label: "TotNC", value: "TotNC" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteVentasVendedor", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    arr.resultado.forEach((element) => {
      element.FechaIn = moment(element.fechaInicio).format("DD/MM/YYYY hh:mm a");
      element.FechaFn = moment(element.fechaFin).format("DD/MM/YYYY hh:mm a");
    });
    this.fechaIn = arr.resultado[0].FechaIn;
    this.fechaFn = arr.resultado[0].FechaFn;
    this.loadingt = false;
    this.respuestaDeApi = arr.resultado;
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
