<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"
      ><h1>Reporte de Traspasos Pendientes por Recibir</h1></v-row
    >
    <v-row>
      <v-col>
        <v-row>Condiciones</v-row>
        <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
        <v-row>Suc: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="traspasos"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="traspasos.length"
          sort-by="CveSucursalDestino"
          group-by="Zona"
          show-group-by
        >
          <template v-slot:item.FechaTras="{ item }">
            {{ formatDate(item.FechaTraspaso) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headers: [],
    traspasos: [],
    total: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    date1Format() {
      var fecha1 = "";
      var fechtemp1 = this.bodyForFetch.dtFechaInicio.split(" ");
      fecha1 = moment(fechtemp1[0]).format("YYYY/MM/DD");
      return fecha1;
    },
    date2Format() {
      var fecha2 = "";
      var fechtemp2 = this.bodyForFetch.dtFechaFin.split(" ");
      fecha2 = moment(fechtemp2[0]).format("YYYY/MM/DD");
      return fecha2;
    },
    formatHeaders() {
      switch (this.bodyForFetch.tipoTabla) {
        case 1:
          this.headers = [
            { text: "Zona", value: "Zona" },
            { text: "Destino", value: "CveSucursalDestino" },
            { text: "Origen", value: "CveSucursalOrigen" },
            { text: "Importe", value: "Importe" },
          ];
          break;
        case 2:
          this.headers = [
            { text: "Zona", value: "Zona" },
            { text: "Destino", value: "CveSucursalDestino" },
            { text: "Origen", value: "CveSucursalOrigen" },
            { text: "Importe", value: "Importe" },
            { text: "Fecha Traspaso", value: "FechaTras" },
          ];
          break;
        case 3:
          this.headers = [
            { text: "Zona", value: "Zona" },
            { text: "Destino", value: "CveSucursalDestino" },
            { text: "Origen", value: "CveSucursalOrigen" },
            { text: "Importe", value: "Importe" },
            { text: "Fecha Trasp", value: "FechaTras" },
            { text: "No Trasp", value: "NoTraspaso" },
            { text: "Almacen", value: "Almacen" },
            { text: "Descripcion", value: "Descripcion" },
            { text: "Pedido", value: "Pedido" },
            { text: "Tipo Traspaso", value: "TipoTraspaso" },
            { text: "NOMBRE", value: "NOMBRE" },
          ];
          break;
        case 4:
          this.headers = [
            { text: "Zona", value: "Zona" },
            { text: "Destino", value: "CveSucursalDestino" },
            { text: "Origen", value: "CveSucursalOrigen" },
            { text: "Importe", value: "Importe" },
            { text: "Fecha Trasp", value: "FechaTras" },
            { text: "No Trasp", value: "NoTraspaso" },
            { text: "Articulo", value: "Articulo" },
            { text: "Descripcion", value: "Descripcion" },
            { text: "Pzas", value: "Cantidad" },
            { text: "Costo", value: "CostoPromedio" },
            { text: "Pedido", value: "Pedido" },
          ];
          break;
      }
    },
    formatDate(fecha) {
      //console.log(fecha);
      if (fecha != " ") {
        var fecharr = fecha.split("T");
        var fechatemp = fecharr[0];
        return moment(fechatemp).format("DD/MM/YYYY");
      } else {
        return fecha;
      }
    },
    totalSumAll(value) {
      var totalzona = {};
      var totaldestino = {};
      for (var i = 0; i < value.length; i++) {
        this.total += value[i].Importe;
      }
      for (var i = 0; i < value.length; i++) {
        if (!totalzona.hasOwnProperty(value[i].Zona)) {
          totalzona[value[i].Zona] = 0;
        }
        totalzona[value[i].Zona] += value[i].Importe;
      }
      ////console.log(totalzona);

      for (var i = 0; i < value.length; i++) {
        if (!totaldestino.hasOwnProperty(value[i].Zona)) {
          totaldestino[value[i].Zona] = {};
        }

        if (
          !totaldestino[value[i].Zona].hasOwnProperty(
            value[i].CveSucursalDestino
          )
        ) {
          totaldestino[value[i].Zona][value[i].CveSucursalDestino] = 0;
        }
        totaldestino[value[i].Zona][value[i].CveSucursalDestino] +=
          value[i].Importe;
      }
      //console.log(totaldestino);
      for (let zonas in totalzona) {
        value.push({
          Zona: zonas,
          CveSucursalDestino: "zzz-Total Zona",
          CveSucursalOrigen: "",
          Importe: totalzona[zonas].toFixed(2),
          FechaTraspaso: " ",
        });
      }
      for (let zona in totaldestino) {
        //console.log(totaldestino[zona]);
        for (let destino in totaldestino[zona]) {
          value.push({
            Zona: zona,
            CveSucursalDestino: destino,
            CveSucursalOrigen: "zzz-Total Destino",
            Importe: totaldestino[zona][destino].toFixed(2),
            FechaTraspaso: " ",
          });
        }
      }
      //console.log(value);
      this.traspasos = value;
    },
    downloadexcel() {
      let arr = this.traspasos;
      let data = [];
      switch (this.bodyForFetch.tipoTabla) {
        case 1:
          data = [
            {
              sheet: "Reporte de Traspasos Pendientes por Recibir",
              columns: [
                { label: "Zona", value: "Zona" },
                { label: "Destino", value: "CveSucursalDestino" },
                { label: "Origen", value: "CveSucursalOrigen" },
                { label: "Importe", value: "Importe" },
              ],
              content: arr,
            },
          ];
          break;
        case 2:
          data = [
            {
              sheet: "Reporte de Traspasos Pendientes por Recibir",
              columns: [
                { label: "Zona", value: "Zona" },
                { label: "Destino", value: "CveSucursalDestino" },
                { label: "Origen", value: "CveSucursalOrigen" },
                { label: "Importe", value: "Importe" },
                { label: "Fecha Traspaso", value: "FechaTras" },
              ],
              content: arr,
            },
          ];
          break;
        case 3:
          data = [
            {
              sheet: "Reporte de Traspasos Pendientes por Recibir",
              columns: [
                { label: "Zona", value: "Zona" },
                { label: "Destino", value: "CveSucursalDestino" },
                { label: "Origen", value: "CveSucursalOrigen" },
                { label: "Importe", value: "Importe" },
                { label: "Fecha Trasp", value: "FechaTras" },
                { label: "No Trasp", value: "NoTraspaso" },
                { label: "Almacen", value: "Almacen" },
                { label: "Descripcion", value: "Descripcion" },
                { label: "Pedido", value: "Pedido" },
                { label: "Tipo Traspaso", value: "TipoTraspaso" },
                { label: "NOMBRE", value: "NOMBRE" },
              ],
              content: arr,
            },
          ];
          break;
        case 4:
          data = [
            {
              sheet: "Reporte de Traspasos Pendientes por Recibir",
              columns: [
                { label: "Zona", value: "Zona" },
                { label: "Destino", value: "CveSucursalDestino" },
                { label: "Origen", value: "CveSucursalOrigen" },
                { label: "Importe", value: "Importe" },
                { label: "Fecha Trasp", value: "FechaTras" },
                { label: "No Trasp", value: "NoTraspaso" },
                { label: "Articulo", value: "Articulo" },
                { label: "Descripcion", value: "Descripcion" },
                { label: "Pzas", value: "Cantidad" },
                { label: "Costo", value: "CostoPromedio" },
                { label: "Pedido", value: "Pedido" },
              ],
              content: arr,
            },
          ];
          break;
      }
      let settings = {
        fileName: "TraspasosSinRecibir", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    const response = await fetch(
      this.url + "/traspasos/traspasos_sin_recibir",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      }
    );
    if (response.status == 200) {
      const value = await response.json();
      this.formatHeaders();

      //console.log(value);
      this.totalSumAll(value);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style lang=""></style>
