<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"
      ><h1>Nivel de Surtido de Traspasos {{ titulo }}</h1></v-row
    >
    <v-row>
      <v-col>
        <v-row>Condiciones</v-row>
        <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
        <v-row v-if="bodyForFetch.strSucursal != ''"
          >Suc: {{ bodyForFetch.strSucursal }}</v-row
        >
        <v-row v-else>Suc: Todas</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row v-if="muestratable == 1">
      <v-col>
        <v-data-table
          dense
          :headers="headersDetalle"
          :items="detalleList"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="50"
        >
          <template v-slot:item.pedSym="{ item }">
            ${{ item.importepedido }}
          </template>
          <template v-slot:item.surSym="{ item }">
            ${{ item.importesurtido }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="muestratable == 0">
      <v-col>
        <v-data-table
          dense
          :headers="headersGlobal"
          :items="globalList"
          class="row-height-50 elevation-1"
          :items-per-page="globalList.length"
          hide-default-footer
        >
          <!--AQUI ESTA COMO FORMATEAR Y ANADIR COLUMNAS A DATA TABLE-->
          <template v-slot:item.centRen="{ item }">
            {{ percentRenglon(item.Renglones, item.renglsurtidoscompletos) }}
          </template>
          <template v-slot:item.centPzas="{ item }">
            {{ percentPiezas(item.surtido, item.pedido) }}
          </template>
          <template v-slot:item.pedSym="{ item }">
            ${{ item.importepedido }}
          </template>
          <template v-slot:item.surSym="{ item }">
            ${{ item.importesurtido }}
          </template>
          <template v-slot:item.centCash="{ item }">
            {{ percentCash(item.importesurtido, item.importepedido) }}
          </template>
          <template slot="body.append">
            <tr>
              <th></th>
              <th><b>Total</b></th>
              <th>
                <b>{{ rensum }}</b>
              </th>
              <th>
                <b>{{ rensurtsum }}</b>
              </th>
              <th>
                <b>{{ centRengSum }}%</b>
              </th>
              <th>
                <b>{{ pedsum }}</b>
              </th>
              <th>
                <b>{{ sursum }}</b>
              </th>
              <th>
                <b>{{ centPzasSum }}%</b>
              </th>
              <th>
                <b>${{ imppedsum }}</b>
              </th>
              <th>
                <b>${{ impsursum }}</b>
              </th>
              <th>
                <b>{{ centCashSum }}%</b>
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headersDetalle: [
      { text: "Origen", value: "Origen" },
      { text: "Destino", value: "Destino" },
      { text: "Bitacora", value: "Nobitacora" },
      { text: "Fecha Pedido", value: "fechapedido" },
      { text: "Trasp. Ini", value: "Trasp_Ini" },
      { text: "Fecha Ini", value: "Fecha_Ini" },
      { text: "Dias 1", value: "dias1" },
      { text: "Trasp. Fin", value: "Trasp_Fin" },
      { text: "Fecha Fin", value: "Fecha_Fin" },
      { text: "Dias 2", value: "dias2" },
      { text: "RenglPed", value: "renglped" },
      { text: "Surt. Com", value: "renglsurtcompletos" },
      { text: "P.Pedidas", value: "pzaspedidas" },
      { text: "P.Surtidas", value: "pzassurtidas" },
      { text: "$Pedido", value: "pedSym" },
      { text: "$Surtido", value: "surSym" },
    ],
    headersGlobal: [
      { text: "Origen", value: "Origen" },
      { text: "Destino", value: "Destino" },
      { text: "Ren.", value: "Renglones" },
      { text: "Ren. Surt.", value: "renglsurtidoscompletos" },
      { text: "%Ren", value: "centRen" },
      { text: "Pedido", value: "pedido" },
      { text: "Surtido", value: "surtido" },
      { text: "% Pzas", value: "centPzas" },
      { text: "$Pedido", value: "pedSym" },
      { text: "$Surtido", value: "surSym" },
      { text: "%$", value: "centCash" },
    ],
    detalleList: [],
    globalList: [],
    muestratable: -1,
    titulo: "",
    prntButton: -1,
    rensum: 0,
    rensurtsum: 0,
    pedsum: 0,
    sursum: 0,
    imppedsum: 0,
    impsursum: 0,
    centRengSum: 0,
    centPzasSum: 0,
    centCashSum: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    date1Format() {
      var fecha1 = "";
      var fechtemp1 = this.bodyForFetch.dtFechaInicio.split(" ");
      fecha1 = moment(fechtemp1[0]).format("YYYY/MM/DD");
      return fecha1;
    },
    date2Format() {
      var fecha2 = "";
      var fechtemp2 = this.bodyForFetch.dtFechaFin.split(" ");
      fecha2 = moment(fechtemp2[0]).format("YYYY/MM/DD");
      return fecha2;
    },
    percentRenglon(surt, ren) {
      var resultado;
      if (ren == 0) {
        resultado = 0;
      } else {
        resultado = (surt / ren) * 100;
      }
      return resultado.toFixed(2) + "%";
    },
    percentPiezas(sur, ped) {
      var resultado;
      if (ped == 0) {
        resultado = 0;
      } else {
        resultado = (sur / ped) * 100;
      }
      return resultado.toFixed(2) + "%";
    },
    percentCash(sur, ped) {
      var resultado;
      if (ped == 0) {
        resultado = 0;
      } else {
        resultado = (sur / ped) * 100;
      }
      return resultado.toFixed(2) + "%";
    },
    downloadexcel() {
      //change
      let arr = this.varcostinvlst;
      let data = [];
      switch (this.prntButton) {
        case 1:
          data = [
            {
              sheet: "Nivel de Surtido de Traspasos Detallado",
              columns: [
                { label: "Origen", value: "Origen" },
                { label: "Destino", value: "Destino" },
                { label: "Bitacora", value: "Nobitacora" },
                { label: "Fecha Pedido", value: "fechapedido" },
                { label: "Trasp. Ini", value: "Trasp_Ini" },
                { label: "Fecha Ini", value: "Fecha_Ini" },
                { label: "Dias 1", value: "dias1" },
                { label: "Trasp. Fin", value: "Trasp_Fin" },
                { label: "Fecha Fin", value: "Fecha_Fin" },
                { label: "Dias 2", value: "dias2" },
                { label: "RenglPed", value: "renglped" },
                { label: "Surt. Com", value: "renglsurtcompletos" },
                { label: "P.Pedidas", value: "pzaspedidas" },
                { label: "P.Surtidas", value: "pzassurtidas" },
                { label: "$Pedido", value: "pedSym" },
                { label: "$Surtido", value: "surSym" },
              ],
              content: arr,
            },
          ];
          break;
        case 0:
          data = [
            {
              sheet: "Nivel de Surtido de Traspasos Global",
              columns: [
                { label: "Origen", value: "Origen" },
                { label: "Destino", value: "Destino" },
                { label: "Ren.", value: "Renglones" },
                { label: "Ren. Surt.", value: "renglsurtidoscompletos" },
                { label: "%Ren", value: "centRen" },
                { label: "Pedido", value: "pedido" },
                { label: "Surtido", value: "surtido" },
                { label: "% Pzas", value: "centPzas" },
                { label: "$Pedido", value: "pedSym" },
                { label: "$Surtido", value: "surSym" },
                { label: "%$", value: "centCash" },
              ],
              content: arr,
            },
          ];
          break;
      }
      let settings = {
        fileName: "NivelSurtidoTraspasos", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
    formatDate(value) {
      for (var i = 0; i < value.length; i++) {
        if (value[i].Fecha_Fin != null) {
          var nudate1 = value[i].Fecha_Fin;
          nudate1 = nudate1.replace("T", " ");
          nudate1 = nudate1.replace("Z", "");
          nudate1 = moment(nudate1).format("DD/MM/YYYY HH:mm");
          value[i].Fecha_Fin = nudate1;
        }
        if (value[i].Fecha_Ini != null) {
          var nudate2 = value[i].Fecha_Ini;
          nudate2 = nudate2.replace("T", " ");
          nudate2 = nudate2.replace("Z", "");
          nudate2 = moment(nudate2).format("DD/MM/YYYY HH:mm");
          value[i].Fecha_Ini = nudate2;
        }
        if (value[i].fechapedido != null) {
          var nudate3 = value[i].fechapedido;
          nudate3 = nudate3.replace("T", " ");
          nudate3 = nudate3.replace("Z", "");
          nudate3 = moment(nudate3).format("DD/MM/YYYY HH:mm");
          value[i].fechapedido = nudate3;
        }
      }
      //console.log(value);
      this.detalleList = value;
    },
    async detallado() {
      const response = await fetch(
        this.url + "/traspasos/nivel_traspasos_detallado",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify(this.bodyForFetch),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        await this.formatDate(value);
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async global() {
      const response = await fetch(
        this.url + "/traspasos/nivel_traspasos_global",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify(this.bodyForFetch),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        for (var i = 0; i < value.length; i++) {
          this.rensum += value[i].Renglones;
          this.rensurtsum += value[i].renglsurtidoscompletos;
          this.pedsum += value[i].pedido;
          this.sursum += value[i].surtido;
          this.imppedsum += value[i].importepedido;
          this.impsursum += value[i].importesurtido;
        }
        var cRStemp;
        var cPStemp;
        var cCStemp;
        if (this.rensum == 0) {
          cRStemp = 0;
        } else {
          cRStemp = (this.rensurtsum / this.rensum) * 100;
        }
        if (this.pedsum == 0) {
          cPStemp = 0;
        } else {
          cPStemp = (this.sursum / this.pedsum) * 100;
        }
        if (this.imppedsum == 0) {
          cCStemp = 0;
        } else {
          cCStemp = (this.impsursum / this.imppedsum) * 100;
        }
        this.centRengSum = cRStemp.toFixed(2);
        this.centPzasSum = cPStemp.toFixed(2);
        this.centCashSum = cCStemp.toFixed(2);
        this.globalList = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
  },
  mounted() {
    this.prntButton = this.bodyForFetch.tempRepType;
    this.muestratable = this.bodyForFetch.tempRepType;
    this.titulo = this.bodyForFetch.tempRepType == 1 ? "Detallado" : "Global";
    delete this.bodyForFetch.tempRepType;
    switch (this.muestratable) {
      case 1:
        this.detallado();
        break;
      case 0:
        this.global();
        break;
    }
  },
};
</script>
<style lang=""></style>
