<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-row justify="center"><h1>Reporte de Ordenes de Compras</h1></v-row>
    <v-row>
      <v-col>
        <v-row>Condiciones</v-row>
        <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
        <v-row>Suc: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="compras"
          class="row-height-50 elevation-1"
          :items-per-page="compras.length"
          hide-default-footer
        >
          <template slot="body.append">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>XDR{{ subtotalSum }}</th>
              <th>XDR{{ ivaSum }}</th>
              <th>XDR{{ totalSum }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    headers: [
      { text: "Folio", value: "FolioCompra" },
      { text: "sucursal", value: "Sucursal" },
      { text: "", value: "Clave" },
      { text: "Proveedor", value: "razonsocial" },
      { text: "Fecha", value: "Fecha" },
      { text: "Comprador", value: "Comprador" },
      { text: "Subtotal", value: "Subtotal" },
      { text: "Iva", value: "Iva" },
      { text: "Total", value: "Total" },
    ],
    compras: [],
    subtotalSum: 0,
    ivaSum: 0,
    totalSum: 0,
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  async mounted() {
    const response = await fetch(this.url + "/compras/trae_ordenes_compras", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": this.token,
      },
      body: JSON.stringify(this.bodyForFetch),
    });
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      await this.dateAndSum(value);
      this.compras = value;
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
  methods: {
    date1Format() {
      var fecha1 = "";
      fecha1 = moment(this.bodyForFetch.dtFechaInicio).format("YYYY/MM/DD");
      return fecha1;
    },
    date2Format() {
      var fecha2 = "";
      fecha2 = moment(this.bodyForFetch.dtFechaFin).format("YYYY/MM/DD");
      return fecha2;
    },
    dateAndSum(tabla) {
      var sum1 = 0;
      var sum2 = 0;
      var sum3 = 0;
      for (var i = 0; i < tabla.length; i++) {
        var nudate = tabla[i].Fecha;
        nudate = nudate.replace("T", " ");
        nudate = nudate.replace("Z", "");
        nudate = moment(nudate).format("DD/MM/YYYY HH:mm");
        tabla[i].Fecha = nudate;
        sum1 += tabla[i].Subtotal;
        sum2 += tabla[i].Iva;
        sum3 += tabla[i].Total;
      }
      this.subtotalSum = parseFloat(sum1.toFixed(2));
      this.ivaSum = parseFloat(sum2.toFixed(2));
      this.totalSum = parseFloat(sum3.toFixed(2));
    },
    downloadexcel() {
      let arr = this.compras;
      let data = [
        {
          sheet: "Ordenes de Compras",
          columns: [
            { label: "Folio", value: "FolioCompra" },
            { label: "sucursal", value: "Sucursal" },
            { label: "", value: "Clave" },
            { label: "Proveedor", value: "razonsocial" },
            { label: "Fecha", value: "Fecha" },
            { label: "Comprador", value: "Comprador" },
            { label: "Subtotal", value: "Subtotal" },
            { label: "Iva", value: "Iva" },
            { label: "Total", value: "Total" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "OrdenesCompras", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
