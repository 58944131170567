<template>
  <v-container>
    <v-row>
      <v-col style="text-align: center" cols="12">
        <div class="hello">
          <h1>QR code styling for Vue</h1>
          <div id="qr-code" ref="qrCode"></div>
          <label>
            <input v-model="options.data" placeholder="Add data" />
            <v-btn v-on:click="download">Download</v-btn>
          </label>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Extension,
} from "qr-code-styling";
export default {
  name: "GeneradorQR",
  data: () => ({
    options: {},
    extension: "svg",
    qrCode: false,
  }),
  mounted: function () {
    this.options = {
      width: 300,
      height: 300,
      type: "svg",
      data: "https://jomarfacturacion.com/index",
      image: "/Logo-Nuevo-Jomar-Borde-Blanco.png",
      margin: 10,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.4,
        margin: 0,
        crossOrigin: "anonymous",
      },
      dotsOptions: {
        color: "#000000",
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
        // },
        type: "square",
      },
      backgroundOptions: {
        color: "#ffffff",
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
        // },
      },
      cornersSquareOptions: {
        color: "#000000",
        type: "square",
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
        // },
      },
      cornersDotOptions: {
        color: "#000000",
        type: "square",
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
        // },
      },
    };
    this.qrCode = new QRCodeStyling(this.options);
    this.qrCode.append(this.$refs["qrCode"]);
  },
  watch: {
    ["options.data"]: function () {
      this.qrCode.update(this.options);
    },
  },
  methods: {
    download() {
      this.qrCode.download();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
