<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <v-card-title><h2>Seguimiento de Clientes</h2></v-card-title>
          <v-row>
            <v-col cols="1">Cliente:</v-col>
            <v-col cols="7">
              <v-text-field
                label="Cliente"
                v-model="cliente"
                outlined
                solo
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn @click="llenarTabla()"
                ><v-icon dense>mdi-account-search</v-icon>Consultar</v-btn
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="1">Nombre:</v-col>
            <v-col cols="11">
              <v-text-field
                v-model="nombreClient"
                label="Nombre"
                outlined
                solo
                dense
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">Saldo:</v-col>
            <v-col cols="5">
              <v-text-field
                v-model="saldoClient"
                label="Saldo"
                outlined
                solo
                dense
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">Telefono:</v-col>
            <v-col cols="5">
              <v-text-field
                v-model="telefonoClient"
                label="Telefono"
                outlined
                solo
                dense
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card>
          <v-card-title>Nuevo Registro</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="1">Vendedor:</v-col>
                <v-col>
                  <v-autocomplete
                    v-model="venselect"
                    dense
                    solo
                    :items="itemsVend"
                    item-text="Nvendedor"
                    item-value="Vendedor"
                    :disabled="activarNuevo"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1">Contacto:</v-col>
                <v-col cols="11">
                  <v-text-field
                    v-model="contactoClient"
                    label="Contacto"
                    outlined
                    solo
                    dense
                    :disabled="activarNuevo"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1">Comentarios:</v-col>
                <v-col cols="11">
                  <v-text-field
                    v-model="comentariosClient"
                    label="Comentarios"
                    outlined
                    solo
                    dense
                    :disabled="activarNuevo"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1">Tipo de Contacto:</v-col>
                <v-col>
                  <v-autocomplete
                    v-model="tipoContactoClient"
                    dense
                    solo
                    :items="itemsTipoContacto"
                    item-text="Tipo de Contacto"
                    item-value="Tipo de Contacto"
                    :disabled="activarNuevo"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="5">
                  <v-btn @click="nuevoUsuario()"
                    ><v-icon dense>mdi-account</v-icon>Nuevo</v-btn
                  >
                </v-col>
                <v-col cols="5">
                  <v-btn
                    :disabled="activarNuevo"
                    @click="guardarLlamadaCliente()"
                    ><v-icon dense>mdi-content-save</v-icon>Grabar</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card-title>Historial de Registros</v-card-title>
        <v-row>
          <v-col cols="5">
            <v-menu
              v-model="menuFechaInicio"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fechaIn"
                  label="Fecha Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaIn"
                @input="menuFechaInicio = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5">
            <v-menu
              v-model="menuFechaFin"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fechaFn"
                  label="Fecha Fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaFn"
                @input="menuFechaFin = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headersLlam" :items="itemsLlamadas">
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    cliente: "",
    nombreClient: "",
    saldoClient: "",
    telefonoClient: "",
    fechaIn: "",
    fechaFn: "",
    venselect: "",
    contactoClient: "",
    comentariosClient: "",
    tipoContactoClient: "",
    menuFechaInicio: "",
    menuFechaFin: "",
    activarNuevo: true,
    itemsLlamadas: [],
    itemsVend: [],
    itemsTipoContacto: [],
    headersLlam: [
      {
        text: "Folio",
        value: "FOLIO",
      },
      {
        text: "Fecha Llamada",
        value: "FECHALLAMADA",
      },
      {
        text: "Vendedor",
        value: "VENDEDOR",
      },
      {
        text: "Usuario",
        value: "USUARIO_ID",
      },
      {
        text: "Nombre",
        value: "NOMBRE",
      },
      {
        text: "Contacto Cliente",
        value: "CONTACTOCLTE",
      },
      {
        text: "Comentarios",
        value: "COMENTARIOS",
      },
      {
        text: "Tipo Contacto",
        value: "TIPOCONTACTO",
      },
    ],
  }),
  computed: {
    ...mapState(["sucursales", "url", "userInfo", "token", "sucSelected"]),
    computedDateFormattedMomentjs() {
      return this.fechaIn ? moment(this.fechaIn).format("YYYY/MM/DD") : "";
    },
    computedDateFormattedMomentjss() {
      return this.fechaFn ? moment(this.fechaFn).format("YYYY/MM/DD") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.traerListaVendedores();
    this.traerListaTipoDeContacto();
    this.fechaIn = this.printDate();
    this.fechaFn = this.printDateFn();
  },
  methods: {
    nuevoUsuario() {
      this.$refs.form.reset();
      this.activarNuevo = false;
    },
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
    async traerListaVendedores() {
      const response = await fetch(
        this.url +
          "/ventas/lista_vendedores?strSucursal=" +
          this.userInfo.sucursal,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      //console.log(value);
      this.itemsVend = value.resultado;
      ////console.log(this.date);
    },
    async traerListaTipoDeContacto() {
      try {
        const response = await fetch(this.url + "/ventas/listaTipoDeContacto", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.itemsTipoContacto = value.resultado;
          this.nombreClient = value.NOMBRE;
          this.saldoClient = value.SALDO;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async guardarLlamadaCliente() {
      try {
        const response = await fetch(
          this.url + "/ventas/GuardaLlamadaCliente",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Sucursal: this.userInfo.sucursal,
              CLIENTE: this.cliente,
              Vendedor: this.venselect,
              Usuario: this.userInfo.IdUsuario,
              ContactoClte: this.contactoClient,
              Comentarios: this.comentariosClient,
              TipoContacto: this.tipoContactoClient,
              Importe: req.body.Importe,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.llenarTabla();
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async llenarTabla() {
      try {
        //console.log(this.token);
        const response = await fetch(
          this.url + "/ventas/rpt_consulta_clientes_llamada",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              cliente: this.cliente,
              FechaIn: moment(this.fechaIn).format("YYYYMMDD"),
              FechaFn: moment(this.fechaFn).format("YYYYMMDD"),
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.itemsLlamadas = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async abrirInfoCliente() {
      this.bodyForFetch = {
        cliente: this.cliente,
      };
      ////console.log(this.bodyForFetch);
      let routeData = this.$router.resolve({
        name: "PantallaInfoCliente",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
