import Vue from "vue";
import Vuex from "vuex";
import actions from "../Modules/actions";
import mutations from "../Modules/mutations";
import state from "../Modules/state";
import VuexPersistence from "vuex-persist";
import reporteKardex from "@/Modules/reporteKardex";
import reporteventascat from "@/Modules/reporteventascat";
import reportepromociones from "@/Modules/reportepromociones";
import reporteventascall from "@/Modules/reporteventascall";
import reporteventascredito from "@/Modules/reporteventascredito";
import reporteventascontado from "@/Modules/reporteventascontado";
import reporteventascreditoycontado from "@/Modules/reporteventascreditoycontado";
import reporteventasvendedores from "@/Modules/reporteventasvendedores";
import reportetraspasossucursal from "@/Modules/reportetraspasossucursal";
import reporteexistenciacategorias from "@/Modules/reporteexistenciacategorias";
import reportelistadocatalmacen from "@/Modules/reportelistadocatalmacen";
import reporteembarquetraspasos from "@/Modules/reporteembarquetraspasos";
import reporteembarqueindividual from "@/Modules/reporteembarqueindividual";
import reporteembarquexsucursal from "@/Modules/reporteembarquexsucursal";
import reportevisitasxventasefectuadas from "@/Modules/reportevisitasxventasefectuadas";
import reportepetrepxsuc from "@/Modules/reportepetrepxsuc";
import reporteinventarionegativo from "@/Modules/reporteinventarionegativo";
import reportevaloracionporcategoria from "@/Modules/reportevaloracionporcategoria";
import reporteventabaterias from "@/Modules/reporteventabaterias";
import reporteuniversopedidos from "@/Modules/reporteuniversopedidos";
import reportepedidosescaneados from "@/Modules/reportepedidosescaneados";
import reportenegados from "@/Modules/reportenegados";
import reportedevolucionprov from "@/Modules/reportedevolucionprov";
import reportereimprimircompra from "@/Modules/reportereimprimircompra";
import reportefacturasescaneadas from "@/Modules/reportefacturasescaneadas";
import reportepedidoscancelados from "@/Modules/reportepedidoscancelados";
import reportereimpcontrarecibo from "@/Modules/reportereimpcontrarecibo";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters: {},
  mutations,
  actions,
  modules: {
    reporteKardex,
    reporteventascat,
    reportepromociones,
    reporteventascall,
    reporteventascredito,
    reporteventascontado,
    reporteventascreditoycontado,
    reporteventasvendedores,
    reportetraspasossucursal,
    reporteexistenciacategorias,
    reportelistadocatalmacen,
    reporteembarquetraspasos,
    reporteembarqueindividual,
    reporteembarquexsucursal,
    reportevisitasxventasefectuadas,
    reportepetrepxsuc,
    reporteinventarionegativo,
    reportevaloracionporcategoria,
    reporteventabaterias,
    reporteuniversopedidos,
    reportepedidosescaneados,
    reportenegados,
    reportedevolucionprov,
    reportereimprimircompra,
    reportefacturasescaneadas,
    reportepedidoscancelados,
    reportereimpcontrarecibo,
  },
  plugins: [
    new VuexPersistence({
      storage: window.localStorage,
    }).plugin,
  ],
});
