<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-form>
                <v-card-title> Reporte de Traspasos </v-card-title>
                <v-row>
                  <v-col cols>
                    <v-text-field
                      v-model="noTraspaso"
                      label="Numero Traspaso"
                      outlined
                      solo
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-autocomplete
                  v-model="sucPide"
                  :items="sucursales"
                  item-text="sucursal"
                  item-value="sucursal"
                  :rules="[(v) => !!v || 'Seleccione sucursal']"
                  solo
                  label="Sucursal recibe"
                  required
                  dense
                >
                </v-autocomplete>
                <v-autocomplete
                  v-model="sucTraspasa"
                  :items="sucursales"
                  item-text="sucursal"
                  item-value="sucursal"
                  :rules="[(v) => !!v || 'Seleccione sucursal']"
                  solo
                  label="Sucursal traspasa"
                  required
                  dense
                >
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="abrirReporteTraspasos()"
                ><v-icon dense>mdi-printer</v-icon>Imprimir</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaTraspasos",
  data: () => ({
    sucPide: "TODOS",
    sucTraspasa: "TODOS",
    noTraspaso: "",
  }),
  computed: {
    ...mapState(["sucursales"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    abrirReporteTraspasos() {
      //console.log(moment(this.fechaInicio).format("YYYYmmdd") + "00:00:00");
      this.bodyForFetch = {
        noTraspaso: this.noTraspaso,
        sucPide: this.sucPide,
        sucTraspasa: this.sucTraspasa,
      };
      let routeData = this.$router.resolve({
        name: "ReporteComprobanteTraspasos",
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.$store.commit("setNavbarToggle", 0);
    this.$store.dispatch("getSucursales", false);
  },
};
</script>
<style></style>
