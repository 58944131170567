import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import ReporteKardex from "../views/ReportesAlmacen/ReporteKardex.vue";
import PantallKardex from "../views/ReportesAlmacen/PantallKardex.vue";
import PantallaReimpresionEmbarque from "@/views/ReportesTraspasos/PantallaReimpresionEmbarque";
import ReporteReimpresionEmbarque from "@/views/ReportesTraspasos/ReporteReimpresionEmbarque";
import ReporteVentasCAT from "@/views/ReportesVentas/ReporteVentasCAT";
import PantallVentasCAT from "@/views/ReportesVentas/PantallVentasCAT";
import PantallaCotizaciones from "@/views/ReportesVentas/PantallaCotizaciones";
import ReporteCotizaciones from "@/views/ReportesVentas/ReporteCotizaciones";
import PantallaPromociones from "@/views/ReportesVentas/PantallaPromociones";
import ReportePromociones from "@/views/ReportesVentas/ReportePromociones";
import PantallaRedondeo from "@/views/ReportesVentas/PantallaRedondeo";
import ReporteRedondeoC from "@/views/ReportesVentas/ReporteRedondeoC";
import ReporteRedondeoD from "@/views/ReportesVentas/ReporteRedondeoD";
import PantallaVentasCall from "@/views/ReportesVentas/PantallaVentasCall";
import ReporteVentasCall from "@/views/ReportesVentas/ReporteVentasCall";
import PantallaVentasXPrecio from "@/views/ReportesVentas/PantallaVentasXPrecio";
import ReporteVentasCredito from "@/views/ReportesVentas/ReporteVentasCredito";
import ReporteVentasContado from "@/views/ReportesVentas/ReporteVentasContado";
import ReporteVentasCreditoyContado from "@/views/ReportesVentas/ReporteVentasCreditoyContado";
import ReporteVentasPorVendedor from "@/views/ReportesVentas/ReporteVentasPorVendedor";
import PantallaComprobanteTraspasos from "@/views/ReportesTraspasos/PantallaComprobanteTraspasos";
import ReporteComprobanteTraspasos from "@/views/ReportesTraspasos/ReporteComprobanteTraspasos";
import PantallaPedidoMayoreo from "@/views/ReportesVentas/PantallaPedidoMayoreo";
import ReportePedidoMayoreo from "@/views/ReportesVentas/ReportePedidoMayoreo";
import PantallaVentasMes from "@/views/ReportesVentas/PantallaVentasMes";
import ReporteVentasMesCategoria from "@/views/ReportesVentas/ReporteVentasMesCategoria";
import ReporteVentasMesCliente from "@/views/ReportesVentas/ReporteVentasMesCliente";
import ReporteVentasMesVendedor from "@/views/ReportesVentas/ReporteVentasMesVendedor";
import PantallaDiferenciasCascos from "@/views/ReportesAlmacen/PantallaDiferenciasCascos";
import ReporteDiferenciasCascos from "@/views/ReportesAlmacen/ReporteDiferenciasCascos";
import PantallaExistenciaCategoria from "@/views/ReportesAlmacen/PantallaExistenciaCategoria";
import ReporteExistenciaCategoria from "@/views/ReportesAlmacen/ReporteExistenciaCategoria";
import TablaPantallaPrueba from "@/views/TablaPantallaPrueba";
import PantallaLlamadasClientes from "@/views/ReportesVentas/PantallaLlamadasClientes";
import ReporteLlamadasClientes from "@/views/ReportesVentas/ReporteLlamadasClientes";
import ResumenLlamadasClientes from "@/views/ReportesVentas/ResumenLlamadasClientes";
import PantallaKardexCascos from "@/views/ReportesAlmacen/PantallaKardexCascos";
import ReporteKardexCascos from "@/views/ReportesAlmacen/ReporteKardexCascos";
import PantallaPeticionesRepa from "@/views/Administracion/PantallaPeticionesRepa";
import PantallaConsultaPetiRepa from "@/views/Administracion/PantallaConsultaPetiRepa";
import PantallaMttoRepartidor from "@/views/Administracion/PantallaMttoRepartidor";
import PantallaChecksRepartidores from "@/views/Administracion/PantallaChecksRepartidores";
import PantallaPermisosUsuarios from "@/views/Administracion/PantallaPermisosUsuarios";
import PantallaPermisosAplicacion from "@/views/Administracion/PantallaPermisosAplicacion";
import PantallaAuditoriaConteo from "@/views/ReportesAlmacen/PantallaAuditoriaConteo";
import ReporteAuditoriaConteo from "@/views/ReportesAlmacen/ReporteAuditoriaConteo";
import PantallaOrdenesCompras from "@/views/ReportesCompras/PantallaOrdenesCompras";
import ReporteOrdenesCompras from "@/views/ReportesCompras/ReporteOrdenesCompras";
import GeneradorQR from "@/views/Extras/GeneradorQR";
import PantallaListadoCatAlmacen from "@/views/ReportesAlmacen/PantallaListadoCatAlmacen";
import ReporteListadoCatAlmacen from "@/views/ReportesAlmacen/ReporteListadoCatAlmacen";
import MonitorPeticionesRepartidores from "@/views/Administracion/MonitorPeticionesRepartidores";
import PantallaEmbarqueTraspasos from "@/views/ReportesTraspasos/PantallaEmbarqueTraspasos";
import ReporteEmbarqueTraspasos from "@/views/ReportesTraspasos/ReporteEmbarqueTraspasos";
import PantallaEmbarquesSinFolio from "@/views/ReportesTraspasos/PantallaEmbarquesSinFolio";
import ReporteEmbConEsc from "@/views/ReportesTraspasos/ReporteEmbConEsc";
import ReporteEmbSinEsc from "@/views/ReportesTraspasos/ReporteEmbSinEsc";
import PantallaEmbarqueIndividual from "@/views/ReportesTraspasos/PantallaEmbarqueIndividual";
import ReporteEmbarqueIndividual from "@/views/ReportesTraspasos/ReporteEmbarqueIndividual";
import ReporteEmbarquexSucursal from "@/views/ReportesTraspasos/ReporteEmbarquexSucursal";
import VentasMostradorPromo from "@/views/Ventas/VentasMostradorPromo";
import PantallaInventarioArticulos from "@/views/ReportesAlmacen/PantallaInventarioArticulos";
import ReporteArticulosContado from "@/views/ReportesAlmacen/ReporteArticulosContado";
import ReporteArticulosNoContado from "@/views/ReportesAlmacen/ReporteArticulosNoContado";
import PantallaVisitasXVentasEfectuadas from "@/views/ReportesVentas/PantallaVisitasXVentasEfectuadas";
import ReporteVisitasXVentasEfectuadas from "@/views/ReportesVentas/ReporteVisitasXVentasEfectuadas";
import PantallaPeticionesRepXSuc from "@/views/ReportesVentas/PantallaPeticionesRepXSuc";
import ReportePeticionesRepXSuc from "@/views/ReportesVentas/ReportePeticionesRepXSuc";
import PantallaInventarioNegativo from "@/views/ReportesAlmacen/PantallaInventarioNegativo";
import ReporteInventarioNegativo from "@/views/ReportesAlmacen/ReporteInventarioNegativo";
import PantallaDisponibilidad from "@/views/ReportesAlmacen/PantallaDisponibilidad";
import ReporteDisponibilidad from "@/views/ReportesAlmacen/ReporteDisponibilidad";
import PantallaGastosContado from "@/views/ReportesCompras/PantallaGastosContado";
import ReporteGastosContado from "@/views/ReportesCompras/ReporteGastosContado";
import PantallaVariacionCostoInventario from "@/views/ReportesAlmacen/PantallaVariacionCostoInventario";
import ReporteVariacionCostoInventario from "@/views/ReportesAlmacen/ReporteVariacionCostoInventario";
import PantallaMovimientosAlmacen from "@/views/ReportesAlmacen/PantallaMovimientosAlmacen";
import ReporteMovimientosAlmacen from "@/views/ReportesAlmacen/ReporteMovimientosAlmacen";
import PantallaCosteoVentasTraspasos from "@/views/ReportesAlmacen/PantallaCosteoVentasTraspasos";
import ReporteCosteoVentasTraspasos from "@/views/ReportesAlmacen/ReporteCosteoVentasTraspasos";
import PantallaValoracionInventario from "@/views/ReportesAlmacen/PantallaValoracionInventario";
import PantallaArticulosSinMov from "@/views/ReportesAlmacen/PantallaArticulosSinMov";
import ReporteArticulosSinMov from "@/views/ReportesAlmacen/ReporteArticulosSinMov";
import PantallaNivelSurtidoTraspasos from "@/views/ReportesTraspasos/PantallaNivelSurtidoTraspasos";
import ReporteNivelSurtidoTraspasos from "@/views/ReportesTraspasos/ReporteNivelSurtidoTraspasos";
import PantallaTraspasosSinRecibir from "@/views/ReportesTraspasos/PantallaTraspasosSinRecibir";
import ReporteTraspasosSinRecibir from "@/views/ReportesTraspasos/ReporteTraspasosSinRecibir";
import PantallaNivelSurtidoVentas from "@/views/ReportesVentas/PantallaNivelSurtidoVentas";
import ReporteNivelSurtidoVentas from "@/views/ReportesVentas/ReporteNivelSurtidoVentas";
import PantallaSegClientes from "@/views/ReportesVentas/PantallaSegClientes";
import PantallaInfoCliente from "@/views/ReportesVentas/PantallaInfoCliente";
import PantallaValoracionCategoria from "@/views/ReportesAlmacen/PantallaValoracionCategoria";
import ReporteValoracionCategoria from "@/views/ReportesAlmacen/ReporteValoracionCategoria";
import PantallaSegPedido from "@/views/ReportesVentas/PantallaSegPedido";
import PantallaVentaBaterias from "@/views/ReportesVentas/PantallaVentaBaterias";
import ReporteVentaBaterias from "@/views/ReportesVentas/ReporteVentaBaterias";
import PantallaUniversoPedidos from "@/views/ReportesVentas/PantallaUniversoPedidos";
import ReporteUniversoPedidos from "@/views/ReportesVentas/ReporteUniversoPedidos";
import ReportePedidosEscaneados from "@/views/ReportesVentas/ReportePedidosEscaneados";
import PantallaNegados from "@/views/ReportesVentas/PantallaNegados";
import ReporteNegados from "@/views/ReportesVentas/ReporteNegados";
import PantallaDevolucionesProv from "@/views/ReportesCompras/PantallaDevolucionesProv";
import ReporteDevolucionesProv from "@/views/ReportesCompras/ReporteDevolucionesProv";
import PantallaReimprimirOrdenCompra from "@/views/ReportesCompras/PantallaReimprimirOrdenCompra";
import ReporteReimprimirOrdenCompra from "@/views/ReportesCompras/ReporteReimprimirOrdenCompra";
import PantallaFacturasEscaneadas from "@/views/ReportesCompras/PantallaFacturasEscaneadas";
import ReporteFacturasEscaneadas from "@/views/ReportesCompras/ReporteFacturasEscaneadas";
import PantallaPedidosCancelados from "@/views/ReportesVentas/PantallaPedidosCancelados";
import ReportePedidosCancelados from "@/views/ReportesVentas/ReportePedidosCancelados";
import PantallaReimpContraRecibo from "@/views/ReportesCompras/PantallaReimpContraRecibo";
import ReporteReimpContraRecibo from "@/views/ReportesCompras/ReporteReimpContraRecibo";
import PantallaGeneradorToken from "@/views/Administracion/PantallaGeneradorToken.vue";
import Politicas from "@/views/Politicas";
import PantallaMttoTecnicoLTH from "@/views/Administracion/PantallaMttoTecnicoLTH";
import PantallaPeticionesLTH from "@/views/Administracion/PantallaPeticionesLTH";
import PantallaConsultaPetiLTH from "@/views/Administracion/PantallaConsultaPetiLTH";
import MonitorPetLTH from "@/views/Administracion/MonitorPetLTH";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "Test",
    component: TablaPantallaPrueba,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reporte_kardex",
    name: "ReporteKardex",
    component: ReporteKardex,
  },
  {
    path: "/reportes_almacen/pantalla_kardex",
    name: "PantallKardex",
    component: PantallKardex,
  },
  {
    path: "/reportes_traspasos/pantalla_embarque",
    name: "PantallaReimpresionEmbarque",
    component: PantallaReimpresionEmbarque,
  },
  {
    path: "/reportes_traspasos/reimpresion_embarque",
    name: "ReporteReimpresionEmbarque",
    component: ReporteReimpresionEmbarque,
  },
  {
    path: "/reportes_ventas/ReporteVentasCAT",
    name: "ReporteVentasCAT",
    component: ReporteVentasCAT,
  },
  {
    path: "/reportes_ventas/PantallVentasCAT",
    name: "PantallVentasCAT",
    component: PantallVentasCAT,
  },
  {
    path: "/reportes_ventas/pantalla_cotizaciones",
    name: "PantallaCotizaciones",
    component: PantallaCotizaciones,
  },
  {
    path: "/reportes_ventas/reimpresion_cotizaciones",
    name: "ReporteCotizaciones",
    component: ReporteCotizaciones,
  },
  {
    path: "/reportes_ventas/ReportePromociones",
    name: "ReportePromociones",
    component: ReportePromociones,
  },
  {
    path: "/reportes_ventas/PantallaPromociones",
    name: "PantallaPromociones",
    component: PantallaPromociones,
  },
  {
    path: "/reportes_ventas/pantalla_redondeo",
    name: "PantallaRedondeo",
    component: PantallaRedondeo,
  },
  {
    path: "/reportes_ventas/reimpresion_redondeo_c",
    name: "ReporteRedondeoC",
    component: ReporteRedondeoC,
  },
  {
    path: "/reportes_ventas/reimpresion_redondeo_d",
    name: "ReporteRedondeoD",
    component: ReporteRedondeoD,
  },
  {
    path: "/reportes_ventas/PantallaVentasCall",
    name: "PantallaVentasCall",
    component: PantallaVentasCall,
  },
  {
    path: "/reportes_ventas/ReporteVentasCall",
    name: "ReporteVentasCall",
    component: ReporteVentasCall,
  },
  {
    path: "/reportes_ventas/PantallaVentasPorPrecio",
    name: "PantallaVentasPorPrecio",
    component: PantallaVentasXPrecio,
  },
  {
    path: "/reportes_ventas/ReporteVentasCredito",
    name: "ReporteVentasCredito",
    component: ReporteVentasCredito,
  },
  {
    path: "/reportes_ventas/ReporteVentasContado",
    name: "ReporteVentasContado",
    component: ReporteVentasContado,
  },
  {
    path: "/reportes_ventas/ReporteVentasCreditoyContado",
    name: "ReporteVentasCreditoyContado",
    component: ReporteVentasCreditoyContado,
  },
  {
    path: "/reportes_ventas/ReporteVentasPorVendedor",
    name: "ReporteVentasPorVendedor",
    component: ReporteVentasPorVendedor,
  },
  {
    path: "/reportes_traspasos/PantallaComprobanteTraspasos",
    name: "PantallaComprobanteTraspasos",
    component: PantallaComprobanteTraspasos,
  },
  {
    path: "/reportes_traspasos/ReporteComprobanteTraspasos",
    name: "ReporteComprobanteTraspasos",
    component: ReporteComprobanteTraspasos,
  },
  {
    path: "/reportes_ventas/pantalla_reimpresion_pedido_mayoreo",
    name: "PantallaReimpresionPedidoMayoreo",
    component: PantallaPedidoMayoreo,
  },
  {
    path: "/reportes_ventas/reporte_reimpresion_pedido_mayoreo",
    name: "ReporteReimpresionPedidoMayoreo",
    component: ReportePedidoMayoreo,
  },
  {
    path: "/reportes_ventas/pantalla_ventas_mes",
    name: "PantallaVentasMes",
    component: PantallaVentasMes,
  },
  {
    path: "/reportes_ventas/pantalla_ventas_mes_categoria",
    name: "ReporteVentasMesCategoria",
    component: ReporteVentasMesCategoria,
  },
  {
    path: "/reportes_ventas/pantalla_ventas_mes_cliente",
    name: "ReporteVentasMesCliente",
    component: ReporteVentasMesCliente,
  },
  {
    path: "/reportes_ventas/pantalla_ventas_mes_vendedor",
    name: "ReporteVentasMesVendedor",
    component: ReporteVentasMesVendedor,
  },
  {
    path: "/reportes_almacen/pantalla_diferencias_cascos",
    name: "PantallaDiferenciasCascos",
    component: PantallaDiferenciasCascos,
  },
  {
    path: "/reportes_almacen/reporte_diferencias_cascos",
    name: "ReporteDiferenciasCascos",
    component: ReporteDiferenciasCascos,
  },
  {
    path: "/reportes_almacen/pantalla_existencia_categoria",
    name: "PantallaExistenciaCategoria",
    component: PantallaExistenciaCategoria,
  },
  {
    path: "/inventario/reporte_existencia_categoria",
    name: "ReporteExistenciaCategoria",
    component: ReporteExistenciaCategoria,
  },
  {
    path: "/reportes_ventas/pantalla_llamadas_clientes",
    name: "PantallaLlamadasClientes",
    component: PantallaLlamadasClientes,
  },
  {
    path: "/reportes_ventas/reporte_llamadas_clientes",
    name: "ReporteLlamadasClientes",
    component: ReporteLlamadasClientes,
  },
  {
    path: "/reportes_ventas/resumen_llamadas_clientes",
    name: "ResumenLlamadasClientes",
    component: ResumenLlamadasClientes,
  },
  {
    path: "/reportes_almacen/pantalla_kardex_cascos",
    name: "PantallaKardexCascos",
    component: PantallaKardexCascos,
  },
  {
    path: "/inventario/reporte_kardex_cascos",
    name: "ReporteKardexCascos",
    component: ReporteKardexCascos,
  },
  {
    path: "/administracion/pantalla_peticiones_repartidores",
    name: "PeticionesRepartidores",
    component: PantallaPeticionesRepa,
  },
  {
    path: "/administracion/pantalla_consulta_peticiones",
    name: "PantallaConsultaPetiRepa",
    component: PantallaConsultaPetiRepa,
  },
  {
    path: "/administracion/pantalla_mantenimiento_repartidor",
    name: "MantenimientoRepartidor",
    component: PantallaMttoRepartidor,
  },
  {
    path: "/administracion/pantalla_actualizaciones_repartidores",
    name: "PantallaChecksRepartidores",
    component: PantallaChecksRepartidores,
  },
  {
    path: "/administracion/pantalla_permisos_usuarios",
    name: "PantallaPermisosUsuarios",
    component: PantallaPermisosUsuarios,
  },
  {
    path: "/administracion/pantalla_permisos_aplicacion",
    name: "PantallaPermisosAplicacion",
    component: PantallaPermisosAplicacion,
  },
  {
    path: "/reportes_almacen/pantalla_auditoria_conteo",
    name: "PantallaAuditoriaConteo",
    component: PantallaAuditoriaConteo,
  },
  {
    path: "/administracion/reporte_auditoria_conteo",
    name: "ReporteAuditoriaConteo",
    component: ReporteAuditoriaConteo,
  },
  {
    path: "/extras/generador_qr",
    name: "GeneradorQR",
    component: GeneradorQR,
  },
  {
    path: "/reportes_almacen/pantalla_listado_cat_almacen",
    name: "PantallaListadoCatAlmacen",
    component: PantallaListadoCatAlmacen,
  },
  {
    path: "/inventario/reporte_listado_cat_almacen",
    name: "ReporteListadoCatAlmacen",
    component: ReporteListadoCatAlmacen,
  },
  {
    path: "/reporte_compras/pantalla_ordenes_compras",
    name: "PantallaOrdenesCompras",
    component: PantallaOrdenesCompras,
  },
  {
    path: "/compras/reporte_ordenes_compras",
    name: "ReporteOrdenesCompras",
    component: ReporteOrdenesCompras,
  },
  {
    path: "/administracion/monitor_peticiones_repartidores",
    name: "MonitorPetRepartidor",
    component: MonitorPeticionesRepartidores,
  },
  {
    path: "/reportes_traspasos/PantallaEmbarqueTraspasos",
    name: "PantallaEmbarqueTraspasos",
    component: PantallaEmbarqueTraspasos,
  },
  {
    path: "/reportes_traspasos/ReporteEmbarqueTraspasos",
    name: "ReporteEmbarqueTraspasos",
    component: ReporteEmbarqueTraspasos,
  },
  {
    path: "/reportes_traspasos/pantalla_embarques_sin_folio",
    name: "PantallaEmbarquesSinFolio",
    component: PantallaEmbarquesSinFolio,
  },
  {
    path: "/reportes_traspasos/reporte_embarques_sinfolio_con_escaner",
    name: "ReporteEmbConEsc",
    component: ReporteEmbConEsc,
  },
  {
    path: "/reportes_traspasos/reporte_embarques_sinfolio_sin_escaner",
    name: "ReporteEmbSinEsc",
    component: ReporteEmbSinEsc,
  },
  {
    path: "/reportes_traspasos/PantallaEmbarqueIndividual",
    name: "PantallaEmbarqueIndividual",
    component: PantallaEmbarqueIndividual,
  },
  {
    path: "/reportes_traspasos/ReporteEmbarqueIndividual",
    name: "ReporteEmbarqueIndividual",
    component: ReporteEmbarqueIndividual,
  },
  {
    path: "/reportes_traspasos/ReporteEmbarquexSucursal",
    name: "ReporteEmbarquexSucursal",
    component: ReporteEmbarquexSucursal,
  },
  {
    path: "/ventas/ventas_mostrador_c_promos",
    name: "VentasMostradorconPromociones",
    component: VentasMostradorPromo,
  },
  {
    path: "/reportes_almacen/pantalla_inventario_articulos",
    name: "PantallaInventarioArticulos",
    component: PantallaInventarioArticulos,
  },
  {
    path: "/reportes_almacen/reporte_articulos_contado",
    name: "ReporteArticulosContado",
    component: ReporteArticulosContado,
  },
  {
    path: "/reportes_almacen/reporte_articulos_no_contado",
    name: "ReporteArticulosNoContado",
    component: ReporteArticulosNoContado,
  },
  {
    path: "/reportes_ventas/pantalla_visitas_efectuadas_cat",
    name: "PantallaVisitasPorVentasEfectuadas",
    component: PantallaVisitasXVentasEfectuadas,
  },
  {
    path: "/reportes_ventas/reporte_visitas_efectuadas_cat",
    name: "ReporteVisitasXVentasEfectuadas",
    component: ReporteVisitasXVentasEfectuadas,
  },
  {
    path: "/reportes_ventas/pantalla_peticiones_repxsuc",
    name: "PantallaPeticionesRepXSuc",
    component: PantallaPeticionesRepXSuc,
  },
  {
    path: "/reportes_ventas/reporte_peticiones_repxsuc",
    name: "ReportePeticionesRepXSuc",
    component: ReportePeticionesRepXSuc,
  },
  {
    path: "/reportes_almacen/pantalla_inventario_negativo",
    name: "PantallaInventarioNegativo",
    component: PantallaInventarioNegativo,
  },
  {
    path: "/inventario/reporte_inventario_negativo",
    name: "ReporteInventarioNegativo",
    component: ReporteInventarioNegativo,
  },
  {
    path: "/inventario/pantalla_disponibilidad",
    name: "PantallaDisponibilidad",
    component: PantallaDisponibilidad,
  },
  {
    path: "/inventario/reporte_disponibilidad",
    name: "ReporteDisponibilidad",
    component: ReporteDisponibilidad,
  },
  {
    path: "/reporte_compras/pantalla_gastos_contado",
    name: "PantallaGastosContado",
    component: PantallaGastosContado,
  },
  {
    path: "/reporte_compras/reporte_gastos_contado",
    name: "ReporteGastosContado",
    component: ReporteGastosContado,
  },
  {
    path: "/inventario/pantalla_var_cost_inventario",
    name: "PantallaVariacionCostoInventario",
    component: PantallaVariacionCostoInventario,
  },
  {
    path: "/inventario/reporte_var_cost_inventario",
    name: "ReporteVariacionCostoInventario",
    component: ReporteVariacionCostoInventario,
  },
  {
    path: "/inventario/pantalla_movimiento_almacen",
    name: "PantallaMovimientosAlmacen",
    component: PantallaMovimientosAlmacen,
  },
  {
    path: "/inventario/reporte_movimiento_almacen",
    name: "ReporteMovimientosAlmacen",
    component: ReporteMovimientosAlmacen,
  },
  {
    path: "/inventario/pantalla_costeo_ventas_traspasos",
    name: "PantallaCosteoVentasTraspasos",
    component: PantallaCosteoVentasTraspasos,
  },
  {
    path: "/inventario/reporte_costeo_ventas_traspasos",
    name: "ReporteCosteoVentasTraspasos",
    component: ReporteCosteoVentasTraspasos,
  },
  {
    path: "/inventario/pantalla_valoracion_inventario",
    name: "PantallaValoracionInventario",
    component: PantallaValoracionInventario,
  },
  {
    path: "/inventario/pantalla_articulos_sin_movimiento",
    name: "PantallaArticulosSinMov",
    component: PantallaArticulosSinMov,
  },
  {
    path: "/inventario/reporte_articulos_sin_movimiento",
    name: "ReporteArticulosSinMov",
    component: ReporteArticulosSinMov,
  },
  {
    path: "/reportes_traspasos/pantalla_nivel_surtido_trasp",
    name: "PantallaNivelSurtidoTraspasos",
    component: PantallaNivelSurtidoTraspasos,
  },
  {
    path: "/reportes_traspasos/reporte_nivel_surtido_trasp",
    name: "ReporteNivelSurtidoTraspasos",
    component: ReporteNivelSurtidoTraspasos,
  },
  {
    path: "/reportes_traspasos/pantalla_traspasos_pendientes_por_recibir",
    name: "PantallaTraspasosSinRecibir",
    component: PantallaTraspasosSinRecibir,
  },
  {
    path: "/reportes_traspasos/reporte_traspasos_pendientes_por_recibir",
    name: "ReporteTraspasosSinRecibir",
    component: ReporteTraspasosSinRecibir,
  },
  {
    path: "/reportes_ventas/pantalla_seguimiento_clientes",
    name: "PantallaSegClientes",
    component: PantallaSegClientes,
  },
  {
    path: "/reportes_ventas/pantalla_info_clientes",
    name: "PantallaInfoCliente",
    component: PantallaInfoCliente,
  },
  {
    path: "/reportes_almacen/pantalla_valoracion_categoria",
    name: "PantallaValoracionCategoria",
    component: PantallaValoracionCategoria,
  },
  {
    path: "/reportes_almacen/reporte_valoracion_categoria",
    name: "ReporteValoracionCategoria",
    component: ReporteValoracionCategoria,
  },
  {
    path: "/reportes_ventas/pantalla_seg_pedido",
    name: "PantallaSegPedido",
    component: PantallaSegPedido,
  },
  {
    path: "/reportes_ventas/PantallaVentaBaterias",
    name: "PantallaVentaBaterias",
    component: PantallaVentaBaterias,
  },
  {
    path: "/reportes_ventas/ReporteVentaBaterias",
    name: "ReporteVentaBaterias",
    component: ReporteVentaBaterias,
  },
  {
    path: "/reportes_ventas/PantallaUniversoPedidos",
    name: "PantallaUniversoPedidos",
    component: PantallaUniversoPedidos,
  },
  {
    path: "/reportes_ventas/ReporteUniversoPedidos",
    name: "ReporteUniversoPedidos",
    component: ReporteUniversoPedidos,
  },
  {
    path: "/reportes_ventas/ReportePedidosEscaneados",
    name: "ReportePedidosEscaneados",
    component: ReportePedidosEscaneados,
  },
  {
    path: "/reportes_ventas/PantallaNegados",
    name: "PantallaNegados",
    component: PantallaNegados,
  },
  {
    path: "/reportes_ventas/ReporteNegados",
    name: "ReporteNegados",
    component: ReporteNegados,
  },
  {
    path: "/reporte_compras/pantalla_devolucion_proveedor",
    name: "PantallaDevolucionesProv",
    component: PantallaDevolucionesProv,
  },
  {
    path: "/compras/reporte_devoluciones_proveedor",
    name: "ReporteDevolucionesProv",
    component: ReporteDevolucionesProv,
  },
  {
    path: "/reporte_compras/pantalla_reimprimir_orden_compra",
    name: "PantallaReimprimirOrdenCompra",
    component: PantallaReimprimirOrdenCompra,
  },
  {
    path: "/compras/reporte_reimprimir_orden_compra",
    name: "ReporteReimprimirOrdenCompra",
    component: ReporteReimprimirOrdenCompra,
  },
  {
    path: "/reporte_compras/pantalla_facturas_escaneadas",
    name: "PantallaFacturasEscaneadas",
    component: PantallaFacturasEscaneadas,
  },
  {
    path: "/compras/reporte_facturas_escaneadas",
    name: "ReporteFacturasEscaneadas",
    component: ReporteFacturasEscaneadas,
  },
  {
    path: "/reportes_ventas/PantallaPedidosCancelados",
    name: "PantallaPedidosCancelados",
    component: PantallaPedidosCancelados,
  },
  {
    path: "/reportes_ventas/ReportePedidosCancelados",
    name: "ReportePedidosCancelados",
    component: ReportePedidosCancelados,
  },
  {
    path: "/reporte_compras/pantalla_reimpcontrarecibo",
    name: "PantallaReimpContraRecibo",
    component: PantallaReimpContraRecibo,
  },
  {
    path: "/compras/reporte_reimpcontrarecibo",
    name: "ReporteReimpContraRecibo",
    component: ReporteReimpContraRecibo,
  },
  {
    path: "/administracion/generador_tokens",
    name: "GeneradorTokens",
    component: PantallaGeneradorToken,
  },
  {
    path: "/politica_de_privacidad",
    name: "PoliticaPrivacidad",
    component: Politicas,
  },
  {
    path: "/administracion/pantalla_peticiones_lth",
    name: "PantallaPeticionesLTH",
    component: PantallaPeticionesLTH,
  },
  {
    path: "/administracion/pantalla_consulta_peticiones_lth",
    name: "PantallaConsultaPetiLTH",
    component: PantallaConsultaPetiLTH,
  },
  {
    path: "/administracion/pantalla_mantenimiento_tecnico_lth",
    name: "MantenimientoTecnicoLTH",
    component: PantallaMttoTecnicoLTH,
  },
  {
    path: "/administracion/monitor_peticiones_lth",
    name: "MonitorPeticionLTH",
    component: MonitorPetLTH,
  },
  {
    path: "/reportes_ventas/pantalla_nivel_surtido_ventas",
    name: "PantallaNivelSurtidoVentas",
    component: PantallaNivelSurtidoVentas,
  },
  {
    path: "/reportes_ventas/reporte_nivel_surtido_ventas",
    name: "ReporteNivelSurtidoVentas",
    component: ReporteNivelSurtidoVentas,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
