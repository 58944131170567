<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col>
        <h1 align="center">Reportes de venta por app CAT</h1>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      respuestaDeApi: [],
      headers: [
        {
          text: "Suc",
          value: "Suc",
        },
        {
          text: "Pedido",
          value: "Pedido",
        },
        {
          text: "Clave",
          value: "Clave",
        },
        {
          text: "Razonsocial",
          value: "Razonsocial",
        },
        {
          text: "Vend",
          value: "Vend",
        },
        {
          text: "Vendedor",
          value: "Vendedor",
        },
        {
          text: "Fecha Ped",
          value: "FechaPed",
        },
        {
          text: "Suc F",
          value: "SucF",
        },
        {
          text: "Fecha Fact",
          value: "FechaFact",
        },
        {
          text: "Factura",
          value: "Factura",
        },
        {
          text: "Sub Total",
          value: "Subtotal",
        },
        {
          text: "Total",
          value: "Total",
        },
      ],
    };
  },
  methods: {
    ...mapActions("reporteventascat", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;

      let data = [
        {
          sheet: "ReportesdeventaporAppCAT",
          columns: [
            { label: "Suc", value: "Suc" },
            { label: "Pedido", value: "Pedido" },
            { label: "Clave", value: "Clave" },
            { label: "Razonsocial", value: "Razonsocial" },
            { label: "Vend", value: "Vend" },
            { label: "Vendedor", value: "Vendedor" },
            { label: "Fecha Ped", value: "FechaPed" },
            { label: "Suc F", value: "SucF" },
            { label: "Fecha Fact", value: "FechaFact" },
            { label: "Factura", value: "Factura" },
            { label: "Sub Total", value: "Subtotal" },
            { label: "Total", value: "Total" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteVentaCAT", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    //console.log(arr)
    arr.resultado.forEach((element) => {
      element.FechaPed = moment(element.FechaPed).format("DD/MM/YYYY hh:mm a");
      element.FechaFact = moment(element.FechaFact).format(
        "DD/MM/YYYY hh:mm a"
      );
    });
    this.loadingt = false;
    this.respuestaDeApi = arr.resultado;
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
