<template lang="">
  <v-container>
    <v-card>
      <v-card-title>Kardex de Cascos</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">Sucursal:</v-col>
          <v-col>
            <sucursal-simple></sucursal-simple>
            <!--<v-autocomplete
              v-model="sucursalDeHoy"
              dense
              solo
              :items="sucursales"
              item-text="sucursal"
              item-value="sucursal"
            ></v-autocomplete>-->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">Articulo:</v-col>
          <v-col>
            <v-autocomplete
              v-model="article"
              dense
              solo
              :items="articulos"
              item-text="CASCO"
              item-value="idCasco"
              item-key="idCasco"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-card>
          <v-card-subtitle>
            <v-radio-group v-model="fechaGroup" row>
              <v-radio label="Todos" :value="1"></v-radio>
              <v-radio label="Seleccionado" :value="0"></v-radio>
            </v-radio-group>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="2">Fecha Inicial: </v-col>
              <v-col cols="10">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="truthSwitch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">Fecha Final: </v-col>
              <v-col cols="10">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="truthSwitch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn v-on:click="abrirReporteKardexCascos()">Reporte</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    article: "",
    articulos: [],
    fechaGroup: 1,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
  }),
  methods: {
    truthSwitch() {
      if (this.fechaGroup == 1) {
        return true;
      } else {
        return false;
      }
    },

    abrirReporteKardexCascos() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteKardexCascos",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      this.bodyForFetch = {
        todaSucursal: this.sucSelected == "TODOS" ? 1 : 0,
        sucursal: this.sucSelected,
        rangoFecha: this.fechaGroup,
        fechaIni: this.date.replaceAll("-", "") + "%2000:00",
        fechaFin: this.date1.replaceAll("-", "") + "%2023:59",
        articulo: this.article,
      };
      //console.log(this.bodyForFetch);
    },

    async enableList() {
      const response = await fetch(this.url + "/inventario/Articulos_Cascos", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
      });
      const value = await response.json();
      ////console.log(value);
      this.articulos = value.resultado;
      ////console.log(this.date);
    },
  },
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected", "url", "token"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.enableList();
  },
};
</script>
<style lang=""></style>
