<template lang="">
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Embarque Sin Folio</v-card-title>
          <v-card-subtitle>Rango de Fechas</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="3">Fecha Inicial: </v-col>
              <v-col cols="9">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">Fecha Final: </v-col>
              <v-col cols="9">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn block @click="abrirReporteSinEsc()">Sin Escanear</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn block @click="abrirReporteEsc()">Escaneados</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),

    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    abrirReporteSinEsc() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteEmbSinEsc",
      });
      window.open(routeData.href, "_blank");
    },
    abrirReporteEsc() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteEmbConEsc",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      this.bodyForFetch = {
        FechaIn: this.date.replaceAll("-", ""),
        FechaFin:
          this.date == this.date1
            ? this.addDay()
            : this.date1.replaceAll("-", ""),
      };
      //console.log(this.bodyForFetch);
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      //console.log(datef);
      datef = datef.replaceAll("-", "");

      return datef;
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
  },
};
</script>
<style lang=""></style>
