<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de consulta: {{ dateNow }} </v-row>
    <v-row> Sucursal: {{ sucText }} </v-row>
    <v-row> Niveles: {{ niveltext }} </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Jomar Industrias S.A. de C.V.</h1>
        <h2 align="center">Existencia por Categorias</h2>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      respuestaDeApi: [],
      headers: [
        {
          text: "ID",
          value: "ID",
        },
        {
          text: "Categoria",
          value: "CATEGORIA",
        },
        {
          text: "Existencia",
          value: "EXISTENCIA",
        },
        {
          text: "Monto",
          value: "MONTO",
        },
        {
          text: "SKU",
          value: "SKU",
        },
      ],
      niveltext: "",
      sucText: ""
    };
  },
  methods: {
    ...mapActions("reporteexistenciacategorias", ["tableFill", "getSucursales"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReporteExistenciaCategoria",
          columns: [
            { label: "ID", value: "ID" },
            { label: "Categoria", value: "CATEGORIA" },
            { label: "Existencia", value: "EXISTENCIA" },
            { label: "Monto", value: "MONTO" },
            { label: "SKU", value: "SKU" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteExistenciaCategoria", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    arr.obj.resultado.forEach((element) => {
      element.FechaIn = moment(element.fechaInicio).format(
        "DD/MM/YYYY hh:mm a"
      );
    });
    this.loadingt = false;
    this.respuestaDeApi = arr.obj.resultado;
    
    this.niveltext = arr.body.TodoNivel == 1 ? 'A,B,C,D,E,F' : arr.body.Nivel
    this.sucText = arr.body.sucursal == "" ? 'TODOS' : arr.body.sucursal
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
