<template lang="">
  <v-container>
    <v-card>
      <v-card-text>
        <v-card-title><h2>Informacion de Cliente</h2></v-card-title>
        <v-row>
          <v-col cols="1">Venta Neta:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Anteriores" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Facturado:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Anteriores" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Saldos:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Anteriores" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Notas Credito:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Anteriores" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Pagos:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Anteriores" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Vencido:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Ant6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Anteriores" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Por Vencer:</v-col>
          <v-col cols="1">
            <v-text-field label="Total" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig6" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Siguientes" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">Cheques Postfechados:</v-col>
          <v-col cols="1">
            <v-text-field label="Anterior" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual Ayer" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Actual Mañana" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig1" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig2" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig3" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig4" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Sig5" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">SALDO:</v-col>
          <v-col cols="1">
            <v-text-field label="Cobrable" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Incobrable" outlined solo dense readonly>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Depuracion" outlined solo dense readonly>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {};
</script>
<style></style>
