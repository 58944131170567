<template>
  <v-container>
    <v-row><h1>Peticiones LTH</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="guardarValid" lazy-validation>
              <v-row>
                <v-col sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        :items="users"
                        v-model="usuarioCC"
                        item-text="NOMBRE"
                        item-value="USUARIO_ID"
                        label="Usuarios CallCenter"
                        :rules="rules"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        :items="sucList"
                        dense
                        v-model="sucursal"
                        @change="summonTecnicosyRutas()"
                        label="Sucursal"
                        :rules="rules"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="nombre"
                        label="Nombre Cliente"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="direccion"
                        label="Dirección"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="tel"
                        label="Telefono"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        :rules="rules"
                        :items="estados"
                        item-text="Nombre"
                        item-value="Abrev2"
                        @change="traerMunicipios()"
                        v-model="estado"
                        label="Estado"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        :rules="rules"
                        v-model="municipio"
                        :items="municipios"
                        item-text="Descripcion"
                        item-value="IdMunicipio"
                        :disabled="muniSwitch"
                        label="Municipio"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="calle1"
                        label="Entre Calle 1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="calle2"
                        label="Entre Calle 2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row></v-row>
                </v-col>
                <v-col sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        v-model="idTec"
                        :items="tecnicos"
                        :disabled="sucursal == ''"
                        item-text="nombre"
                        item-value="idTecnico"
                        label="Tecnicos (Selec. Sucursal)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        v-model="idRuta"
                        :items="rutas"
                        :disabled="sucursal == ''"
                        item-text="Ruta"
                        item-value="idRuta"
                        label="Rutas (Selec. Sucursal)"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="latlng"
                        label="Latitud y Longitud"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="marca"
                        dense
                        :rules="rules"
                        :items="marcasCarro"
                        item-text="MakeName"
                        item-value="MakeID"
                        label="Marca Vehiculo"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="modelo"
                        label="Modelo"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="anyo"
                        type="number"
                        label="Año"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="color"
                        label="Color"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="codigo"
                        label="Codigo Bateria"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="precio"
                        label="Precio"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        v-model="pago"
                        :rules="rules"
                        :items="tiposCobro"
                        item-text="Nombre"
                        item-value="IdTipoCobro"
                        label="Metodo de Pago"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="servicio"
                        label="Servicio"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="ref"
                        label="Referencia"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :rules="rules"
                        v-model="prog"
                        label="Programacion"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="notas"
                    :rules="rules"
                    dense
                    label="Notas"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn block @click="submit()" :disabled="!guardarValid"
                >guardar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title> ¡Datos mandados!</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="seguro" max-width="400" persistent>
      <v-card>
        <v-card-title>Ingresa Clave Supervisor (Editar Precio)</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                hide-details
                v-model="usuario"
                label="Usuario"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                v-model="password"
                label="Contraseña"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="motivo"
                label="Motivo"
                auto-grow
                outlined
                rows="3"
                row-height="25"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ feedback }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :color="color" text @click="cierraDialogSeguro()">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="color" text @click="validacionUsuario()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    guardarValid: true,
    nombre: "",
    lat: "",
    lng: "",
    direccion: "",
    calle0: "",
    marca: "",
    modelo: "",
    num: "",
    estado: "",
    municipio: "",
    anyo: "",
    color: "",
    colonia: "",
    codigo: "",
    precio: "",
    calle1: "",
    calle2: "",
    pago: "",
    servicio: "",
    tel: "",
    ref: "",
    notas: "",
    prog: "",
    usuarioCC: "",
    sucursal: "",
    idTec: "",
    idRuta: "",
    estados: [],
    municipios: [],
    muniSwitch: true,
    tiposCobro: [],
    marcasCarro: [],
    tempFolSer: "",
    sucList: ["LEO", "LT3", "LT4"],
    tecnicos: [],
    users: [],
    rules: [(v) => !!v || "Campo requerido"],
    rutas: [],
    ogPrecio: 0,
    feedback: "",
    seguro: false,
    usuario: "",
    password: "",
    show: false,
    tempstatus: 0,
    motivo: "",
    latlng: "",
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    async validacionUsuario() {
      const response = await fetch(
        this.url + "/administracion/cambiar_precio_pedido_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            usuarioSup: this.usuario,
            password: this.password,
            idUserPantalla: this.userInfo.idUsuario,
            motivo: this.motivo,
            idPeti: this.bodyForFetch.idPeti,
            statusPeti: this.tempstatus,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.feedback = value[0].Mensaje;

        if (this.feedback == "CORRECTO") {
          this.modificar();
        }
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.guardarValid = false;
        if (Object.keys(this.bodyForFetch).length > 0) {
          if (this.precio != this.ogPrecio) {
            this.feedback = "";
            this.seguro = true;
          } else {
            this.modificar();
          }
        } else {
          let partes = this.latlng.split(",");
          this.lat = partes[0].trim();
          this.lng = partes[1].trim();
          const response = await fetch(
            this.url + "/administracion/guardar_peticiones_lth",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-access-token": this.token,
              },
              body: JSON.stringify({
                idUsuario: this.userInfo.idUsuario,
                sucursal: this.sucursal,
                idUserVenta: this.usuarioCC,
                idTecnico: this.idTec,
                nombreClte: this.nombre,
                calle0: this.calle0,
                numero: this.num,
                estado: this.estado,
                municipio: this.municipio,
                colonia: this.colonia,
                calle1: this.calle1,
                calle2: this.calle2,
                telefono: this.tel,
                referencia: this.ref,
                latitud: this.lat,
                longitud: this.lng,
                marca: this.marca,
                modelo: this.modelo,
                anyo: this.anyo,
                color: this.color,
                codigoBateria: this.codigo,
                precio: this.precio,
                metodoPago: this.pago,
                servicio: this.servicio,
                notas: this.notas,
                programa: this.prog,
                idRuta: this.idRuta,
                direccion: this.direccion,
              }),
            }
          );
          if (response.status == 200) {
            const value = await response.json();
            this.dialog = true;
            this.limpiar();
            this.$refs.form.reset();
          } else {
            const value = await response.json();
            let body = {
              status: response.status,
              mensaje: value.mensaje,
            };
            this.$store.dispatch("responseError", body);
          }
        }
      }
    },
    async modificar() {
      let partes = this.latlng.split(",");
      this.lat = partes[0].trim();
      this.lng = partes[1].trim();
      const response = await fetch(
        this.url + "/administracion/modificar_peticiones_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeti: this.bodyForFetch.idPeti,
            idUsuario: this.userInfo.idUsuario,
            sucursal: this.sucursal,
            idUserVenta: this.usuarioCC,
            idTecnico: this.idTec,
            nombreClte: this.nombre,
            calle0: this.calle0,
            numero: this.num,
            estado: this.estado,
            municipio: this.municipio,
            colonia: this.colonia,
            calle1: this.calle1,
            calle2: this.calle2,
            telefono: this.tel,
            referencia: this.ref,
            latitud: this.lat,
            longitud: this.lng,
            marca: this.marca,
            modelo: this.modelo,
            anyo: this.anyo,
            color: this.color,
            codigoBateria: this.codigo,
            precio: this.precio,
            metodoPago: this.pago,
            servicio: this.servicio,
            notas: this.notas,
            programa: this.prog,
            folioSer: this.tempFolSer,
            idRuta: this.idRuta,
            direccion: this.direccion,
          }),
        }
      );
      if (response.status == 200) {
        this.bodyForFetch = {};
        let routeData = this.$router.resolve({
          name: "MonitorPeticionLTH",
        });
        window.open(routeData.href, "_self");
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    limpiar() {
      this.nombre = "";
      this.lat = "";
      this.lng = "";
      this.latlng = "";
      this.calle0 = "";
      this.marca = "";
      this.modelo = "";
      this.num = "";
      this.estado = "";
      this.municipio = "";
      this.anyo = "";
      this.color = "";
      this.colonia = "";
      this.codigo = "";
      this.precio = "";
      this.calle1 = "";
      this.calle2 = "";
      this.pago = "";
      this.servicio = "";
      this.tel = "";
      this.ref = "";
      this.notas = "";
      this.idRuta = "";
      this.direccion = "";
    },
    async summonTecnicosyRutas() {
      this.summonTecnicos();
      this.summonRutas();
    },
    async summonRutas() {
      const response = await fetch(
        this.url + "/administracion/checar_rutas_sucursal",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            sucursal: this.sucursal,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.rutas = value;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async summonTecnicos() {
      const response = await fetch(
        this.url + "/administracion/lista_usuarios_suc_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            sucursal: this.sucursal,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.tecnicos = value;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async traerUsuariosCC() {
      const response = await fetch(
        this.url + "/administracion/traer_usuarios_callcenter",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        this.users = value.resultado;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async traerEstados() {
      const response = await fetch(this.url + "/administracion/lista_estados", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
      });
      const value = await response.json();
      if (response.status == 200) {
        this.estados = value.resultado;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async traerMunicipios() {
      const response = await fetch(
        this.url + "/administracion/lista_municipios",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            abrev: this.estado,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.municipios = value;
        this.muniSwitch = false;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async listadeCobros() {
      const response = await fetch(
        this.url + "/administracion/tipos_de_cobro",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        this.tiposCobro = value.resultado;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async marcasdeVehiculo() {
      const response = await fetch(
        this.url + "/administracion/marcas_vehiculos ",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        //console.log(value);
        this.marcasCarro = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async editarPedidos() {
      const response = await fetch(
        this.url + "/administracion/info_completa_pedido_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeti: this.bodyForFetch.idPeti,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.nombre = value[0].nombreCliente;
        this.lat = value[0].destinoLat;
        this.lng = value[0].destinoLng;
        this.latlng = this.lat + ", " + this.lng;
        this.calle0 = value[0].calle;
        this.marca = value[0].marca;
        this.modelo = value[0].modelo;
        this.num = value[0].numero;
        this.estado = value[0].estado;
        this.anyo = value[0].año;
        this.color = value[0].color;
        this.colonia = value[0].colonia;
        this.codigo = value[0].codigoBateria;
        this.precio = value[0].precio;
        this.ogPrecio = value[0].precio;
        this.calle1 = value[0].entreCalles1;
        this.calle2 = value[0].entreCalles2;
        this.pago = value[0].metodoPago;
        this.servicio = value[0].servicio;
        this.tel = value[0].telefono;
        this.ref = value[0].referencia;
        this.notas = value[0].notas;
        this.prog = value[0].Programacion;
        this.usuarioCC = value[0].idUsuarioVenta;
        this.sucursal = value[0].sucursal;
        this.tempFolSer = value[0].folioServicio;
        this.tempstatus = value[0].statusPeticion;
        await this.summonTecnicosyRutas();
        await this.traerMunicipios();
        this.municipio = value[0].municipio;
        this.idTec = value[0].idTecnico;
        this.idRuta = value[0].idRuta;
        this.direccion = value[0].direccion;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    cierraDialogSeguro() {
      this.feedback = "";
      this.seguro = false;
    },
  },
  async mounted() {
    this.traerEstados();
    this.listadeCobros();
    this.marcasdeVehiculo();
    this.traerUsuariosCC();
    if (Object.keys(this.bodyForFetch).length > 0) {
      this.editarPedidos();
    }
  },
  beforeDestroy() {
    this.bodyForFetch = {};
  },
};
</script>
<style></style>
