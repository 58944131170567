<template lang="">
  <v-container>
    <v-card>
      <v-card-title> Llamadas Clientes </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Sucursal</v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="radioSelected" column>
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Seleccionado" :value="0"></v-radio>
                </v-radio-group>
                <!--<v-combobox
                  v-model="sucSelect"
                  :items="sucursales"
                  item-text="sucursal"
                  item-value="sucursal"
                  :disabled="sucursal"
                ></v-combobox>-->
                <sucursal-simple></sucursal-simple>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-subtitle>Vendedor</v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="vendedor" column>
                  <v-radio label="Todos" :value="true"></v-radio>
                  <v-radio label="Seleccionado" :value="false"></v-radio>
                </v-radio-group>
                <v-combobox
                  v-model="venselect"
                  :items="vendedores"
                  item-text="Nvendedor"
                  item-value="Vendedor"
                  multiple
                  :disabled="vendedor"
                ></v-combobox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Fechas</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="2">Fecha Inicial: </v-col>
                  <v-col cols="10">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Picker in menu"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">Fecha Final: </v-col>
                  <v-col cols="10">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="date1"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date1"
                          label="Picker in menu"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date1" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu3.save(date1)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn v-on:click="abrirResumenLlamadas()">Resumen</v-btn>
          <v-btn v-on:click="abrirReporteLlamadas()">Reporte</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    //sucursal: "",
    vendedor: "",
    //sucSelect: "",
    venselect: [],
    vendedores: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
  },
  watch: {
    // whenever question changes, this function will run
    sucSelected(sucurs) {
      ////console.log(sucurs);
      this.enableList();
    },
    // venselect(val){
    //     //console.log(val);
    // }
  },
  async mounted() {
    // //console.log(this.sucursales);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
  },
  methods: {
    vendedoresStringing() {
      var sendvend = "";
      for (var counter = 0; counter < this.venselect.length; counter++) {
        sendvend = sendvend + this.venselect[counter].Vendedor + ",";
      }
      return sendvend;
    },
    abrirResumenLlamadas() {
      this.bodyForFetch = {
        fechaIn: this.date.replaceAll("-", ""),
        fechaFn: this.date1.replaceAll("-", ""),
        sucursal: this.sucSelected,
        todoSuc: this.radioSelected ? 1 : 0,
        vendedores: this.vendedoresStringing(),
        todoVend: this.vendedor ? 1 : 0,
      };
      ////console.log(this.bodyForFetch);
      let routeData = this.$router.resolve({
        name: "ResumenLlamadasClientes",
      });
      window.open(routeData.href, "_blank");
    },
    abrirReporteLlamadas() {
      this.bodyForFetch = {
        fechaIn: this.date.replaceAll("-", ""),
        fechaFn: this.date1.replaceAll("-", ""),
        sucursal: this.sucSelected,
        todoSuc: this.radioSelected ? 1 : 0,
        vendedores: this.vendedoresStringing(),
        todoVend: this.vendedor ? 1 : 0,
      };
      ////console.log(this.bodyForFetch);
      let routeData = this.$router.resolve({
        name: "ReporteLlamadasClientes",
      });
      window.open(routeData.href, "_blank");
    },
    async enableList() {
      const response = await fetch(
        this.url + "/ventas/lista_vendedores?strSucursal=" + this.sucSelected,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      ////console.log(value);
      this.vendedores = value.resultado;
      ////console.log(this.date);
    },
  },
};
</script>
<style lang=""></style>
