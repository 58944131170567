<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de impresion: {{ dateNow }} </v-row>
    <v-row>
      <v-col cols="12" style="font-size: 17px">
        <v-row>Desde: {{ fechaI }} </v-row>
        <v-row>Hasta: {{ fechaF }} </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Reportes Promociones</h1>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      fechaI: "",
      fechaF: "",
      respuestaDeApi: [],
      // headers: [
      //   {
      //     text: "Zona",
      //     value: "zona",
      //   },
      //   {
      //     text: "Sucursal",
      //     value: "sucursal",
      //   },
      //   {
      //     text: "Promociones",
      //     value: "Promociones",
      //   },
      //   {
      //     text: "Facturas",
      //     value: "facturas",
      //   },
      // ],
    };
  },
  computed: {
    ...mapState("reportepromociones", ["headers"]),
    ...mapState(["bodyForFetch"]),
  },
  methods: {
    ...mapActions("reportepromociones", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let columnexp = [];
      if (this.bodyForFetch.tipo == "1") {
        columnexp = [
          { label: "Zona", value: "zona" },
          { label: "Sucursal", value: "sucursal" },
          { label: "Promociones", value: "Promociones" },
          { label: "Facturas", value: "facturas" },
          { label: "Fecha Fin", value: "FechaF" },
          { label: "Fecha Inicio", value: "FechaI" },
          { label: "Orden", value: "orden" },
        ];
      } else {
        columnexp = [
          { label: "Zona", value: "zona" },
          { label: "Sucursal", value: "sucursal" },
          { label: "Promociones", value: "Promociones" },
          { label: "Fecha Fin", value: "FechaF" },
          { label: "Fecha Inicio", value: "FechaI" },
          { label: "Orden", value: "orden" },
          { label: "Vendedor", value: "vendedor" },
        ];
      }
      let data = [
        {
          sheet: "ReportePromociones",
          columns: columnexp,
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReportePromociones", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    arr.resultado.forEach((element) => {
      element.FechaI = moment(element.fechaInicio).format("DD/MM/YYYY hh:mm a");
      element.FechaF = moment(element.fechaFin).format("DD/MM/YYYY hh:mm a");
    });
    this.fechaI = arr.resultado[0].FechaI;
    this.fechaF = arr.resultado[0].FechaF;
    this.loadingt = false;
    this.respuestaDeApi = arr.resultado;
    ////console.log(this.respuestaDeApi);
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
