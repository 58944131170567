<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card color="#EEF2F2">
            <v-card-text>
              <v-form>
                <v-card-title>
                  <h2>Existencia por categoria</h2>
                </v-card-title>
                <v-card-actions>
                  <v-radio-group v-model="radioSelected" row>
                    <v-radio label="Todos" :value="1" dense></v-radio>
                    <v-radio
                      label="Sucursal seleccionada"
                      :value="0"
                      dense
                    ></v-radio>
                  </v-radio-group>
                </v-card-actions>
                <sucursal-simple></sucursal-simple>
                <v-card-actions>
                  <v-radio-group v-model="TodoNivel" row>
                    <v-radio label="Todos" :value="1" dense></v-radio>
                    <v-radio
                      label="Nivel seleccionado"
                      :value="0"
                      dense
                    ></v-radio>
                  </v-radio-group>
                </v-card-actions>
                <v-autocomplete
                  v-model="Nivel"
                  :items="Niveles"
                  item-text="Nivel"
                  item-value="Nivel"
                  :rules="[(v) => !!v || 'Seleccione Nivel']"
                  solo
                  label="Niveles"
                  required
                  multiple
                  dense
                  :disabled="TodoNivel == 1"
                >
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="abrirReporteExistencia()"
                ><v-icon dense>mdi-printer</v-icon>Imprimir</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaExistenciaCategoria",
  components: {
    sucursalSimple,
  },
  data: () => ({
    TodoNivel: 1,
    Niveles: ["A", "B", "C", "D", "E", "F"],
    Nivel: "",
  }),
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
  },
  methods: {
    abrirReporteExistencia() {
      this.bodyForFetch = {
        sucursal: this.radioSelected == 1 ? "" : this.sucSelected,
        Nivel: this.agarraNivel(),
        TodoNivel: this.TodoNivel,
      };
      let routeData = this.$router.resolve({
        name: "ReporteExistenciaCategoria",
      });
      window.open(routeData.href, "_blank");
    },
    agarraNivel() {
      var solucion = "";
      for (let i = 0; i < this.Nivel.length; i++) {
        solucion = solucion + this.Nivel[i] + ",";
      }
      return solucion;
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 1);
  },
};
</script>
<style></style>
