<template lang="">
  <v-container>
    <v-row><h1>Reporte de Variación de Costo de Inventario</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-subtitle>Sucursal</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group
                      dense
                      :disabled="sucursalIsolate()"
                      v-model="radioSelected"
                      column
                    >
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <sucursal-simple></sucursal-simple>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-subtitle>Rango de Fechas</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="5">Fecha Inicial: </v-col>
                      <v-col cols="7">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">Fecha Final: </v-col>
                      <v-col cols="7">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          :return-value.sync="date1"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date1"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date1" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu3 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu3.save(date1)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn @click="abrirReporte()">Reporte</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    d0dia: 0,
    d0mes: 0,
    d0anyo: 0,
    d1dia: 0,
    d1mes: 0,
    d1anyo: 0,
    date1SEND: 0,
    date2SEND: 0,
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    sucursalIsolate() {
      if (this.userInfo.ESGLOBAL == true) {
        return false;
      } else {
        this.radioSelected = 0;
        this.sucSelected = this.userInfo.sucursal;
        return true;
      }
    },
    fechawork() {
      this.date1SEND = this.date.replaceAll("-", "");
      if (this.date == this.date1) {
        this.date2SEND = this.addDay();
      } else {
        this.date2SEND = this.date1.replaceAll("-", "");
      }
      this.d0dia = this.date1SEND[6] + this.date1SEND[7];
      this.d0mes = this.date1SEND[4] + this.date1SEND[5];
      this.d0anyo =
        this.date1SEND[0] + this.date1SEND[1] + this.date[2] + this.date[3];
      this.d1dia = this.date2SEND[6] + this.date2SEND[7];
      this.d1mes = this.date2SEND[4] + this.date2SEND[5];
      this.d1anyo =
        this.date2SEND[0] +
        this.date2SEND[1] +
        this.date2SEND[2] +
        this.date2SEND[3];
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      this.date1 = datef;
      datef = datef.replaceAll("-", "");
      //console.log(datef);
      return datef;
    },
    abrirReporte() {
      this.fechawork();
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        TodaSucursal: this.radioSelected,
        dtFechaInicio: this.date1SEND,
        dtFechaFin: this.date2SEND,
        stDia: this.d0dia,
        stMes: this.d0mes,
        stAnyo: this.d0anyo,
        stDia2: this.d1dia,
        stMes2: this.d1mes,
        stAnyo2: this.d1anyo,
      };
      let routeData = this.$router.resolve({
        name: "ReporteVariacionCostoInventario",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
  },
};
</script>
<style lang=""></style>
