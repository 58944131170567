<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-row justify="center">
      <h1>Reporte de Embarques sin Folio {{ "( con escanear )" }}</h1>
    </v-row>
    <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="embarques"
          class="row-height-50 elevation-1"
          :items-per-page="embarques.length"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    headers: [
      { text: "No Traspaso", value: "NoTraspaso" },
      { text: "No Embarque", value: "NoEmbarque" },
      { text: "Suc Origen", value: "SucOrigen" },
      { text: "Suc Destino", value: "SucDestino" },
      { text: "No Tarima", value: "NoTarima" },
      { text: "FechaHora", value: "FechaHora" },
      { text: "nombre", value: "nombre" },
    ],
    embarques: [],
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  async mounted() {
    const response = await fetch(this.url + "/traspasos/tabla_con_escanear", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": this.token,
      },
      body: JSON.stringify(this.bodyForFetch),
    });
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      var arr = value;
      arr.forEach((element) => {
        element.FechaHora = moment(element.FechaHora).format(
          "DD/MM/YYYY HH:mm:ss a"
        );
      });
      this.embarques = arr;
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
  methods: {
    date1Format() {
      var fecha1 = "";
      fecha1 = moment(this.bodyForFetch.FechaIn).format("YYYY/MM/DD");
      return fecha1;
    },
    date2Format() {
      var fecha2 = "";
      fecha2 = moment(this.bodyForFetch.FechaFin).format("YYYY/MM/DD");
      return fecha2;
    },

    downloadexcel() {
      let arr = this.embarques;
      let data = [
        {
          sheet: "Ordenes de Embarques (Con Escanear)",
          columns: [
            { label: "No Traspaso", value: "NoTraspaso" },
            { label: "No Embarque", value: "NoEmbarque" },
            { label: "Suc Origen", value: "SucOrigen" },
            { label: "Suc Destino", value: "SucDestino" },
            { label: "No Tarima", value: "NoTarima" },
            { label: "FechaHora", value: "Fecha" },
            { label: "nombre", value: "nombre" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "EmbarquesConEscanear", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
