<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"><h1>Reporte de Auditoria de Conteo</h1></v-row>
    <v-row>
      <v-col>
        <v-row
          >De {{ bodyForFetch.strFechaHoraIn }} a
          {{ bodyForFetch.strFechaHoraFin }}</v-row
        >
      </v-col>
      <v-col>
        <v-row justify="end">Sucursal: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="auditorias"
          class="row-height-50 elevation-1"
          :items-per-page="auditorias.length"
          hide-default-footer
        >
          <!--AQUI ESTA COMO FORMATEAR Y ANADIR COLUMNAS A DATA TABLE-->
          <template v-slot:item.tablaSkusScan="{ item }">
            {{ skuscantecla(item.SkusScan, item.Tecleados) }}
          </template>
          <template v-slot:item.tablaPctjeAudit="{ item }">
            {{ skuscanprogram(item.SkusScan, item.SkusProgram) }}
          </template>
          <template v-slot:item.tablaPctjeConf="{ item }">
            {{ skusdifscan(item.SkusDif, item.SkusScan) }}
          </template>
          <template slot="body.append">
            <tr>
              <th>{{ auditorias.length }}</th>
              <th></th>
              <th>{{ skusSum }}</th>
              <th>{{ skusProgSum }}</th>
              <th>{{ skusScanSum }}</th>
              <th>{{ sumaSkusScan() }}</th>
              <th>{{ tecleadosSum }}</th>
              <th>{{ sumaPctjeAudit() }}</th>
              <th>{{ skusDifSum }}</th>
              <th>{{ sumaPctjeConf() }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    headers: [
      { text: "idCat", value: "IdCategoria" },
      { text: "Categoria", value: "Categoria" },
      { text: "Skus", value: "Skus" },
      { text: "Skus Prog", value: "SkusProgram" },
      { text: "Skus Audit", value: "SkusScan" },
      { text: "Skus Scan", value: "tablaSkusScan" },
      { text: "Skus Manual", value: "Tecleados" },
      { text: "Pctje Audit", value: "tablaPctjeAudit" },
      { text: "Skus Dif", value: "SkusDif" },
      { text: "Pctje Conf", value: "tablaPctjeConf" },
      { text: "Nombre", value: "Nombre" },
    ],
    auditorias: [],
    skusSum: 0,
    skusProgSum: 0,
    skusScanSum: 0,
    tecleadosSum: 0,
    skusDifSum: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  async mounted() {
    const response = await fetch(
      this.url + "/inventario/trae_reporte_conteo_audit",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      }
    );
    if (response.status == 200) {
      const value = await response.json();
      for (var i = 0; i < value.length; i++) {
        this.skusSum += value[i].Skus;
        this.skusProgSum += value[i].SkusProgram;
        this.skusScanSum += value[i].SkusScan;
        this.tecleadosSum += value[i].Tecleados;
        this.skusDifSum += value[i].SkusDif;
      }
      this.auditorias = value;
      //console.log(this.auditorias);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
  methods: {
    skuscantecla(scan, tecla) {
      var resultado = 0;
      resultado = scan - tecla;
      //this.sumaSkusScan += resultado;
      return resultado.toFixed(0);
    },
    skuscanprogram(scan, program) {
      var resultado = 0;
      if (program == 0) {
        resultado = 0;
      } else {
        resultado = (scan / program) * 100;
      }
      //this.sumaPctjeAudit += resultado;
      return resultado.toFixed(0);
    },
    skusdifscan(dif, scan) {
      var resultado = 0;
      if (scan == 0) {
        resultado = 100;
      } else {
        resultado = 100 - (dif / scan) * 100;
      }
      //this.sumaPctjeConf += resultado;
      return resultado.toFixed(0);
    },
    sumaSkusScan() {
      var resultado = 0;
      for (var i = 0; i < this.auditorias.length; i++) {
        resultado += this.auditorias[i].SkusScan - this.auditorias[i].Tecleados;
      }
      return parseFloat(resultado.toFixed(2));
    },
    sumaPctjeAudit() {
      var resScan = 0;
      var resProgram = 0;
      var resultado = 0;
      for (var i = 0; i < this.auditorias.length; i++) {
        resScan += this.auditorias[i].SkusScan;
        resProgram += this.auditorias[i].SkusProgram;
      }
      if (resProgram != 0) {
        resultado = (resScan / resProgram) * 100;
      }
      return parseFloat(resultado.toFixed(2));
    },
    sumaPctjeConf() {
      var resDif = 0;
      var resScan = 0;
      var resultado = 0;
      for (var i = 0; i < this.auditorias.length; i++) {
        resScan += this.auditorias[i].SkusScan;
        resDif += this.auditorias[i].SkusDif;
      }
      if (resScan != 0) {
        resultado = 100 - (resDif / resScan) * 100;
      }
      return parseFloat(resultado.toFixed(2));
    },
    downloadexcel() {
      let arr = this.auditorias;
      let data = [
        {
          sheet: "Auditoria de Conteo",
          columns: [
            { label: "idCat", value: "IdCategoria" },
            { label: "Categoria", value: "Categoria" },
            { label: "Skus", value: "Skus" },
            { label: "Skus Prog", value: "SkusProgram" },
            { label: "Skus Audit", value: "SkusScan" },
            { label: "Skus Scan", value: "tablaSkusScan" },
            { label: "Skus Manual", value: "Tecleados" },
            { label: "Pctje Audit", value: "tablaPctjeAudit" },
            { label: "Skus Dif", value: "SkusDif" },
            { label: "Pctje Conf", value: "tablaPctjeConf" },
            { label: "Nombre", value: "Nombre" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "AuditoriaConteo", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
