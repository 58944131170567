<template lang="">
  <v-container>
    <v-row><h1>Auditoria de Conteo</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-subtitle> Filtros Iniciales </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                <sucursal-simple></sucursal-simple>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">Fecha Inicial: </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu2"
                  v-model="timeIn"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time1"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time1"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeIn"
                    v-model="time1"
                    full-width
                    no-title
                    @click:minute="$refs.menu2.save(time1)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">Fecha Inicial: </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu4"
                  v-model="timeOut"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time2"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeOut"
                    v-model="time2"
                    full-width
                    no-title
                    @click:minute="$refs.menu4.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn @click="traerUsuarios()">Traer Usuarios</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-subtitle> Ultimo Filtro </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-row><v-col> Usuarios</v-col></v-row>
                <br />
                <v-row>
                  <v-col>
                    <v-radio-group
                      dense
                      v-model="repartSelected"
                      :disabled="radioAble"
                      column
                    >
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8">
                <template>
                  <v-card class="mx-auto" min-width="95%" tile>
                    <v-list :disabled="repartSelected == 1" dense>
                      <v-list-item-group
                        multiple
                        v-model="selectedItems"
                        color="primary"
                      >
                        <v-list-item v-for="(item, i) in usuariosList" :key="i">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.IdUsuario + '-' + item.NOMBRE"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="abrirReporteAuditoria()">Reporte</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    time1: "00:00",
    timeIn: false,
    menu3: false,
    menu4: false,
    time2: "23:59",
    timeOut: false,
    repartSelected: 1,
    selectedItems: [],
    usuariosList: [],
    fechaHoraIni: "",
    fechaHoraFin: "",
    radioAble: true,
  }),
  computed: {
    ...mapState(["sucSelected", "url", "userInfo", "token"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    fuseTime() {
      this.fechaHoraIni =
        this.date.replaceAll("-", "") + " " + this.time1 + ":00";
      this.fechaHoraFin =
        this.date1.replaceAll("-", "") + " " + this.time2 + ":00";
    },
    async traerUsuarios() {
      this.fuseTime();
      const response = await fetch(
        this.url + "/inventario/trae_id_nombre_usuario",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            strSucursal: this.sucSelected,
            strFechaHoraIn: this.fechaHoraIni,
            strFechaHoraFin: this.fechaHoraFin,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.usuariosList = value;
        //console.log(this.usuariosList);
        this.radioAble = false;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    abrirReporteAuditoria() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteAuditoriaConteo",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      var sendlist = "";
      for (var i = 0; i < this.selectedItems.length; i++) {
        sendlist += this.usuariosList[this.selectedItems[i]].IdUsuario + ", ";
      }
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        intTodosUsuarios: this.repartSelected,
        strUsuarios: sendlist,
        strFechaHoraIn: this.fechaHoraIni,
        strFechaHoraFin: this.fechaHoraFin,
      };
      //console.log(this.bodyForFetch);
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.fuseTime();
  },
};
</script>
<style scoped>
.v-list {
  height: 250px; /* or any height you want */
  overflow-y: auto;
}
</style>
