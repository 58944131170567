<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de impresion: {{ dateNow }} </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Reportes Peticiones de Repartidores por Sucursal</h1>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",
  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      fechaIn: "",
      fechaFn: "",
      respuestaDeApi: [],
      headers: [
        {
          text: "Sucursal",
          value: "sucursal",
        },
        {
          text: "Total de Peticiones",
          value: "peticionesT",
        },
        {
          text: "Peticiones Pendientes",
          value: "petPend",
        },
        {
          text: "Peticiones Finalizadas",
          value: "petFin",
        },
      ],
    };
  },
  computed: {
    ...mapState(["bodyForFetch"]),
  },
  methods: {
    ...mapActions("reportepetrepxsuc", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReportePeticionesRepXSuc",
          columns: [
            { label: "Sucursal", value: "sucursal" },
            { label: "Total de Peticiones", value: "peticionesT" },
            { label: "Peticiones Pendientes", value: "petPend" },
            { label: "Peticiones Finalizadas", value: "petFin" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReportePeticionesRepXSuc", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    //console.log(arr);
    //console.log(this.bodyForFetch);
    if (arr.length > 0) {
      this.loadingt = false;
      this.respuestaDeApi = arr;
    } else {
      this.loadingt = false;
      this.respuestaDeApi = [];
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
