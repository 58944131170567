<template lang="">
  <div>
    <v-container>
      <v-card>
        <v-card-title> Reimpresión de Pedido Mayoreo </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">Folio Pedido: </v-col>
            <v-col>
              <v-text-field v-model="folio"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Sucursal: </v-col>
            <v-col>
              <!--<v-autocomplete
                v-model="sucursal"
                dense
                solo
                :items="sucursales"
                item-text="sucursal"
                item-value="sucursal"
              ></v-autocomplete>-->
              <sucursal-simple></sucursal-simple>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col align="end">
            <v-btn @click="abrirReimpresionPedidoMayoreo()">Imprimir</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    //sucursal: "",
    folio: "",
  }),
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    abrirReimpresionPedidoMayoreo() {
      this.bodyForFetch = { folio: this.folio, sucursal: this.sucSelected };
      let routeData = this.$router.resolve({
        name: "ReporteReimpresionPedidoMayoreo",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    //this.$store.dispatch("getSucursales");
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.$store.dispatch("getTipoMov");
    this.$store.dispatch("getArea");
  },
};
</script>
<style lang=""></style>
