export default {
  namespaced: true,
  state: { headers: [] },
  actions: {
    tableFill: async ({ state, commit, dispatch, rootState }, payload) => {
      let body = rootState.bodyForFetch;
      ////console.log(body);
      try {
        const response = await fetch(
          rootState.url +
            `/ventas/rpt_promociones?fechaI=${body.FechaI}&fechaF=${body.FechaF}&tipo=${body.tipo}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": rootState.token,
            },
          }
        );
        ////console.log(response);
        const obj = await response.json();
        ////console.log(obj);
        return obj;
      } catch (error) {
        //console.log(error);
      }
    },
  },
  mutations: {
    setheaders(state, payload) {
      if (payload == "1") {
        state.headers = [
          {
            text: "Zona",
            value: "zona",
          },
          {
            text: "Sucursal",
            value: "sucursal",
          },
          {
            text: "Promociones",
            value: "Promociones",
          },
          {
            text: "Facturas",
            value: "facturas",
          },
        ];
      } else {
        state.headers = [
          {
            text: "Zona",
            value: "zona",
          },
          {
            text: "Sucursal",
            value: "sucursal",
          },
          {
            text: "Vendedor",
            value: "vendedor",
          },
          {
            text: "Promociones",
            value: "Promociones",
          },
        ];
      }
    },
  },
};
