<template>
  <v-col cols="12" xl="3" lg="3" md="4">
    <v-card>
      <v-card-title>{{ repaUser }}</v-card-title>
      <v-divider></v-divider>
      <v-virtual-scroll :items="list" :item-height="80" height="300">
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >{{ item.folioPeticion }} -
                {{ item.TipoPeticion }}</v-list-item-title
              >
              <v-list-item-subtitle class="text--primary"
                >folio: {{ item.folio }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                >Destino: {{ item.destino }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-col>
</template>
<script>
export default {
  props: {
    repaUser: String,
    list: {
      type: Array,
      required: false,
    },
  },
};
</script>
<style></style>
