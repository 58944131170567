import router from "@/router";

export default {
  responseError: async ({ state, commit }, payload) => {
    if (payload.status == 401) {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
      commit("ExitApp");
    } else if (payload.status == 403) {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
      commit("ExitApp");
    } else {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
    }
  },
  getSucursales: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.url + "/users/sucursales", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.token,
      },
    });
    const value = await response.json();
    if (response.status == 200) {
      var data = [];
      if (payload) {
        data.push({ sucursal: "TODOS" });
      }
      value.info.forEach((element) => {
        data.push(element);
      });
      commit("setSucursales", data);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  getCascos: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.url + "/traspasos/lista_cascos", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.token,
      },
    });
    const value = await response.json();
    if (response.status == 200) {
      ////console.log(value);
      commit("setCascos", value.resultado);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  getTipoMov: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.url + "/users/TipoMov", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.token,
      },
    });
    const value = await response.json();
    ////console.log(value, "console");
    if (response.status == 200) {
      var data = [];
      data.push({ TipoMov: "0", TipoMovAlmacen: "TODOS" });
      value.Respuesta.forEach((element) => {
        data.push(element);
      });
      commit("setTipoMov", data);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  getArea: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.url + "/users/Area", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.token,
      },
    });
    const value = await response.json();
    if (response.status == 200) {
      var data = [];
      data.push({ Area: "TODOS", AreaId: "" });
      value.Respuesta.forEach((element) => {
        data.push(element);
      });
      commit("setArea", data);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
};
