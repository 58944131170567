<template>
  <v-container>
    <h1>Solicitud de Repartidor</h1>
    <br />
    <v-row>
      <v-col xl="6" lg="6" md="6" cols="12">
        <v-autocomplete
          v-model="modo"
          :items="opciones"
          label="SELECCIONAR"
        ></v-autocomplete>
        <v-container id="map" style="height: 70vh"></v-container>
        <br />
      </v-col>
      <v-col>
        <v-container>
          <div v-if="modo == 'Varios'">
            <v-text-field v-model="direcVarios" label="Destino"></v-text-field>
            <v-textarea v-model="descVarios" label="Descripción"></v-textarea>
          </div>
          <div v-if="modo == 'Deposito'">
            <v-autocomplete
              v-model="banco"
              :items="bancos"
              item-text="nombre"
              return-object
              label="Sucursal o Banco"
              @change="ponerBancoCoordenadas()"
            >
            </v-autocomplete>
            <br />
            <v-text-field v-model="cantidad" prefix="$" label="Monto">
            </v-text-field>
          </div>
          <div v-if="modo == 'Compra'">
            <v-row>
              <v-col
                ><v-text-field v-model="folio" label="Folio de Compra">
                </v-text-field
              ></v-col>
              <v-col
                ><v-btn dark color="#003479" @click="llamarCompra()"
                  >Buscar</v-btn
                ></v-col
              >
            </v-row>
            <v-autocomplete
              v-model="compradorAuto"
              :items="compradores"
              item-text="Nombre"
              item-value="Comprador"
              disabled
            ></v-autocomplete>
            <v-autocomplete
              v-model="provedorAuto"
              :items="provedores"
              item-text="Nombre"
              item-value="Clave"
              disabled
            ></v-autocomplete>
            <v-textarea v-model="descCompra" label="Descripción"></v-textarea>
            <v-card>
              <v-card-text>
                <v-data-table
                  :headers="headCompra"
                  :items="compras"
                  :items-per-page="10"
                  mobile-breakpoint="0"
                ></v-data-table>
              </v-card-text>
            </v-card>
          </div>
          <div v-if="modo == 'Traspaso'">
            <v-row>
            <v-col cols="12">
            <h4>*NOTA*</br>Ahora se tiene que poner el numero del pedido ya no el de traspaso, y al repartidor cuando vaya a recoger el pedido se le pedira el numero de traspaso para poder finalizar el viaje.</h4>
            </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="traspaso"
                  label="Numero de Pedido"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3"
                ><v-btn dark color="#003479" @click="llamarTraspaso()"
                  >Buscar</v-btn
                ></v-col
              >
            </v-row>
            <v-row v-if="traspasosDatos.length != 0">
              <v-col cols="12" md="6" lg="6" xl="6">
                Sucursal Origen: {{ traspasosDatos[0].CveSucursalOrigen }}
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                Sucursal Destino: {{ traspasosDatos[0].CveSucursalDestino }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-data-table
                      :headers="headTras"
                      :items="traspasosDatos"
                      :items-per-page="10"
                      mobile-breakpoint="0"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-btn color="#003479" dark @click="mandarDatos()" block> Enviar </v-btn>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title> ¡Datos mandados!</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { mapState } from "vuex";

export default {
  name: "PeticionesRepartidores",
  data: () => ({
    dialog: false,
    descCompra: "",
    direcVarios: "",
    descVarios: "",
    opciones: ["SELECCIONAR", "Varios", "Deposito", "Compra", "Traspaso"],
    modo: "",
    banco: {},
    bancos: [],
    cantidad: 0,
    folio: "",
    compradorAuto: "",
    compradores: [],
    provedorAuto: "",
    provedores: [],
    headCompra: [
      { text: "Producto", value: "ItemNo" },
      { text: "Cant.", value: "CantPedida" },
    ],
    compras: [],
    traspaso: "",
    headTras: [
      { text: "Producto", value: "Articulo" },
      { text: "Cant.", value: "Cantidad" },
    ],
    traspasosDatos: [],
    TokenMap:
      "pk.eyJ1Ijoiam9tYXJpbmR1c3RyaWFzIiwiYSI6ImNsNTlzY3llYTJmNWEza3FxcHJyMDZ4bjkifQ.GcFBgAtC6I4vN5eSH49LeA",
    lat: 0,
    lng: 0,
    map: null,
    marker: null,
    // Geocoder: null,
  }),

  computed: {
    ...mapState(["url", "userInfo", "token"]),
  },

  async mounted() {
    mapboxgl.accessToken = this.TokenMap;
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.userInfo.Longitud, this.userInfo.Latitud],
      zoom: 16,
      projection: "globe",
    });

    this.map.on("click", (e) => {
      //console.log(`A click event has occurred at ${e.lngLat}`);
      if (this.marker) {
        this.marker.remove();
      }
      this.marker = new mapboxgl.Marker().setLngLat(e.lngLat).addTo(this.map);
    });
    this.enableBancos();
  },
  methods: {
    async mandarDatos() {
      this.traerCoordenadas();
      if (this.modo == "Varios") {
        const response = await fetch(
          this.url + "/administracion/crear_pedido_reparticion",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              folio: "",
              destinoLat: this.lat,
              destinoLng: this.lng,
              destino: this.direcVarios,
              sucursal: this.userInfo.sucursal,
              idTipoPeticion: "V",
              monto: "",
              cambio: "",
              descripcion: this.descVarios,
              claveComprador: "",
              claveProveedor: "",
            }),
          }
        );
        if (response.status == 200) {
          this.descVarios = "";
          this.direcVarios = "";
          this.marker.remove();
          this.dialog = true;
        } else {
          const value = await response.json();
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } else if (this.modo == "Deposito") {
        const response = await fetch(
          this.url + "/administracion/crear_pedido_reparticion",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              folio: "",
              destinoLat: this.lat,
              destinoLng: this.lng,
              destino: this.banco.nombre,
              sucursal: this.userInfo.sucursal,
              idTipoPeticion: "D",
              monto: this.cantidad,
              cambio: "",
              descripcion: "",
              claveComprador: "",
              claveProveedor: "",
            }),
          }
        );
        if (response.status == 200) {
          this.banco = "";
          this.cantidad = 0;
          this.marker.remove();
          this.dialog = true;
        } else {
          const value = await response.json();
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } else if (this.modo == "Compra") {
        const response = await fetch(
          this.url + "/administracion/crear_pedido_reparticion",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              folio: this.folio,
              destinoLat: this.lat,
              destinoLng: this.lng,
              destino: "",
              sucursal: this.userInfo.sucursal,
              idTipoPeticion: "C",
              monto: "",
              cambio: "",
              descripcion: this.descCompra,
              claveComprador: this.compradorAuto,
              claveProveedor: this.provedorAuto,
            }),
          }
        );
        if (response.status == 200) {
          this.folio = "";
          this.compradorAuto = "";
          this.provedorAuto = "";
          this.descCompra = "";
          this.compras = [];
          this.marker.remove();
          this.dialog = true;
        } else {
          const value = await response.json();
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } else if (this.modo == "Traspaso") {
        //var tras2 = await this.traspasoTransform();
        const response = await fetch(
          this.url + "/administracion/crear_pedido_reparticion",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              folio: this.traspaso,
              destinoLat: this.lat,
              destinoLng: this.lng,
              destino: this.traspasosDatos[0].CveSucursalDestino,
              sucursal: this.userInfo.sucursal,
              idTipoPeticion: "T",
              monto: "",
              cambio: "",
              descripcion: "",
              claveComprador: "",
              claveProveedor: "",
            }),
          }
        );
        if (response.status == 200) {
          this.traspaso = "";
          this.traspasosDatos = [];
          this.marker.remove();
          this.dialog = true;
        } else {
          const value = await response.json();
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      }
    },
    async llamarTraspaso() {
      //const value = await response.json();
      const response = await fetch(
        this.url +
          "/administracion/Consulta_Pedido?nobitacora=" +
          this.traspaso +
          "&sucursal=" +
          this.userInfo.sucursal,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      console.log(value);
      if (response.status == 200) {
        if (value[0].resultado == 1) {
          this.traspasosDatos = value;
          this.marker = new mapboxgl.Marker()
            .setLngLat([
              this.traspasosDatos[0].Longitud,
              this.traspasosDatos[0].Latitud,
            ])
            .addTo(this.map);
          this.map.setCenter([
            this.traspasosDatos[0].Longitud,
            this.traspasosDatos[0].Latitud,
          ]);
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: value[0].descripcion,
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async llamarCompra() {
      //const value = await response.json();
      const response = await fetch(
        this.url +
          "/administracion/traer_Compra?sucursal=" +
          this.userInfo.sucursal +
          "&folioCompra=" +
          this.folio,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const valor = await response.json();
      if (response.status == 200) {
        if (valor[0].Resultado == 1) {
          this.compras = valor;
          if (this.compras[0].Longitud != 0 && this.compras[0].Latitud != 0) {
            this.marker = new mapboxgl.Marker()
              .setLngLat([this.compras[0].Longitud, this.compras[0].Latitud])
              .addTo(this.map);
            this.map.setCenter([
              this.compras[0].Longitud,
              this.compras[0].Latitud,
            ]);
          } else {
            const localOrig = this.compras[0].Direccion.replaceAll(" ", "%20");
            const origenResponse = await fetch(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${localOrig}.json?access_token=${this.TokenMap}`
            );
            if (response.status == 200) {
              const origenInfo = await origenResponse.json();
              var lat = origenInfo.features[0].center[1];
              var lng = origenInfo.features[0].center[0];
              this.marker = new mapboxgl.Marker()
                .setLngLat([lng, lat])
                .addTo(this.map);
              this.map.setCenter([lng, lat]);
            } else {
              let body = {
                status: response.status,
                mensaje: value.mensaje,
              };
              this.$store.dispatch("responseError", body);
            }
          }
          this.llenarAutoCmpltComprdr();
          this.llenarAutoCmpltProvdr();
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: value[0].Descripcion,
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async llenarAutoCmpltComprdr() {
      //const value = await response.json();
      const comprdr = this.compras[0].Comprador;
      const response = await fetch(
        this.url +
          "/administracion/Lista_Compradores?sucursal=" +
          this.userInfo.sucursal,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        this.compradores = value;
        this.compradorAuto = comprdr;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async llenarAutoCmpltProvdr() {
      //const value = await response.json();
      const clav = this.compras[0].Clave;
      const response = await fetch(
        this.url + "/administracion//Lista_Proveedores",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        this.provedores = value;
        this.provedorAuto = clav;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async enableBancos() {
      const response = await fetch(
        this.url + "/administracion/destino_bancos",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.bancos = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    ponerBancoCoordenadas() {
      this.marker = new mapboxgl.Marker()
        .setLngLat([this.banco.lng, this.banco.lat])
        .addTo(this.map);
      this.map.setCenter([this.banco.lng, this.banco.lat]);
    },
    traspasoTransform() {
      var traspasNot;
      traspasNot = this.traspaso;
      traspasNot = traspasNot.replace("T", "");

      traspasNot = parseInt(traspasNot);

      return traspasNot;
    },
    traerCoordenadas() {
      const lngLat = this.marker.getLngLat();
      //console.log(`lat: ${lngLat.lat}, lng: ${lngLat.lng}`);
      this.lat = lngLat.lat;
      this.lng = lngLat.lng;
    },
    submit() {
      this.$refs.observer.validate();
    },
  },
};
</script>

<style scoped>
@media (max-width: 700px) {
  #map {
    height: 50vh !important;
  }
}
</style>
