<template lang="">
  <v-container>
    <v-row><h1>Costeo de Ventas y Traspasos</h1></v-row>
    <v-row>
      <v-col cols="4">
        <v-card class="toprow">
          <v-card-subtitle>Sucursal</v-card-subtitle>
          <v-card-text>
            <v-radio-group dense v-model="radioSelected" column>
              <v-radio label="Todos" :value="1"></v-radio>
              <v-radio label="Seleccionado" :value="0"></v-radio>
            </v-radio-group>
            <sucursal-simple></sucursal-simple>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card class="toprow">
          <v-card-subtitle>Rango de Fechas</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="2">Inicial: </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu2"
                  v-model="timeIn"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time1"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time1"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeIn"
                    v-model="time1"
                    full-width
                    no-title
                    @click:minute="$refs.menu2.save(time1)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">Final: </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="menu4"
                  v-model="timeOut"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time2"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeOut"
                    v-model="time2"
                    full-width
                    no-title
                    @click:minute="$refs.menu4.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="abrirReporte()">Reporte</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    time1: "00:00",
    timeIn: false,
    menu3: false,
    menu4: false,
    time2: "23:59",
    timeOut: false,
    fechaHoraIni: "",
    fechaHoraFin: "",
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    fuseTime() {
      this.fechaHoraIni = this.date.replaceAll("-", "") + " " + this.time1;
      this.fechaHoraFin = this.date1.replaceAll("-", "") + " " + this.time2;
    },
    abrirReporte() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteCosteoVentasTraspasos",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      this.fuseTime();
      var sendlist = "";
      this.bodyForFetch = {
        strSucursal: this.radioSelected == 1 ? "TODOS" : this.sucSelected,
        TodaSucursal: this.radioSelected,
        dtFechaInicio: this.fechaHoraIni,
        dtFechaFin: this.fechaHoraFin,
      };
      //console.log(this.bodyForFetch);
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
  },
};
</script>
<style>
.toprow {
  height: 215px;
}
</style>
