<template lang="">
  <v-container>
    <v-row><h1>Reporte de Disponibilidad</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-card class="toprow">
                  <v-card-subtitle>Sucursal</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <sucursal-simple></sucursal-simple>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="toprow">
                  <v-card-subtitle>Ordenado</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-radio-group dense v-model="ordenado" row>
                          <v-radio label="Codigo" value="S"></v-radio>
                          <v-radio label="Descripcion" value="N"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="toprow">
                  <v-card-subtitle> Existencia </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="existencia"
                          label="Cero"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-card>
                  <v-card-subtitle>Categoria</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-radio-group
                          dense
                          v-model="categoriaSel"
                          hide-details
                          column
                        >
                          <v-radio label="Todo" :value="1"></v-radio>
                          <v-radio label="Seleccionado" :value="0"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list
                          :disabled="categoriaSel == 1"
                          dense
                          :color="categoriaSel == 1 ? 'grey lighten-2' : ''"
                        >
                          <v-list-item-group
                            multiple
                            v-model="selMulCat"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in categoriaList"
                              :key="i"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  :class="{ listtext: categoriaSel == 1 }"
                                  v-text="item.Categorias"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card>
                  <v-card-subtitle>Proveedor</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-radio-group
                          dense
                          v-model="proveedorSel"
                          hide-details
                          column
                        >
                          <v-radio label="Todo" :value="1"></v-radio>
                          <v-radio label="Seleccionado" :value="0"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list
                          :disabled="proveedorSel == 1"
                          dense
                          :color="proveedorSel == 1 ? 'grey lighten-2' : ''"
                        >
                          <v-list-item-group
                            multiple
                            v-model="selMulPro"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in provedList"
                              :key="i"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  :class="{ listtext: proveedorSel == 1 }"
                                  v-text="item.nombre"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn @click="abrirReporteDisponibilidad()">Reporte</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    existencia: false,
    ordenado: -1,
    categoriaSel: 1,
    proveedorSel: 1,
    selMulPro: [],
    provedList: [],
    selMulCat: [],
    categoriaList: [],
  }),
  computed: {
    ...mapState(["sucSelected", "url", "userInfo", "token"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    async listProveedor() {
      const response = await fetch(
        this.url + "/compras/trae_lista_proveedores",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            strSucursal: this.sucSelected,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.provedList = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async traerListaCategorias() {
      try {
        const response = await fetch(
          this.url + "/inventario/lista_Categorias",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.categoriaList = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    abrirReporteDisponibilidad() {
      var catSelList = "";
      var proSelList = "";
      var catArr = [];
      var provArr = [];
      var finProvShow = [];
      for (var i = 0; i < this.selMulCat.length; i++) {
        catArr = this.categoriaList[this.selMulCat[i]].Categorias.split("-");
        catSelList += catArr[0] + ", ";
      }
      for (var j = 0; j < this.selMulPro.length; j++) {
        provArr = this.provedList[this.selMulPro[j]].nombre.split("-");
        proSelList += provArr[0] + ", ";
        finProvShow += provArr[1] + ", ";
      }
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        foliocat: this.categoriaSel == 1 ? "TODOS" : catSelList,
        todaCategoria: this.categoriaSel,
        folioprov: this.proveedorSel == 1 ? "TODOS" : proSelList,
        todoProveedor: this.proveedorSel,
        orderCodigo: this.ordenado,
        intExistencia: this.existencia ? 1 : 0,
        listRemove: this.proveedorSel == 1 ? "TODOS" : finProvShow,
      };
      let routeData = this.$router.resolve({
        name: "ReporteDisponibilidad",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.listProveedor();
    this.traerListaCategorias();
  },
};
</script>
<style scoped>
.v-list {
  height: 250px; /* or any height you want */
  overflow-y: auto;
}
.toprow {
  height: 100px;
}
.listtext {
  color: #616161;
}
</style>
