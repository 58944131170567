<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table
          :headers="topHeaders"
          :items="destinos"
          :items-per-page="destinos.length"
          item-key="name"
          sort-by="zona"
          group-by="zona"
          class="elevation-1"
          show-group-by
          :expanded.sync="topExpanded"
          show-expand
          dense
          hide-default-footer
        >
          <template v-slot:group.header="{ group }"
            >Zona: {{ group }} <v-spacer></v-spacer> Total Zona:
            {{ getTotalZona(group) }}</template
          >
          <template v-slot:expanded-item="{ headers, item }">
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="11">
                <v-data-table
                  :headers="subHeaders"
                  :items="topAndSubItems[`${item.zona}-${item.destino}`]"
                  :items-per-page="
                    topAndSubItems[`${item.zona}-${item.destino}`].length
                  "
                  class="elevation-1"
                  hide-default-footer
                  dense
                ></v-data-table>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    topExpanded: [],
    topHeaders: [
      { text: "Zona", value: "zona", align: "start" },
      { text: "Destino", value: "destino", align: "start" },
      { text: "", value: "data-table-expand" },
    ],
    subHeaders: [
      { text: "Origen", value: "CveSucursalOrigen", align: "start" },
      { text: "Importe", value: "Importe", align: "start" },
    ],
    traspasos: [
      {
        Zona: "INT",
        CveSucursalOrigen: "ALL",
        CveSucursalDestino: "AP2",
        Importe: 595.5666,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "INT",
        CveSucursalOrigen: "SN1",
        CveSucursalDestino: "ES2",
        Importe: 402.5805,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "INT",
        CveSucursalOrigen: "MTZ",
        CveSucursalDestino: "LI1",
        Importe: 1426.5122,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "INT",
        CveSucursalOrigen: "ALL",
        CveSucursalDestino: "AP1",
        Importe: 265.33,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "INT",
        CveSucursalOrigen: "MTZ",
        CveSucursalDestino: "SA1",
        Importe: 1355.1405,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "OCC",
        CveSucursalOrigen: "MTZ",
        CveSucursalDestino: "LN1",
        Importe: 21674.4983,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "AP3",
        CveSucursalDestino: "MTZ",
        Importe: 5221.52219,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "COL",
        CveSucursalDestino: "MTZ",
        Importe: 1069.3029,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "ESC",
        CveSucursalDestino: "MTZ",
        Importe: 3157.89963,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "LI1",
        CveSucursalDestino: "MTZ",
        Importe: 3138.9589,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "LT4",
        CveSucursalDestino: "MTZ",
        Importe: 6011.8299,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "MM3",
        CveSucursalDestino: "MTZ",
        Importe: 293.0144,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "MT1",
        CveSucursalDestino: "MTZ",
        Importe: 116.1702,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "NLM",
        CveSucursalDestino: "MTZ",
        Importe: 17131.4712,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "RB2",
        CveSucursalDestino: "MTZ",
        Importe: 12076.1064,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "MAY",
        CveSucursalOrigen: "RYH",
        CveSucursalDestino: "MTZ",
        Importe: 7181.0373,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
      {
        Zona: "DEF",
        CveSucursalOrigen: "SA2",
        CveSucursalDestino: "JOV",
        Importe: 48750,
        FechaInicial: "2022-07-31",
        FechaFin: "2022-08-17",
      },
    ],
    destinos: [],//para tabla superficial de solo zonas y destinos
    topAndSubItems: {}, //Objeto que tiene propiedades formato "${zona}-${destino} (ej, "MAY-MTZ", cuyos valores son todos los traspasos que pertenezcan a esa zona y tengan ese destino. Asi se separan los traspasos en las diferentes tablas
  }),

  methods: {
    getTotalZona(group) {
      let totalZona = 0;
      for (const zonaYDestino in this.topAndSubItems) {
        if (zonaYDestino.includes(group)){
          for (const traspaso of this.topAndSubItems[zonaYDestino]) {
          totalZona += parseFloat(traspaso.Importe)
          }
        }
      }
      totalZona = totalZona / 2;
      return totalZona.toFixed(2);
    }
  },

  async mounted() {
    //Hay dos arrays para popular dos tablas. El primer array solo tiene la zona y sucursales destino. Este for loop crea ese array, checando de no añadir entradas duplicadas. Tambien redondea a dos decimales
    //Tambien se crea el segundo array. Crea un objeto con propiedades "${Zona}-${destino}", por ejemplo "MAY-MTZ". Y cada propiedad de estas es igual a un array de todos los traspasos que tengan esa zona y ese destino.
    for (let traspaso of this.traspasos) {
      let foundDestino = false;
      for (const destino of this.destinos) {
        if (
          destino.zona == traspaso.Zona &&
          destino.destino == traspaso.CveSucursalDestino
        ) {
          foundDestino = true;
          break;
        }
      }
      if (!foundDestino) {
        this.destinos.push({
          zona: traspaso.Zona,
          destino: traspaso.CveSucursalDestino,
        });
        this.topExpanded.push({
          zona: traspaso.Zona,
          destino: traspaso.CveSucursalDestino,
        });
      }
      if (
        !this.topAndSubItems[`${traspaso.Zona}-${traspaso.CveSucursalDestino}`]
      ) {
        this.topAndSubItems[`${traspaso.Zona}-${traspaso.CveSucursalDestino}`] =
          [];
      }
      traspaso.Importe = traspaso.Importe.toFixed(2);
      this.topAndSubItems[
        `${traspaso.Zona}-${traspaso.CveSucursalDestino}`
      ].push(traspaso);
      foundDestino = false;
    }

    //Este for se encarga de hacer la suma para el Total Destino y total Zona
    for (const zonaYDestino in this.topAndSubItems) {
      let totalDestino = 0;
      for (const traspaso of this.topAndSubItems[zonaYDestino]) {
        totalDestino += parseFloat(traspaso.Importe);
      }
      let totalDestinoObjeto = {
        CveSucursalOrigen: "Total Destino",
        Importe: totalDestino.toFixed(2),
      };
      this.topAndSubItems[zonaYDestino].push(totalDestinoObjeto);
    }
  },
};
</script>