<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <v-card-title> Reporte de Promociones </v-card-title>
          <v-row>
            <v-col>
              <v-menu
                v-model="menuFechaInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaI"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaI"
                  @input="menuFechaInicio = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="menuFechaFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaF"
                    label="Fecha Fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaF"
                  @input="menuFechaFin = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" cols="12">
              <v-checkbox
                v-model="tipo"
                value="1"
                label="Por Sucursal"
              ></v-checkbox>
            </v-col>
            <v-col xl="6" lg="6" md="6" cols="12">
              <v-checkbox
                v-model="tipo"
                value="0"
                label="Por Vendedor"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="abrirReportePromociones()"
            ><v-icon dense>mdi-printer</v-icon>Imprimir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaPromociones",
  data: () => ({
    fechaI: "",
    fechaF: "",
    menuFechaInicio: "",
    menuFechaFin: "",
    tipo: "1",
  }),
  computed: {
    ...mapState(["sucursales"]),
    computedDateFormattedMomentjs() {
      return this.fechaI ? moment(this.fechaI).format("YYYY/MM/DD") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    computedDateFormattedMomentjss() {
      return this.fechaF ? moment(this.fechaF).format("YYYY/MM/DD") : "";
    },
  },
  methods: {
    ...mapMutations("reportepromociones", ["setheaders"]),
    abrirReportePromociones() {
      this.setheaders(this.tipo);
      this.bodyForFetch = {
        FechaI: moment(this.fechaI).format("YYYYMMDD"),
        FechaF: moment(this.fechaF).format("YYYYMMDD"),
        tipo: this.tipo,
      };
      let routeData = this.$router.resolve({
        name: "ReportePromociones",
      });
      window.open(routeData.href, "_blank");
    },
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.fechaI = this.printDate();
    this.fechaF = this.printDateFn();
  },
};
</script>
<style></style>
