<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-row justify="center">
      <v-col cols="4" style="font-size: 17px">
        <v-row justify="center"> Jomar Industrias S.A. de C.V. </v-row>
        <v-row justify="center">
          <h1 align="center">Reporte de Llamadas a Clientes</h1>
        </v-row>
      </v-col>
    </v-row>
    <v-row>{{ fechaFormat() }}</v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="data"
          class="row-height-50 elevation-1"
          :items-per-page="data.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    headers: [
      { text: "Suc", value: "sucursal", align: "center" },
      { text: "Ven", value: "vendedor", align: "center" },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Total Llamadas", value: "TotalLlamadas", align: "center" },
    ],

    data: [],
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    fechaFormat() {
      var dateString;
      var fechaDeInicio;
      var fechaDeFin;
      fechaDeInicio = this.bodyForFetch.fechaIn;
      fechaDeFin = this.bodyForFetch.fechaFn;
      fechaDeInicio = moment(fechaDeInicio).format("YYYY/MM/DD");
      fechaDeFin = moment(fechaDeFin).format("YYYY/MM/DD");
      var dateString = "Fecha: " + fechaDeInicio + " a " + fechaDeFin;
      return dateString;
    },
    downloadexcel() {
      let arr = this.data;
      let data = [
        {
          sheet: "LlamadasClientes(Resumen)",
          columns: [
            { label: "Suc", value: "sucursal" },
            { label: "Ven", value: "vendedor" },
            { label: "Nombre", value: "nombre" },
            { label: "Total Llamadas", value: "TotalLlamadas" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ResumenLlamadasClientes", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    try {
      const response = await fetch(
        this.url + "/ventas/rpt_Llamada_Clientes_Resumen",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify(this.bodyForFetch),
        }
      );
      const obj = await response.json();
      //console.log(obj);
      this.data = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
