<template lang="">
  <v-container>
    <v-row><h1>Reporte de Movimientos de Almacen</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card class="toprow">
                  <v-card-subtitle>Sucursal</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group dense v-model="radioSelected" column>
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <sucursal-simple></sucursal-simple>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="toprow">
                  <v-card-subtitle>Rango de Fechas</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="5">Fecha Inicial: </v-col>
                      <v-col cols="7">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">Fecha Final: </v-col>
                      <v-col cols="7">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          :return-value.sync="date1"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date1"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date1" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu3 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu3.save(date1)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="toprow">
                  <v-card-subtitle>Tipo Reporte</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group dense v-model="tipoRepSelect" column>
                      <v-radio
                        label="Total"
                        :value="3"
                        :disabled="radioSelected == 0"
                      ></v-radio>
                      <v-radio label="Global" :value="2"></v-radio>
                      <v-radio
                        label="Detallado"
                        :value="1"
                        :disabled="radioSelected == 1"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="bottomrow">
                  <v-card-subtitle>Movimientos</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group
                      dense
                      :disabled="tipoRepSelect != 1"
                      v-model="movSelected"
                      column
                    >
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <v-autocomplete
                      hide-details
                      v-model="movTipo"
                      dense
                      solo
                      :items="tipomovslist"
                      :disabled="movSelected == 1"
                    ></v-autocomplete>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="bottomrow">
                  <v-card-subtitle>Cliente Proveedor</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group
                      dense
                      :disabled="movSelected == 1"
                      v-model="clteSelect"
                      column
                    >
                      <v-radio
                        label="Todos"
                        :value="1"
                        :disabled="tipoRepSelect == 1"
                      ></v-radio>
                      <v-radio label="Cliente Proveedor" :value="0"></v-radio>
                    </v-radio-group>
                    <v-text-field
                      v-model="cliente"
                      :disabled="clteSelect == 1"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn @click="abrirReporte()" :disabled="tipoRepSelect == -1"
                >Reporte</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    tipoRepSelect: -1,
    movSelected: 1,
    clteSelect: 1,
    movTipo: "",
    tipomovslist: [],
    cliente: "",
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    async summonTipoMovs() {
      try {
        const response = await fetch(
          this.url + "/inventario/lista_tipo_mov_alm",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value.resultado[0]);
          for (var i = 0; i < value.resultado.length - 1; i++) {
            this.tipomovslist.push(value.resultado[i][""]);
          }
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    sucursalIsolate() {
      if (this.userInfo.ESGLOBAL == true) {
        return false;
      } else {
        this.radioSelected = 0;
        this.sucSelected = this.userInfo.sucursal;
        return true;
      }
    },

    addDayIni() {
      var datef = new Date(this.date);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      datef = datef.replaceAll("-", "");
      //console.log(datef);
      return datef;
    },
    addDayFin(amount) {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + amount);
      datef = datef.toISOString().split("T")[0];
      datef = datef.replaceAll("-", "");
      //console.log(datef);
      return datef;
    },
    agarraMovimiento() {
      var tempMov = this.movTipo.split("-");
      return tempMov[0];
    },
    abrirReporte() {
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        TodaSucursal: this.radioSelected,
        dtFechaInicio: this.date.replaceAll("-", ""),
        dtFechaInicio2: this.addDayIni(),
        dtFechaFin:
          this.date == this.date1
            ? this.addDayFin(1)
            : this.date1.replaceAll("-", ""),
        dtFechaFin2: this.addDayFin(2),
        strNombreTabla: "",
        strOpcion: this.tipoRepSelect,
        IdMovimiento: this.movSelected == 1 ? "" : this.agarraMovimiento(),
        todoMovimiento: this.movSelected,
        strClte_Prov: this.cliente,
        TodoClte_Prov: this.clteSelect,
      };
      let routeData = this.$router.resolve({
        name: "ReporteMovimientosAlmacen",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.summonTipoMovs();
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
  },
};
</script>
<style>
.toprow {
  height: 180px;
}
.bottomrow {
  height: 200px;
}
</style>
