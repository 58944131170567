export default {
  url: "https://jomarwebws.jomarweb.com",
  navbarToggle: 0,
  navDrawerToggle: false,
  navDrawerOpciones: {
    administracion: {
      name: "Administración",
      children: [],
    },
    reportesDeAlmacen: {
      name: "Reportes de Almacén",
      children: [],
    },
    reportesDeVentas: {
      name: "Reportes de Ventas",
      children: [],
    },
    Ventas: {
      name: "Ventas",
      children: [],
    },
    reportesCompras: {
      name: "Reportes de Compras",
      children: [],
    },
    reportesDeTraspasos: {
      name: "Reportes de Traspasos",
      children: [],
    },
    extras: {
      name: "Extras",
      children: [
        {
          name: "Generador de QR",
          url: "/extras/generador_qr",
        },
      ],
    },
  },
  showSnackbar: false,
  message: "",
  time: 2000,
  color: "#003479",
  progressLogin: false,
  secondColor: "#e70033",
  isAuthorized: false,
  userInfo: {},
  token: "",
  sucursales: [],
  cascos: [],
  tipoMov: [],
  area: [],
  bodyForFetch: {},
  sucSelected: "",
  radioSelected: 0,
  version: "V.1.3.6",
  refreshTimesstamp: null,
  permisos: [],
};
