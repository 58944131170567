<template>
    <v-container>
      <v-card>
        <v-card-title> Reimpresion de Contra Recibo </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card>
                <v-card-subtitle>Sucursal</v-card-subtitle>
                <v-card-text>
                  <sucursal-simple></sucursal-simple>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols>
              <v-text-field
                v-model="noFactura"
                label="Factura"
                outlined
                solo
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols>
              <v-text-field
                v-model="noClave"
                label="Proveedor"
                outlined
                solo
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col align="end">
            <v-btn @click="abrirReporteReimpContraRec()">Imprimir</v-btn>
            <v-btn>Salir</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  <script>
  import sucursalSimple from "@/components/sucursalSimple";
  import { mapState } from "vuex";
  var moment = require("moment");
  export default {
    name: "PantallaReimpContraRecibo",
    components: {
      sucursalSimple,
    },
    data: () => ({
      noFactura: "",
      noClave: "",
    }),
    computed: {
      ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
      computedDateFormattedMomentjs() {
        return this.fechaIn ? moment(this.fechaIn).format("YYYY/MM/DD") : "";
      },
      computedDateFormattedMomentjss() {
        return this.fechaFn ? moment(this.fechaFn).format("YYYY/MM/DD") : "";
      },
      bodyForFetch: {
        get: function () {
          return this.$store.state.bodyForFetch;
        },
        set: function (val) {
          this.$store.commit("setBodyForFetch", val);
        },
      },
      radioSelected: {
        get: function () {
          return this.$store.state.radioSelected;
        },
        set: function (val) {
          this.$store.commit("setradioSelected", val);
        },
      },
    },
    async mounted() {
      this.$store.commit("setNavbarToggle", 0);
      await this.$store.dispatch("getSucursales", false);
      this.$store.commit("setsucSelected", this.userInfo.sucursal);
      this.$store.commit("setradioSelected", 0);
    },
    methods: {
      abrirReporteReimpContraRec() {
        this.bodyForFetch = {
          Sucursal: this.sucSelected,
          FolioFactura: this.noFactura,
          Clave: this.noClave,
        };
        let routeData = this.$router.resolve({
          name: "ReporteReimpContraRecibo",
        });
        window.open(routeData.href, "_blank");
      },
      printDate: function () {
        return moment().format("YYYY-MM-DD");
      },
      printDateFn: function () {
        return moment().format("YYYY-MM-DD");
      },
    },
  };
  </script>
  <style></style>
  