<template lang="">
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-subtitle>Sucursal</v-card-subtitle>
          <v-card-text>
            <v-radio-group
              dense
              :disabled="sucursalIsolate()"
              v-model="radioSelected"
              column
            >
              <v-radio label="Todos" :value="1"></v-radio>
              <v-radio label="Seleccionado" :value="0"></v-radio>
            </v-radio-group>
            <sucursal-simple></sucursal-simple>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-subtitle>Rango de Fechas</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="5">Fecha Inicial: </v-col>
              <v-col cols="7">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">Fecha Final: </v-col>
              <v-col cols="7">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-subtitle>Proveedor</v-card-subtitle>
          <v-card-text>
            <v-radio-group dense v-model="proveedorRadio" column>
              <v-radio label="Todos" :value="1"></v-radio>
              <v-radio label="Seleccionado" :value="0"></v-radio>
            </v-radio-group>

            <template>
              <v-card class="mx-auto" min-width="95%" tile>
                <v-list dense>
                  <v-list-item-group
                    multiple
                    v-model="selectedPItems"
                    color="primary"
                  >
                    <v-list-item v-for="(item, i) in provedList" :key="i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nombre"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-subtitle>Comprador</v-card-subtitle>
          <v-card-text>
            <v-radio-group dense v-model="compradorRadio" column>
              <v-radio label="Todos" :value="1"></v-radio>
              <v-radio label="Seleccionado" :value="0"></v-radio>
            </v-radio-group>

            <template>
              <v-card class="mx-auto" min-width="95%" tile>
                <v-list dense>
                  <v-list-item-group
                    multiple
                    v-model="selectedCItems"
                    color="primary"
                  >
                    <v-list-item v-for="(item, i) in compraList" :key="i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nombre"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="abrirReporteCompras()">Reporte</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    proveedorRadio: 1,
    selectedPItems: [],
    provedList: [],
    compradorRadio: 1,
    selectedCItems: [],
    compraList: [],
  }),
  watch: {
    sucSelected(newValue) {
      this.compradorRadio = 1;
      this.proveedorRadio = 1;
    },
    proveedorRadio(value) {
      if (value == 1) {
        this.provedList = [];
        this.selectedPItems = [];
      } else {
        this.listProveedor();
      }
    },
    compradorRadio(value) {
      if (value == 1) {
        this.compraList = [];
        this.selectedCItems = [];
      } else {
        this.listComprador();
      }
    },
  },
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    sucursalIsolate() {
      if (this.userInfo.ESGLOBAL == true) {
        return false;
      } else {
        this.radioSelected = 0;
        this.sucSelected = this.userInfo.sucursal;
        return true;
      }
    },
    async listComprador() {
      const response = await fetch(
        this.url + "/compras/trae_lista_compradores",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            strSucursal: this.sucSelected,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.compraList = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async listProveedor() {
      const response = await fetch(
        this.url + "/compras/trae_lista_proveedores",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            strSucursal: this.sucSelected,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        this.provedList = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    abrirReporteCompras() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteOrdenesCompras",
      });
      window.open(routeData.href, "_blank");
    },
    printInfo() {
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        TodaSucursal: this.radioSelected,
        dtFechaInicio: this.date.replaceAll("-", ""),
        dtFechaFin:
          this.date == this.date1
            ? this.addDay()
            : this.date1.replaceAll("-", ""),
        xmlProveedor: this.makeXMLProvee(),
        TodoProv: this.proveedorRadio,
        xmlComprador: this.makeXMLCompre(),
        TodoComprador: this.compradorRadio,
      };
      //console.log(this.bodyForFetch);
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      this.date1 = datef;
      datef = datef.replaceAll("-", "");
      //console.log(datef);
      return datef;
    },
    makeXMLProvee() {
      const xmlDoc = document.implementation.createDocument(
        null,
        "ProveedoresList"
      );
      var elements = xmlDoc.getElementsByTagName("ProveedoresList");
      for (var i = 0; i < this.selectedPItems.length; i++) {
        const rootElement = xmlDoc.createElement("Proveedores");
        rootElement.setAttribute(
          "proveedor",
          this.provedList[this.selectedPItems[i]].clave
        );
        elements[0].appendChild(rootElement);
      }
      var xmlText = new XMLSerializer().serializeToString(xmlDoc);
      //console.log(xmlText);
      return xmlText;
      // var file = document.implementation.createDocument("", "", null);
      // var element = file.createElement("Proveedores");
      // for (var i = 0; i < this.selectedPItems.length; i++) {
      //   element.setAttribute(
      //     "proveedor",
      //     this.provedList[this.selectedPItems[i]]
      //   );
      // }
      // file.appendChild(element);
      // var xmlText = new XMLSerializer().serializeToString(file);
      // //console.log(xmlText);
      // return xmlText;
    },
    makeXMLCompre() {
      const xmlDoc = document.implementation.createDocument(
        null,
        "CompradoresList"
      );
      var elements = xmlDoc.getElementsByTagName("CompradoresList");
      for (var i = 0; i < this.selectedCItems.length; i++) {
        const rootElement = xmlDoc.createElement("Compradores");
        rootElement.setAttribute(
          "comprador",
          this.compraList[this.selectedCItems[i]].comprador
        );
        elements[0].appendChild(rootElement);
      }
      var xmlText = new XMLSerializer().serializeToString(xmlDoc);
      //console.log(xmlText);
      return xmlText;
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
  },
};
</script>
<style scoped>
.v-list {
  height: 250px; /* or any height you want */
  overflow-y: auto;
}
</style>
