<template lang="">
  <v-container>
    <v-card>
      <v-card-title>
        <h2>Reporte de Pedidos Cancelados</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Sucursal</v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="radioSelected" row>
                  <v-radio label="Todos" :value="1"></v-radio>
                  <v-radio label="Sucursal seleccionada" :value="0"></v-radio>
                </v-radio-group>
                <sucursal-simple></sucursal-simple>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-subtitle>Fechas:</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="2">Fecha Inicial: </v-col>
                  <v-col cols="10">
                    <v-menu
                      v-model="menuFechaInicio"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="fechaIn"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaIn"
                        @input="menuFechaInicio = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">Fecha Final: </v-col>
                  <v-col cols="10">
                    <v-menu
                      v-model="menuFechaFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="fechaFn"
                          label="Fecha Fin"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaFn"
                        @input="menuFechaFin = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn v-on:click="abrirReportePedidosCancelados()">Imprimir</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaPedidosCancelados",
  components: {
    sucursalSimple,
  },
  data: () => ({
    fechaIn: "",
    fechaFn: "",
    menuFechaInicio: "",
    menuFechaFin: "",
  }),
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected"]),
    computedDateFormattedMomentjs() {
      return this.fechaIn ? moment(this.fechaIn).format("YYYY/MM/DD") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    computedDateFormattedMomentjss() {
      return this.fechaFn ? moment(this.fechaFn).format("YYYY/MM/DD") : "";
    },
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.fechaIn = this.printDate();
    this.fechaFn = this.printDateFn();
  },
  methods: {
    abrirReportePedidosCancelados() {
      this.bodyForFetch = {
        Fechaini: moment(this.fechaIn).format("YYYYMMDD"),
        Fechafin: moment(this.fechaFn).format("YYYYMMDD"),
        SUC: this.radioSelected == 1 ? "" : this.sucSelected,
        TODASUC: this.radioSelected,
      };
      let routeData = this.$router.resolve({
        name: "ReportePedidosCancelados",
      });
      window.open(routeData.href, "_blank");
    },
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>
<style lang=""></style>
