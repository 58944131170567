<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <div>
      <v-row justify="center" style="font-size: 17px"
        >JOMAR INDUSTRIAL, S.A. DE C.V.</v-row
      >
      <v-row justify="center" style="font-size: 15px">PEDIDO</v-row>
      <v-row>
        <v-col>
          <v-row
            >NUMERO:
            {{
              information.length == 0 ? "" : information[0].FolioPedido
            }}</v-row
          >
          <v-row
            >CLIENTE:
            {{ information.length == 0 ? "" : information[0].CusNo }}</v-row
          >
          <v-row
            >VENDEDOR:
            {{ information.length == 0 ? "" : information[0].SlsPsnNo }}</v-row
          >
          <v-row
            >ALMACEN:
            {{ information.length == 0 ? "" : information[0].Almacen }}</v-row
          >
        </v-col>
        <v-col>
          <v-row
            >FECHA PEDIDO:
            {{
              information.length == 0 ? "" : formatDate(information[0].Fecha)
            }}</v-row
          >
          <v-row
            >RAZON SOCIAL:
            {{
              information.length == 0 ? "" : information[0].RazonSocial
            }}</v-row
          >
          <v-row
            >NOMBRE:
            {{ information.length == 0 ? "" : information[0].Nombre }}</v-row
          >
        </v-col>
        <v-col>
          <v-row
            >COND. PAGO:
            {{ information.length == 0 ? "" : information[0].CondPago }}</v-row
          >
          <v-row
            >DIRECCION:
            {{ information.length == 0 ? "" : information[0].Direccion }}</v-row
          >
          <v-row
            >COLONIA:
            {{ information.length == 0 ? "" : information[0].Colonia }}</v-row
          >
          <v-row
            >CIUDAD:
            {{ information.length == 0 ? "" : information[0].Ciudad }}</v-row
          >
        </v-col>
      </v-row>
    </div>
    <br />
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="information"
        class="row-height-50 elevation-1"
        :items-per-page="information.length"
        hide-default-footer
      ></v-data-table>
    </div>
    <br />
    <v-row>
      <v-col
        >COMENTARIO:
        {{ information.length == 0 ? "" : information[0].comentario }}</v-col
      >
    </v-row>
    <v-row>
      <v-col>FECHA Y HORA DE IMPRESION: {{ dateNow }}</v-col>
      <v-col
        >N. DE IMPRESION:
        {{ information.length == 0 ? "" : information[0].Impresion }}</v-col
      >
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    importe: 0,
    disponibilidad: 0,
    information: [],
    headers: [
      { text: "ARTICULO", value: "ItemNo", align: "center" },
      { text: "DESCRIPCION", value: "Descripcion", align: "center" },
      { text: "LOCALIZACION", value: "Localizacion", align: "center" },
      { text: "CANT.", value: "CantPedida", align: "center" },
      { text: "P.U.", value: "PrecioUnit", align: "center" },
      { text: "IMPORTE", value: "importe", align: "center" },
      { text: "DISP.", value: "disponibilidad", align: "center" },
      { text: "EXIST.", value: "Existencia", align: "center" },
    ],
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    formatDate(fecha) {
      fecha = fecha.replace("T", " ");
      fecha = fecha.replace("Z", "");
      return fecha;
    },

    downloadexcel() {
      let arr = this.information;

      let data = [
        {
          sheet: "Embarques",
          columns: [
            { label: "ARTICULO", value: "ItemNo" },
            { label: "DESCRIPCION", value: "Descripcion" },
            { label: "LOCALIZACION", value: "Localizacion" },
            { label: "CANT.", value: "CantPedida" },
            { label: "P.U.", value: "PrecioUnit" },
            { label: "IMPORTE", value: "importe" },
            { label: "DISP.", value: "disponibilidad" },
            { label: "EXIST.", value: "Existencia" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "RedondeoC", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },

  async mounted() {
    try {
      //console.log(this.bodyForFetch);
      const response = await fetch(
        this.url +
          `/ventas/rpt_pedido_mayoreo?sucursal=${this.bodyForFetch.sucursal}&folio=${this.bodyForFetch.folio}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      //console.log(response);
      const obj = await response.json();
      //console.log(obj);
      this.information = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
    for (var counter = 0; counter < this.information.length; counter++) {
      this.importe =
        this.information[counter].CantPedida +
        this.information[counter].PrecioUnit;
      this.disponibilidad =
        this.information[counter].Existencia -
        this.information[counter].portraspasar -
        this.information[counter].porfacturar;
      this.information[counter].importe = this.importe;
      this.information[counter].disponibilidad = this.disponibilidad;
    }
  },
};
</script>
<style lang=""></style>
