import { render, staticRenderFns } from "./PantallaPeticionesRepXSuc.vue?vue&type=template&id=2887c7f0&lang=true&"
import script from "./PantallaPeticionesRepXSuc.vue?vue&type=script&lang=js&"
export * from "./PantallaPeticionesRepXSuc.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports