<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"
      ><h1>Reporte de Movimientos de Almacen {{ tipoMov() }}</h1></v-row
    >
    <v-row>
      <v-col>
        <v-row>Condiciones</v-row>
        <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
        <v-row>Suc: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row v-if="bodyForFetch.strOpcion == 1">
      <v-col>
        <v-data-table
          dense
          :headers="headersDetalle"
          :items="detalleList"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="50"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="bodyForFetch.strOpcion == 2">
      <v-col>
        <v-data-table
          dense
          :headers="headersGlobal"
          :items="globalList"
          sort-by="LOC"
          group-by="LOC"
          class="row-height-50 elevation-1"
          show-group-by
          :items-per-page="globalList.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="bodyForFetch.strOpcion == 3">
      <v-col>
        <v-data-table
          dense
          :headers="headersTotal"
          :items="totalList"
          class="row-height-50 elevation-1"
          :items-per-page="totalList.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end" v-if="bodyForFetch.strOpcion == 1"
      ><b>{{ "Total: $" + this.total.toFixed(2) }} </b></v-row
    >
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headersDetalle: [
      { text: "Movimiento", value: "Movimiento" },
      { text: "Fecha", value: "fechamov" },
      { text: "", value: "tipomov" },
      { text: "Documento", value: "folio" },
      { text: "Articulo", value: "itemno" },
      { text: "N", value: "Nivel" },
      { text: "Per.", value: "Periodicidad" },
      { text: "Cat.", value: "IdCategoria" },
      { text: "Categoria", value: "Categoria" },
      { text: "Alm.", value: "Almacen" },
      { text: "Clte./Prov.", value: "clte_prov" },
      { text: "Pzs", value: "piezas" },
      { text: "Cto. Unit", value: "costo" },
      { text: "Total", value: "pesos" },
      { text: "Exis.", value: "Existencia" },
    ],
    headersGlobal: [
      { text: "", value: "LOC" },
      { text: "", value: "Movimiento" },
      { text: "", value: "PESOS" },
    ],
    headersTotal: [
      { text: "", value: "Movimiento" },
      { text: "", value: "Pesos" },
    ],
    detalleList: [],
    globalList: [],
    totalList: [],

    subtotalGL: {},
    total: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    date1Format() {
      var fecha1 = "";
      fecha1 = moment(this.bodyForFetch.dtFechaInicio).format("YYYY/MM/DD");
      return fecha1;
    },
    date2Format() {
      var fecha2 = "";
      fecha2 = moment(this.bodyForFetch.dtFechaFin).format("YYYY/MM/DD");
      return fecha2;
    },
    tipoMov() {
      switch (this.bodyForFetch.strOpcion) {
        case 1:
          return "(Detallado)";
          break;
        case 2:
          return "(Global)";
          break;
        case 3:
          return "(Total)";
          break;
      }
    },
    downloadexcel() {
      //change
      let arr = this.varcostinvlst;
      let data = [];
      switch (this.bodyForFetch.strOpcion) {
        case 1:
          data = [
            {
              sheet: "Reporte de Movimientos de Almacen (Detallado)",
              columns: [
                { label: "Movimiento", value: "Movimiento" },
                { label: "Fecha", value: "fechamov" },
                { label: "", value: "tipomov" },
                { label: "Documento", value: "folio" },
                { label: "Articulo", value: "itemno" },
                { label: "N", value: "Nivel" },
                { label: "Per.", value: "Periodicidad" },
                { label: "Cat.", value: "IdCategoria" },
                { label: "Categoria", value: "Categoria" },
                { label: "Alm.", value: "Almacen" },
                { label: "Clte./Prov.", value: "clte_prov" },
                { label: "Pzs", value: "piezas" },
                { label: "Cto. Unit", value: "costo" },
                { label: "Total", value: "pesos" },
                { label: "Exis.", value: "Existencia" },
              ],
              content: arr,
            },
          ];
          break;
        case 2:
          data = [
            {
              sheet: "Reporte de Movimientos de Almacen (Global)",
              columns: [
                { label: "", value: "LOC" },
                { label: "", value: "Movimiento" },
                { label: "", value: "PESOS" },
              ],
              content: arr,
            },
          ];
          break;
        case 3:
          data = [
            {
              sheet: "Reporte de Movimientos de Almacen (Total)",
              columns: [
                { label: "", value: "Movimiento" },
                { label: "", value: "Pesos" },
              ],
              content: arr,
            },
          ];
          break;
      }
      let settings = {
        fileName: "MovimientosAlmacen", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    const response = await fetch(this.url + "/inventario/movimientos_almacen", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": this.token,
      },
      body: JSON.stringify(this.bodyForFetch),
    });
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      switch (this.bodyForFetch.strOpcion) {
        case 1:
          for (var i = 0; i < value.length; i++) {
            this.total += value[i].pesos;
          }
          this.detalleList = value;
          break;
        case 2:
          for (var i = 0; i < value.length; i++) {
            if (!this.subtotalGL.hasOwnProperty(value[i].LOC)) {
              this.subtotalGL[value[i].LOC] = 0;
            }
            this.subtotalGL[value[i].LOC] += value[i].PESOS;
          }
          for (let locacion in this.subtotalGL) {
            value.push({
              ID: null,
              LOC: locacion,
              Movimiento: "Perdida:",
              PESOS: this.subtotalGL[locacion].toFixed(2),
              PIEZAS: null,
              orden: null,
            });
          }
          this.globalList = value;
          break;
        case 3:
          for (var i = 0; i < value.length; i++) {
            this.total += value[i].Pesos;
          }
          value.push({
            ID: null,
            LOC: null,
            Movimiento: "Perdida:",
            PESOS: this.total.toFixed(2),
            PIEZAS: null,
            orden: null,
          });
          this.totalList = value;
          break;
      }
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
