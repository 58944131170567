<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"><h1>Articulos Sin Movimientos</h1></v-row>
    <v-row>
      <v-col>
        <v-row justify="start">Sucursal: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="articulosMov"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="50"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">Suma Total: ${{ total.toFixed(2) }} </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headers: [
      { text: "Suc", value: "SUCURSAL" },
      { text: "Id Articulo", value: "Itemno" },
      { text: "Articulo", value: "Descripcion" },
      { text: "Cat", value: "IdCategoria" },
      { text: "Categoria", value: "Categoria" },
      { text: "Cve Prov", value: "PROV" },
      { text: "Proveedor", value: "PROVEEDOR" },
      { text: "Alm", value: "Almacen" },
      { text: "Exis.", value: "Existencia" },
      { text: "D.Sin Vta", value: "DiasSinVta" },
      { text: "ingreso.", value: "ingreso" },
      { text: "Ult Venta", value: "ULTIMAVENTA" },
      { text: "ultimasal.", value: "ultimasalida" },
      { text: "Total", value: "suma" },
      { text: "VtalA", value: "Venta1Año" },
      { text: "Nivel", value: "Nivel" },
    ],
    articulosMov: [],
    total: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  async mounted() {
    const response = await fetch(this.url + "/inventario/meses_sin_mov", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": this.token,
      },
      body: JSON.stringify(this.bodyForFetch),
    });
    if (response.status == 200) {
      const value = await response.json();
      for (var i = 0; i < value.length; i++) {
        var sum = 0;
        sum = value[i].CostoProm * value[i].Existencia;
        value[i].suma = sum.toFixed(2);
        this.total += sum;
      }
      await this.dateAndSum(value);
      this.articulosMov = value;
      //console.log(this.articulosMov);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
  methods: {
    dateAndSum(tabla) {
      for (var i = 0; i < tabla.length; i++) {
        var ultVenArr = tabla[i].ULTIMAVENTA.split("T");
        var ingArr = tabla[i].ingreso.split("T");
        var ultSalArr = tabla[i].ultimasalida.split("T");
        var date1 = moment(ultVenArr[0]).format("DD/MM/YYYY");
        var date2 = moment(ingArr[0]).format("DD/MM/YYYY");
        var date3 = moment(ultSalArr[0]).format("DD/MM/YYYY");
        tabla[i].ULTIMAVENTA = date1;
        tabla[i].ingreso = date2;
        tabla[i].ultimasalida = date3;
      }
    },
    downloadexcel() {
      let arr = this.articulosMov;
      let data = [
        {
          sheet: "Reporte de Articulos sin Movimientos",
          columns: [
            { label: "Suc", value: "SUCURSAL" },
            { label: "Id Articulo", value: "Itemno" },
            { label: "Articulo", value: "Descripcion" },
            { label: "Cat", value: "IdCategoria" },
            { label: "Categoria", value: "Categoria" },
            { label: "Cve Prov", value: "PROV" },
            { label: "Proveedor", value: "PROVEEDOR" },
            { label: "Alm", value: "Almacen" },
            { label: "Exis.", value: "Existencia" },
            { label: "D.Sin Vta", value: "DiasSinVta" },
            { label: "ingreso.", value: "ingreso" },
            { label: "Ult Venta", value: "ULTIMAVENTA" },
            { label: "ultimasal.", value: "ultimasalida" },
            { label: "Total", value: "suma" },
            { label: "VtalA", value: "Venta1Año" },
            { label: "Nivel", value: "Nivel" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ArticulosMov", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
