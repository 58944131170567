export default {
  namespaced: true,
  state: {},
  actions: {
    tableFill: async ({ state, commit, dispatch, rootState }, payload) => {
      let body = rootState.bodyForFetch;
      //console.log(body);
      try {
        const response = await fetch(
          rootState.url + "/products/product/Kardex",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": rootState.token,
            },
            body: JSON.stringify(body),
          }
        );
        //console.log(response);
        const obj = await response.json();
        //console.log(obj);
        return obj;
      } catch (error) {
        //console.log(error);
      }
    },
    getDisponibilidad: async (
      { state, commit, dispatch, rootState },
      payload
    ) => {
      const response = await fetch(
        rootState.url +
          "/products/disponible_art/" +
          rootState.bodyForFetch.Itemno +
          "/" +
          rootState.bodyForFetch.Suc,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": rootState.token,
          },
        }
      );
      const value = await response.json();
      //console.log(value);
      return value;
    },
  },
};
