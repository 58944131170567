export default {
  namespaced: true,
  state: {},
  actions: {
    tableFill: async ({ state, commit, dispatch, rootState }, payload) => {
      let body = rootState.bodyForFetch;
      //console.log(body);
      try {
        const response = await fetch(
          rootState.url +
            `/traspasos/reporte_embarque_sucursal?FechaIn=${body.fechaIn}&FechaFn=${body.fechaFn}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": rootState.token,
            },
          }
        );
        //console.log(response);
        const obj = await response.json();
        //console.log(obj);
        return obj;
      } catch (error) {
        //console.log(error);
      }
    },
  },
};
