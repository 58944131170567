<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card color="#EEF2F2">
            <v-card-text>
              <v-form>
                <v-card-title>
                  <h2>Inventario Negativo</h2>
                </v-card-title>
                <v-card-actions>
                  <v-radio-group v-model="radioSelected" row>
                    <v-radio label="Todos" :value="1" dense></v-radio>
                    <v-radio
                      label="Sucursal seleccionada"
                      :value="0"
                      dense
                    ></v-radio>
                  </v-radio-group>
                </v-card-actions>
                <sucursal-simple></sucursal-simple>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="abrirReporteNegativo()"
                ><v-icon dense>mdi-printer</v-icon>Imprimir</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaInventarioNegativo",
  components: {
    sucursalSimple,
  },
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
  },
  methods: {
    abrirReporteNegativo() {
      this.bodyForFetch = {
        Sucursal: this.radioSelected == 1 ? "" : this.sucSelected,
        TodoSuc: this.radioSelected,
      };
      let routeData = this.$router.resolve({
        name: "ReporteInventarioNegativo",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 1);
  },
};
</script>
<style></style>
