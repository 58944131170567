<template>
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>Fecha de impresion: {{ dateNow }} </v-row>
    <v-row>
      <v-col cols="12" style="font-size: 17px">
        <v-row>Desde: {{ fechaIn }} </v-row>
        <v-row>Hasta: {{ fechaFn }} </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 align="center">Reportes Ventas por Credito</h1>
        <v-data-table
          :headers="headers"
          :items="respuestaDeApi"
          :items-per-page="respuestaDeApi.length"
          hide-default-footer
          class="elevation-1 row-height-50"
          :loading="loadingt"
          loading-text="Loading... Please wait"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  name: "Tabla",

  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      loadingt: true,
      fechaIn: "",
      fechaFn: "",
      respuestaDeApi: [],
      headers: [
        {
          text: "Suc",
          value: "Sucursal",
        },
        {
          text: "Folio Factura",
          value: "FolioFactura",
        },
        {
          text: "Condpago",
          value: "Condpago",
        },
        {
          text: "Cusno",
          value: "Cusno",
        },
        {
          text: "VentaReal",
          value: "VentaReal",
        },
        {
          text: "SinPromo",
          value: "SinPromo",
        },
        {
          text: "Costo",
          value: "Costo",
        },
        {
          text: "Antes de Sup",
          value: "AntesdeSup",
        },
        {
          text: "DifSup",
          value: "DifSup",
        },
        {
          text: "Precio",
          value: "Precio",
        },
        {
          text: "Precio Dev",
          value: "PrecioDev",
        },
        {
          text: "CostoDev",
          value: "CostoDev",
        },
        {
          text: "PrecioDto",
          value: "PrecioDto",
        },
        {
          text: "CostoDto",
          value: "CostoDto",
        },
        {
          text: "PrecioCas",
          value: "PrecioCas",
        },
        {
          text: "CostoCas",
          value: "CostoCas",
        },
        {
          text: "PrecioCancDev",
          value: "PrecioCancDev",
        },
        {
          text: "CostoCancDev",
          value: "CostoCancDev",
        },
        {
          text: "PrecioCancDto",
          value: "PrecioCancDto",
        },
        {
          text: "CostCancDto",
          value: "CostoCancDto",
        },
        {
          text: "PrecioCancCas",
          value: "PrecioCancCas",
        },
        {
          text: "CostoCancCas",
          value: "CostoCancCas",
        },
        {
          text: "Fecha",
          value: "Fecha",
        },
        {
          text: "ConDscto",
          value: "ConDscto",
        },
        {
          text: "Vendedor",
          value: "Vendedor",
        },
        {
          text: "Cliente",
          value: "Cliente",
        },
      ],
    };
  },
  methods: {
    ...mapActions("reporteventascredito", ["tableFill"]),
    downloadexcel() {
      let arr = this.respuestaDeApi;
      let data = [
        {
          sheet: "ReporteVentasCredito",
          columns: [
            { label: "Suc", value: "Sucursal" },
            { label: "Folio factura", value: "FolioFactura" },
            { label: "Condpago", value: "Condpago" },
            { label: "Cusno", value: "Cusno" },
            { label: "VentaReal", value: "VentaReal" },
            { label: "SinPromo", value: "SinPromo" },
            { label: "Costo", value: "Costo" },
            { label: "AntesdeSup", value: "AntesdeSup" },
            { label: "DifSup", value: "DifSup" },
            { label: "Precio", value: "Precio" },
            { label: "PrecioDev", value: "PrecioDev" },
            { label: "CostoDev", value: "CostoDev" },
            { label: "PrecioDto", value: "PrecioDto " },
            { label: "CostoDto", value: "CostoDto" },
            { label: "PrecioCas", value: "PrecioCas" },
            { label: "CostoCas", value: "CostoCas" },
            { label: "PrecioCancDev", value: "PrecioCancDev" },
            { label: "CostoCancDev", value: "CostoCancDev" },
            { label: "PrecioCancDto", value: "PrecioCancDto" },
            { label: "CostoCancDto", value: "CostoCancDto" },
            { label: "PrecioCancCas", value: "PrecioCancCas" },
            { label: "CostoCancCas", value: "CostoCancCas" },
            { label: "Fecha", value: "Fecha" },
            { label: "ConDscto", value: "ConDscto" },
            { label: "Vendedor", value: "Vendedor" },
            { label: "Cliente", value: "Cliente" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteVentasCredito", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let arr = await this.tableFill();
    arr.resultado.forEach((element) => {
      element.FechaIn = moment(element.fechaInicio).format(
        "DD/MM/YYYY hh:mm a"
      );
      element.FechaFn = moment(element.fechaFin).format("DD/MM/YYYY hh:mm a");
    });
    this.fechaIn = arr.resultado[0].FechaIn;
    this.fechaFn = arr.resultado[0].FechaFn;
    this.loadingt = false;
    this.respuestaDeApi = arr.resultado;
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
