<template>
  <div>
    <v-card>
      <v-container>
        <v-card-title><h2>Permisos por Usuarios</h2></v-card-title>
        <v-row>
          <v-col>
            <v-autocomplete
              label="Selecciona Usuario"
              v-model="autocomUsuario"
              :items="listaAutoUsuario"
              item-text="Usuarios"
              item-value="Usuarios"
              dense
              hide-details
              @change="traerListaMenu"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              label="Selecciona Menu"
              v-model="autocomMenu"
              :items="listaAutoMenu"
              item-text="Menus"
              item-value="Menus"
              dense
              hide-details
              @change="
                traerListaSinPerm(autocomUsuario, autocomMenu);
                traerListaConPerm(autocomUsuario, autocomMenu);
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5">
            <v-row justify="center">
              <template>
                <v-card class="mx-auto" min-width="95%" tile>
                  <v-list dense>
                    <v-subheader>Sin permiso</v-subheader>
                    <v-list-item-group v-model="selectedItemA" color="primary">
                      <v-list-item v-for="(item, i) in itemsSin" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.SinAutorizar"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row justify="center" min-width="90%"
              ><v-btn block @click="insertarPermisos(selectedItemA)">{{
                boton1text
              }}</v-btn></v-row
            >
            <v-row justify="center" min-width="90%"
              ><v-btn block @click="quitarPermisos(selectedItemB)">{{
                boton2text
              }}</v-btn></v-row
            >
          </v-col>
          <v-col cols="5">
            <v-row justify="center">
              <template>
                <v-card class="mx-auto" min-width="95%" tile>
                  <v-list dense>
                    <v-subheader>Con permiso</v-subheader>
                    <v-list-item-group v-model="selectedItemB" color="primary">
                      <v-list-item v-for="(item, i) in itemsCon" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.Autorizado"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    autocomUsuario: "",
    listaAutoUsuario: [],
    autocomMenu: "",
    listaAutoMenu: [],
    boton1text: ">",
    boton2text: "<",
    selectedItemA: -1,
    itemsSin: [],
    selectedItemB: -1,
    itemsCon: [],
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
  },
  async mounted() {
    this.traerListaUsuario();
  },
  methods: {
    async traerListaUsuario() {
      try {
        const response = await fetch(
          this.url + "/administracion/listaUsuariosPerNvo",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.listaAutoUsuario = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async traerListaMenu() {
      try {
        const response = await fetch(
          this.url + "/administracion/listaMenuUsuarios",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.listaAutoMenu = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async traerListaSinPerm(Usuarios, Menus) {
      let arr = Usuarios.split("-");
      let arr2 = Menus.split("-");
      try {
        const response = await fetch(
          this.url + "/administracion/usuariosSinPerm",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              USUARIO_ID: arr[0],
              ID_PADRE: arr2[0],
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.itemsSin = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async traerListaConPerm(Usuarios, Menus) {
      let arr = Usuarios.split("-");
      let arr2 = Menus.split("-");
      try {
        const response = await fetch(
          this.url + "/administracion/usuariosConPerm",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              USUARIO_ID: arr[0],
              ID_PADRE: arr2[0],
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.itemsCon = value.respuesta;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async insertarPermisos(permiso) {
      //console.log(this.itemsSin[permiso].SinAutorizar);
      //console.log(this.autocomUsuario);
      let arr = this.itemsSin[permiso].SinAutorizar.split("-");
      let arr2 = this.autocomUsuario.split("-");
      //console.log(arr[0]);
      //console.log(arr2[0]);
      try {
        const response = await fetch(
          this.url + "/administracion/insertarPermiso",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              IdUsuario: arr2[0],
              AplicacionId: arr[0],
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log("Se dio el permiso");
          this.traerListaSinPerm(this.autocomUsuario, this.autocomMenu);
          this.traerListaConPerm(this.autocomUsuario, this.autocomMenu);
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async quitarPermisos(permiso) {
      //console.log(this.itemsCon[permiso].Autorizado);
      //console.log(this.autocomUsuario);
      let arr = this.itemsCon[permiso].Autorizado.split("-");
      let arr2 = this.autocomUsuario.split("-");
      //console.log(arr[0]);
      //console.log(arr2[0]);
      try {
        const response = await fetch(
          this.url + "/administracion/quitarPermiso",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              APLICACION_ID: arr[0],
              USUARIO_ID: arr2[0],
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          this.traerListaSinPerm(this.autocomUsuario, this.autocomMenu);
          this.traerListaConPerm(this.autocomUsuario, this.autocomMenu);
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.v-list {
  height: 500px; /* or any height you want */
  overflow-y: auto;
}
@media (max-width: 600px) {
  #buttons {
    margin-top: 15px;
  }
}
</style>
