<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row
      ><h1>{{ information[0].COND }}</h1></v-row
    >
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="information"
          item-key="Sucursal"
          class="row-height-50 elevation-1"
          :items-per-page="information.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
export default {
  data() {
    return {
      columnas: [],
      total: 0,
      headers: [
        { text: "Suc", value: "SUCURSAL", align: "center" },
        { text: "Id", value: "IDCATEGORIA", align: "center" },
        { text: "Categoria", value: "CATEGORIA", align: "center" },
      ],

      information: [],
    };
  },
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    downloadexcel() {
      let arr = this.information;

      let data = [
        {
          sheet: "ReporteVentasMesCategoria",
          columns: [
            { label: "Suc", value: "SUCURSAL", align: "center" },
            { label: "Id", value: "IDCATEGORIA", align: "center" },
            { label: "Categoria", value: "CATEGORIA", align: "center" },
            { label: "Mes 1", value: "MES1", align: "center" },
            { label: "Mes 2", value: "MES2", align: "center" },
            { label: "Mes 3", value: "MES3", align: "center" },
            { label: "Mes 4", value: "MES4", align: "center" },
            { label: "Mes 5", value: "MES5", align: "center" },
            { label: "Mes 6", value: "MES6", align: "center" },
            { label: "Mes 7", value: "MES7", align: "center" },
            { label: "Mes 8", value: "MES8", align: "center" },
            { label: "Mes 9", value: "MES9", align: "center" },
            { label: "Mes 10", value: "MES10", align: "center" },
            { label: "Mes 11", value: "MES11", align: "center" },
            { label: "Mes 12", value: "MES12", align: "center" },
            { label: "TOTAL", value: "total", align: "center" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "VentasMesCategoria", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    let today = new Date();
    let month = today.getMonth() + 1;
    for (var counter = 0; counter < 12; counter++) {
      if (counter == 0) {
        this.headers.push({
          text: `${month}`,
          value: `MES${month}`,
          align: "center",
        });
      } else if (month - counter < 1) {
        this.headers.push({
          text: `${12 + month - counter}`,
          value: `MES${12 + month - counter}`,
          align: "center",
        });
      } else if (month - counter >= 1) {
        this.headers.push({
          text: `${month - counter}`,
          value: `MES${month - counter}`,
          align: "center",
        });
      }
    }
    this.headers.push({
      text: "Total",
      value: "total",
      align: "center",
    });

    try {
      const response = await fetch(
        this.url +
          `/ventas/rpt_ventas_mes_categoria?sucursal=${this.bodyForFetch.sucursal}&tipo=${this.bodyForFetch.tipo}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      //console.log(response);
      const obj = await response.json();
      //console.log(obj);
      this.information = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
    for (var counter = 0; counter < this.information.length; counter++) {
      this.total =
        this.information[counter].MES1 +
        this.information[counter].MES2 +
        this.information[counter].MES3 +
        this.information[counter].MES4 +
        this.information[counter].MES5 +
        this.information[counter].MES6 +
        this.information[counter].MES7 +
        this.information[counter].MES8 +
        this.information[counter].MES9 +
        this.information[counter].MES10 +
        this.information[counter].MES11 +
        this.information[counter].MES12;
      this.information[counter].total = this.total.toFixed(0);
      this.information[counter].MES1 =
        this.information[counter].MES1.toFixed(0);
      this.information[counter].MES2 =
        this.information[counter].MES2.toFixed(0);
      this.information[counter].MES3 =
        this.information[counter].MES3.toFixed(0);
      this.information[counter].MES4 =
        this.information[counter].MES4.toFixed(0);
      this.information[counter].MES5 =
        this.information[counter].MES5.toFixed(0);
      this.information[counter].MES6 =
        this.information[counter].MES6.toFixed(0);
      this.information[counter].MES7 =
        this.information[counter].MES7.toFixed(0);
      this.information[counter].MES8 =
        this.information[counter].MES8.toFixed(0);
      this.information[counter].MES9 =
        this.information[counter].MES9.toFixed(0);
      this.information[counter].MES10 =
        this.information[counter].MES10.toFixed(0);
      this.information[counter].MES11 =
        this.information[counter].MES11.toFixed(0);
      this.information[counter].MES12 =
        this.information[counter].MES12.toFixed(0);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
