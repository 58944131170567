<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <v-card-title><h2>Mantenimiento de Repartidores</h2></v-card-title>
          <v-row align="center">
            <v-col xl="2" lg="2" md="2" cols="12">
              <v-checkbox v-model="activo" label="Activo"></v-checkbox>
            </v-col>
            <v-col xl="5" lg="5" md="5" cols="12">
              <v-spacer></v-spacer>
              <v-btn @click="llenarTabla()"
                ><v-icon dense>mdi-account-search</v-icon>Buscar</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersRepa"
                :items="repas"
                @click:row="handleRowClick"
                mobile-breakpoint="0"
              >
                <template v-slot:item.act="{ item }">
                  <v-simple-checkbox
                    v-model="item.activo ? true : false"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.last="{ item }">
                  <v-btn dark :color="color" @click="AbrirUltimaUb(item)">
                    Ultima Ubicación
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row>
              <v-col xl="4" lg="4" md="4" cols="12">
                <v-text-field
                  ref="datos"
                  v-model="idrepa"
                  label="idRepartidor"
                  outlined
                  dense
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col xl="4" lg="4" md="4" cols="12">
                <v-text-field
                  ref="datos"
                  v-model="nombreForm"
                  label="Nombre"
                  outlined
                  dense
                  :disabled="justNew"
                ></v-text-field>
              </v-col>
              <v-col xl="4" lg="4" md="4" cols="12">
                <v-text-field
                  ref="datos"
                  v-model="usuario"
                  label="Usuario"
                  outlined
                  dense
                  :disabled="activar"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="4" lg="4" md="4" cols="12">
                <v-text-field
                  ref="datos"
                  v-model="password"
                  label="Password"
                  outlined
                  dense
                  :disabled="activar"
                ></v-text-field>
              </v-col>
              <v-col xl="4" lg="4" md="4" cols="12">
                <v-checkbox
                  v-model="ActivoForm"
                  label="Activo"
                  :disabled="modifica"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="limpiarDatos()"
          ><v-icon dense>mdi-delete</v-icon>Limpiar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="nuevoUsuario()"
          ><v-icon dense>mdi-new-box</v-icon>Nuevo</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="guardarUsuario()"
          ><v-icon dense>mdi-content-save</v-icon>Guardar</v-btn
        >
      </v-card-actions>
    </v-container>
    <v-dialog v-model="dAlerta" max-width="400" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="margin-top: 15px">
          <v-row>
            <v-col>
              <sweetalert-icon icon="error" />
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h2>{{ mensajeError }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :color="color" text @click="dAlerta = !dAlerta">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog transition="fade-transition" v-model="dmapa" max-width="600px">
      <v-card>
        <div
          ref="mapContainer"
          id="map"
          style="height: 60vh; width: 100%; overflow: hidden"
        >
          <v-skeleton-loader
            v-if="skeleton"
            type="image, image, image"
            height="60vh"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//let xlsx = require("json-as-xlsx");
import { mapState, mapActions } from "vuex";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
export default {
  data: () => ({
    enabled: false,
    nombre: "",
    activo: false,
    ActivoForm: false,
    activar: true,
    modifica: true,
    valor: "",
    opBtnNuevo: false,
    opBtnModificar: false,
    idrepa: "",
    nombreForm: "",
    usuario: "",
    password: "",
    repas: [],
    headersRepa: [
      {
        text: "Repartidor",
        value: "idRepartidor",
      },
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Sucursal",
        value: "sucursal",
      },
      {
        text: "Usuario",
        value: "usuario",
      },
      {
        text: "Contraseña",
        value: "password",
      },
      {
        text: "Activo",
        value: "act",
      },
      {
        text: "Ultima Ubicación",
        value: "last",
        sortable: false,
      },
    ],
    justNew: true,
    dAlerta: false,
    mensajeError: "",
    TokenMap:
      "pk.eyJ1Ijoiam9tYXJpbmR1c3RyaWFzIiwiYSI6ImNsNTlzY3llYTJmNWEza3FxcHJyMDZ4bjkifQ.GcFBgAtC6I4vN5eSH49LeA",
    lat: 0,
    lng: 0,
    map: null,
    dmapa: false,
    skeleton: true,
    marker: null,
  }),
  computed: {
    ...mapState(["url", "userInfo", "token", "color"]),
  },

  watch: {
    dmapa(value) {
      if (!value) {
        this.map.remove();
      }
    },
  },

  async mounted() {
    await this.llenarTabla();
  },
  methods: {
    limpiarDatos() {
      this.$refs.form.reset();
      this.activar = true;
      this.modifica = true;
      this.justNew = true;
      this.llenarTabla();
    },
    nuevoUsuario() {
      this.$refs.form.reset();
      this.ActivoForm = true;
      this.modifica = true;
      this.activar = false;
      this.opBtnNuevo = true;
      this.opBtnModificar = false;
      this.justNew = false;
    },
    handleRowClick(value) {
      this.activar = false;
      if (value.activo == 0) {
        this.justNew = false;
        this.ActivoForm = true;
      } else {
        this.justNew = true;
        this.ActivoForm = value.activo;
      }
      this.modifica = false;
      this.idrepa = value.idRepartidor;
      this.nombreForm = value.nombre;
      this.usuario = value.usuario;
      this.password = value.password;
      this.opBtnNuevo = false;
      this.opBtnModificar = true;
    },
    async llenarTabla() {
      try {
        //console.log(this.token);
        const response = await fetch(
          this.url + "/administracion/traer_repartidores",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Sucursal: this.userInfo.sucursal,
              SoloActivos: this.activo,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.repas = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async guardarUsuario() {
      if (this.opBtnNuevo == true) {
        var doc = document.implementation.createDocument("", "", null);
        var ejemploElem = doc.createElement("Repartidor");
        var element = doc.createElement("DatosRep");
        element.setAttribute("nombre", this.nombreForm);
        element.setAttribute("sucursal", this.userInfo.sucursal);
        element.setAttribute("usuario", this.usuario);
        element.setAttribute("password", this.password);
        element.setAttribute("activo", this.ActivoForm ? 1 : 0);
        ejemploElem.appendChild(element);
        doc.appendChild(ejemploElem);
        var xmlText = new XMLSerializer().serializeToString(doc);
        //console.log(xmlText);
        const response = await fetch(
          this.url + "/administracion/alta_repartidores",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              xml: xmlText,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value[0]);
          if (value[0].Respuesta == 1) {
            this.limpiarDatos();
          } else {
            this.mensajeError = value[0].mensaje;
            this.dAlerta = true;
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
          this.limpiarDatos();
        }
      } else {
        var doc = document.implementation.createDocument("", "", null);
        var ejemploElem = doc.createElement("Repartidor");
        var element = doc.createElement("DatosRep");
        element.setAttribute("idRepartidor", this.idrepa);
        element.setAttribute("nombre", this.nombreForm);
        element.setAttribute("sucursal", this.userInfo.sucursal);
        element.setAttribute("usuario", this.usuario);
        element.setAttribute("password", this.password);
        element.setAttribute("activo", this.ActivoForm ? 1 : 0);
        ejemploElem.appendChild(element);
        doc.appendChild(ejemploElem);
        var xmlText = new XMLSerializer().serializeToString(doc);
        const response = await fetch(
          this.url + "/administracion/actualiza_repartidores",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              xml: xmlText,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value[0]);
          if (value[0].Respuesta == 1) {
            this.limpiarDatos();
          } else {
            this.mensajeError = value[0].mensaje;
            this.dAlerta = true;
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
          this.limpiarDatos();
        }
      }
    },
    AbrirUltimaUb(item) {
      this.dmapa = true;
      this.skeleton = true;
      if (this.marker) {
        this.marker.remove();
      }
      setTimeout(() => {
        this.skeleton = false;
        this.initializeMap();
        this.marker = new mapboxgl.Marker()
          .setLngLat([item.ultimaUbicacionlng, item.ultimaUbicacionlat])
          .addTo(this.map);
        this.map.setCenter([item.ultimaUbicacionlng, item.ultimaUbicacionlat]);
      }, 800);
    },
    initializeMap() {
      const div = this.$refs.mapContainer;
      mapboxgl.accessToken = this.TokenMap;
      this.map = new mapboxgl.Map({
        container: div,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [this.userInfo.Longitud, this.userInfo.Latitud],
        zoom: 16,
        projection: "globe",
      });
    },
  },
};
</script>
<style scoped>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

@media (max-width: 700px) {
  #map {
    height: 50vh !important;
  }
}
.mapboxgl-popup {
  max-width: 200px;
}
</style>
