<template>
  <v-app>
    <navbar v-if="isAuthorized"></navbar>
    <v-main>
      <router-view />
    </v-main>
    <v-snackbar
      v-model="showSnackbar"
      outlined
      :color="secondColor"
      :timeout="time"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="secondColor"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "./components/Navbar.vue";
import Login from "./views/Login.vue";
export default {
  name: "App",

  components: {
    Navbar,
    Login,
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState([
      "color",
      "secondColor",
      "message",
      "time",
      "isAuthorized",
      "refreshTimesstamp",
      "token",
      "url",
      "permisos",
    ]),
    showSnackbar: {
      get: function () {
        return this.$store.state.showSnackbar;
      },
      set: function (val) {
        this.$store.state.showSnackbar = val;
      },
    },
    refreshTimesstamp: {
      get: function () {
        return this.$store.state.refreshTimesstamp;
      },
      set: function (val) {
        this.$store.commit("setrefreshTimesstamp", val);
      },
    },
  },

  methods: {
    goRoute(ruta) {
      this.$router.push(ruta);
    },
    async traerState() {
      try {
        const response = await fetch(this.url + "/administracion/getState", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        const value = await response.json();
        if (response.status == 200) {
          this.$store.commit("setState", value);
          this.$store.commit("CargaMenu", this.permisos);
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
  },

  mounted() {
    if (!this.isAuthorized && this.$route.path != "/login") {
      if (this.$route.path != "/politica_de_privacidad") {
        this.$store.commit("ExitApp");
      }
    }
    if (this.refreshTimesstamp == null || !this.isAuthorized) {
      return;
    }
    let timenow = new Date();
    let timestate = new Date(this.refreshTimesstamp);
    let diferencia = timenow.getTime() - timestate.getTime();
    let differenceInHours = diferencia / (1000 * 3600);
    if (differenceInHours > 0.083) {
      this.traerState();
      this.refreshTimesstamp = new Date();
    }
  },

  updated() {
    if (!this.isAuthorized && this.$route.path != "/login") {
      if (this.$route.path != "/politica_de_privacidad") {
        this.$store.commit("ExitApp");
      }
    }
    if (this.refreshTimesstamp == null || !this.isAuthorized) {
      return;
    }
    let timenow = new Date();
    let timestate = new Date(this.refreshTimesstamp);
    let diferencia = timenow.getTime() - timestate.getTime();
    let differenceInHours = diferencia / (1000 * 3600);
    if (differenceInHours > 0.083) {
      this.traerState();
      this.refreshTimesstamp = new Date();
    }
  },
};
</script>

<style>
@media print {
  .noPrint {
    display: none;
  }
  body {
    zoom: 80%;
  }
}
</style>
