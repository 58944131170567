<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <v-form>
            <v-card-title> Kardex Nuevo </v-card-title>
            <v-row>
              <v-col cols="1">Sucursal:</v-col>
              <v-col cols="11">
                <sucursal-simple></sucursal-simple>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">Articulo:</v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  label="Producto"
                  outlined
                  solo
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn block><v-icon dense> mdi-binoculars </v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">Tipo Mov:</v-col>
              <v-col cols="11">
                <v-autocomplete
                  v-model="tipoMovSelected"
                  :items="tipoMov"
                  item-text="TipoMovAlmacen"
                  item-value="TipoMov"
                  :rules="[(v) => !!v || 'Seleccione movimiento']"
                  solo
                  label="Tipo Movimiento"
                  required
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">Area:</v-col>
              <v-col cols="11">
                <v-autocomplete
                  v-model="areaSelected"
                  :items="area"
                  item-text="Area"
                  item-value="AreaId"
                  :rules="[(v) => !!v || 'Seleccione Area']"
                  solo
                  label="Area"
                  required
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-radio-group v-model="rangoSelected" row>
                <v-radio label="Todos" :value="1" dense></v-radio>
                <v-radio label="Rango de fechas" :value="0" dense></v-radio>
              </v-radio-group>
            </v-card-actions>
            <v-card-actions>
              <v-row cols="6">
                <v-menu
                  v-model="menuFechaInicio"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      label="Fecha Inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="rangoSelected == 1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaInicio"
                    @input="menuFechaInicio = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuFechaFin"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjss"
                      label="Fecha Fin"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="rangoSelected == 1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFin"
                    @input="menuFechaFin = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>
            </v-card-actions>
          </v-form>
          <v-textarea
            name=""
            label=""
            auto-grow
            outlined
            dense
            rows="1"
            row-height="15"
            value="Si incluye el mes actual, solo puede consultar hasta 3 años atras. Si NO incluye el mes actual, puede consultar toda la informacion historica existente"
            disabled
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="abrirReporteKardex()" block
            ><v-icon dense>mdi-printer</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaKardex",
  components: {
    sucursalSimple,
  },
  data: () => ({
    //sucSelected: "TODOS",
    search: "",
    tipoMovSelected: "0",
    areaSelected: "A1",
    rangoSelected: 0,
    fechaInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuFechaInicio: "",
    menuFechaFin: "",
  }),
  computed: {
    ...mapState(["tipoMov", "area", "sucSelected", "userInfo"]), //"sucursales",
    computedDateFormattedMomentjs() {
      return this.fechaInicio
        ? moment(this.fechaInicio).format("MMMM/YYYY")
        : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    computedDateFormattedMomentjss() {
      return this.fechaFin ? moment(this.fechaFin).format("MMMM/YYYY") : "";
    },
  },
  methods: {
    abrirReporteKardex() {
      //console.log(moment(this.fechaInicio).format("YYYYmmdd") + "00:00:00");
      this.bodyForFetch = {
        Suc: this.sucSelected,
        FechaIn: moment(this.fechaInicio).format("YYYYMM") + "01 00:00:00",
        FechaFn: moment(this.fechaFin).format("YYYYMMDD") + " 23:59:59",
        Itemno: this.search,
        TipoMov: this.tipoMovSelected == "0" ? "" : this.tipoMovSelected,
        TodaSuc: this.sucSelected == "TODOS" ? 1 : 0,
        Area: this.areaSelected,
        TodaArea: this.rangoSelected,
      };
      //console.log(this.bodyForFetch);
      let routeData = this.$router.resolve({
        name: "ReporteKardex",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.$store.dispatch("getTipoMov");
    this.$store.dispatch("getArea");
  },
};
</script>
<style></style>
