<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"
      ><h1>Reporte de Costeo de Ventas y Traspasos</h1></v-row
    >
    <v-row>
      <v-col>
        <v-row>Condiciones</v-row>
        <v-row>Desde: {{ date1Format() }} Hasta: {{ date2Format() }}</v-row>
        <v-row>Suc: {{ bodyForFetch.strSucursal }}</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="costeoList"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="85"
          sort-by="Grupo"
          group-by="sucursal"
          show-group-by
        >
          <template v-slot:body.append>
            <tr>
              <td />
              <td>
                <b>{{ totalRow.Concepto }}</b>
              </td>
              <td>
                <b>{{ totalRow.subtotal.toFixed(2) }}</b>
              </td>
              <td>
                <b>{{ totalRow.iva.toFixed(2) }}</b>
              </td>
              <td>
                <b>{{ totalRow.total.toFixed(2) }}</b>
              </td>
              <td>
                <b>{{ totalRow.costo.toFixed(2) }}</b>
              </td>
              <td>
                <b>{{ totalRow.utilidad.toFixed(2) }}</b>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headers: [
      { text: "#Doctos.", value: "contador" },
      { text: "Concepto", value: "Concepto" },
      { text: "Subtotal", value: "subtotal" },
      { text: "IVA", value: "iva" },
      { text: "Total", value: "total" },
      { text: "Costo", value: "costo" },
      { text: "Utilidad", value: "utilidad" },
    ],
    costeoList: [],
    totalRow: {
      Concepto: "Total General",
      Grupo: "6",
      contador: null,
      costo: 0,
      iva: 0,
      subtotal: 0,
      sucursal: null,
      total: 0,
      utilidad: 0,
    },
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  //FOR LATER, THIS ONE HAS CODE FOR NESTING SUMS
  methods: {
    date1Format() {
      var tempdate = this.bodyForFetch.dtFechaInicio.split(" ");
      var fecha1 = tempdate[0];
      var hora1 = tempdate[1];
      fecha1 = moment(fecha1).format("DD/MM/YYYY");
      return fecha1 + " " + hora1;
    },
    date2Format() {
      var tempdate1 = this.bodyForFetch.dtFechaFin.split(" ");
      var fecha2 = tempdate1[0];
      var hora2 = tempdate1[1];
      fecha2 = moment(fecha2).format("DD/MM/YYYY");
      return fecha2 + " " + hora2;
    },
    tableCalculus(value) {
      var arr = value;
      var totalSRow = {};
      var subTotalRows = {};

      for (var i = 0; i < arr.length; i++) {
        this.totalRow.costo += arr[i].costo;
        this.totalRow.iva += arr[i].iva;
        this.totalRow.subtotal += arr[i].subtotal;
        this.totalRow.total += arr[i].total;
        this.totalRow.utilidad += arr[i].utilidad;
      }
      for (var i = 0; i < arr.length; i++) {
        if (!totalSRow.hasOwnProperty(arr[i].sucursal)) {
          totalSRow[arr[i].sucursal] = {
            Concepto: "Total Sucursal",
            Grupo: "5",
            contador: null,
            costo: 0,
            iva: 0,
            subtotal: 0,
            sucursal: arr[i].sucursal,
            total: 0,
            utilidad: 0,
          };
        }
        totalSRow[arr[i].sucursal].costo += arr[i].costo;
        totalSRow[arr[i].sucursal].iva += arr[i].iva;
        totalSRow[arr[i].sucursal].subtotal += arr[i].subtotal;
        totalSRow[arr[i].sucursal].total += arr[i].total;
        totalSRow[arr[i].sucursal].utilidad += arr[i].utilidad;
      }
      for (var i = 0; i < arr.length; i++) {
        if (!subTotalRows.hasOwnProperty(arr[i].sucursal)) {
          subTotalRows[arr[i].sucursal] = {
            sub1row: {
              Concepto: "SUBTOTAL (Factura):",
              Grupo: "1",
              contador: 0,
              costo: 0,
              iva: 0,
              subtotal: 0,
              sucursal: arr[i].sucursal,
              total: 0,
              utilidad: 0,
            },
            sub2row: {
              Concepto: "SUBTOTAL (Nota de Cargo):",
              Grupo: "2",
              contador: 0,
              costo: 0,
              iva: 0,
              subtotal: 0,
              sucursal: arr[i].sucursal,
              total: 0,
              utilidad: 0,
            },
            sub3row: {
              Concepto: "SUBTOTAL (Nota de Credito):",
              Grupo: "3",
              contador: 0,
              costo: 0,
              iva: 0,
              subtotal: 0,
              sucursal: arr[i].sucursal,
              total: 0,
              utilidad: 0,
            },
            sub4row: {
              Concepto: "SUBTOTAL (Traspaso):",
              Grupo: "4",
              contador: 0,
              costo: 0,
              iva: 0,
              subtotal: 0,
              sucursal: arr[i].sucursal,
              total: 0,
              utilidad: 0,
            },
          };
        }
        switch (arr[i].Grupo) {
          case "1":
            subTotalRows[arr[i].sucursal].sub1row.contador += arr[i].contador;
            subTotalRows[arr[i].sucursal].sub1row.costo += arr[i].costo;
            subTotalRows[arr[i].sucursal].sub1row.iva += arr[i].iva;
            subTotalRows[arr[i].sucursal].sub1row.subtotal += arr[i].subtotal;
            subTotalRows[arr[i].sucursal].sub1row.total += arr[i].total;
            subTotalRows[arr[i].sucursal].sub1row.utilidad += arr[i].utilidad;
            break;
          case "2":
            subTotalRows[arr[i].sucursal].sub2row.contador += arr[i].contador;
            subTotalRows[arr[i].sucursal].sub2row.costo += arr[i].costo;
            subTotalRows[arr[i].sucursal].sub2row.iva += arr[i].iva;
            subTotalRows[arr[i].sucursal].sub2row.subtotal += arr[i].subtotal;
            subTotalRows[arr[i].sucursal].sub2row.total += arr[i].total;
            subTotalRows[arr[i].sucursal].sub2row.utilidad += arr[i].utilidad;
            break;
          case "3":
            subTotalRows[arr[i].sucursal].sub3row.contador += arr[i].contador;
            subTotalRows[arr[i].sucursal].sub3row.costo += arr[i].costo;
            subTotalRows[arr[i].sucursal].sub3row.iva += arr[i].iva;
            subTotalRows[arr[i].sucursal].sub3row.subtotal += arr[i].subtotal;
            subTotalRows[arr[i].sucursal].sub3row.total += arr[i].total;
            subTotalRows[arr[i].sucursal].sub3row.utilidad += arr[i].utilidad;
            break;
          case "4":
            subTotalRows[arr[i].sucursal].sub4row.contador += arr[i].contador;
            subTotalRows[arr[i].sucursal].sub4row.costo += arr[i].costo;
            subTotalRows[arr[i].sucursal].sub4row.iva += arr[i].iva;
            subTotalRows[arr[i].sucursal].sub4row.subtotal += arr[i].subtotal;
            subTotalRows[arr[i].sucursal].sub4row.total += arr[i].total;
            subTotalRows[arr[i].sucursal].sub4row.utilidad += arr[i].utilidad;
            break;
        }
      }
      for (let STsucursal in subTotalRows) {
        arr.push(subTotalRows[STsucursal].sub1row);
        arr.push(subTotalRows[STsucursal].sub2row);
        arr.push(subTotalRows[STsucursal].sub3row);
        arr.push(subTotalRows[STsucursal].sub4row);
      }

      for (let TSsucursal in totalSRow) {
        arr.push(totalSRow[TSsucursal]);
      }
      this.costeoList = arr;
      //console.log(this.costeoList);
    },
    downloadexcel() {
      //change
      let arr = this.costeoList;
      arr.push(this.totalRow);
      let data = [
        {
          sheet: "Reporte de Costeo de Ventas y Traspasos",
          columns: [
            { label: "suc", value: "sucursal" },
            { label: "#Doctos.", value: "contador" },
            { label: "Concepto", value: "Concepto" },
            { label: "Subtotal", value: "subtotal" },
            { label: "IVA", value: "iva" },
            { label: "Total", value: "total" },
            { label: "Costo", value: "costo" },
            { label: "Utilidad", value: "utilidad" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "CosteoVentasTraspasos", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    const response = await fetch(
      this.url + "/inventario/costeo_ventas_traspasos",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      }
    );
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      this.tableCalculus(value);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
