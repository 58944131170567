<template lang="">
  <v-container>
    <v-card>
      <v-card-title> Diferencias de Cascos </v-card-title>
      <v-card-text>
        <v-card>
          <v-card-subtitle>Sucursal</v-card-subtitle>
          <v-container>
            <v-autocomplete
              v-model="sucursalDeHoy"
              dense
              solo
              label="Sucursal: "
              :items="sucursales"
              item-text="sucursal"
              item-value="sucursal"
            ></v-autocomplete>
          </v-container>
        </v-card>
        <br />
        <v-container>
          <v-row>
            <v-col cols="1" justify="center">Cascos: </v-col>
            <v-col>
              <v-autocomplete
                v-model="articuloCasco"
                dense
                solo
                :items="cascos"
                item-text="casco"
                item-value="casco"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <v-card>
          <v-card-subtitle>Fecha</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn @click="abrirReporteDiferenciasCascos()">Imprimir</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    articuloCasco: "",
    detalle: "",
    sucursalDeHoy: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
  }),
  methods: {
    abrirReporteDiferenciasCascos() {
      this.printInfo();
      let routeData = this.$router.resolve({
        name: "ReporteDiferenciasCascos",
      });
      window.open(routeData.href, "_blank");
    },

    printInfo() {
      this.bodyForFetch = {
        fechaIni: this.date.replaceAll("-", ""),
        sucursal: this.sucursalDeHoy,
        articulo: this.articuloCasco,
      };
      //console.log(this.bodyForFetch);
      //console.log(this.sucursalDeHoy);
    },
  },
  computed: {
    ...mapState(["sucursales", "cascos"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  mounted() {
    this.$store.commit("setNavbarToggle", 0);
    this.$store.dispatch("getSucursales");
    this.$store.dispatch("getCascos");
    this.$store.dispatch("getTipoMov");
    this.$store.dispatch("getArea");
  },
};
</script>
<style lang=""></style>
