<template lang="">
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="1" justify="center"><br />Sucursal: </v-col>
          <v-col>
            <v-container>
              <v-autocomplete
                v-model="sucursalDeHoy"
                dense
                solo
                label="Sucursal: "
                :items="sucursales"
                item-text="sucursal"
                item-value="sucursal"
              ></v-autocomplete>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>Reporte: </v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="reportePagina" column>
                  <v-radio label="Por Cliente" value="cliente"></v-radio>
                  <v-radio label="Por Vendedor" value="vendedor"></v-radio>
                  <v-radio label="Por Categoria" value="categoria"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-subtitle>Tipo: </v-card-subtitle>
              <v-card-text>
                <v-checkbox
                  dense
                  hide-details
                  v-model="contado"
                  label="Contado"
                ></v-checkbox>
                <v-checkbox
                  dense
                  hide-details
                  v-model="credito"
                  label="Credito"
                ></v-checkbox>
                <v-checkbox
                  dense
                  v-model="resumen"
                  label="Resumen"
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn @click="abrirReporteVentasMes()">Imprimir</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    reportePagina: "",
    sucursalDeHoy: "",
    contado: false,
    credito: false,
    resumen: false,
    name: "",
    tipo: 0,
  }),
  methods: {
    abrirReporteVentasMes() {
      this.checkboxSwitch();
      this.bodyForFetch = {
        sucursal: this.sucursalDeHoy,
        tipo: this.tipo,
      };
      //console.log(this.bodyForFetch);
      this.pantallaSelect();
      //console.log(this.name);
      let name1 = this.name;
      let routeData = this.$router.resolve({ name: name1 });
      window.open(routeData.href, "_blank");
    },
    checkboxSwitch() {
      if (this.contado == true) {
        this.tipo = 0;
      } else if (this.credito == true) {
        this.tipo = 1;
      } else if (this.contado == true && this.credito == true) {
        this.tipo = 2;
      } else if (
        this.contado == true &&
        this.credito == true &&
        this.resumen == true
      ) {
        this.tipo = 3;
      } else if (this.resumen == true) {
        this.tipo = 4;
      } else {
        this.tipo = 0;
      }
      return this.tipo;
    },
    pantallaSelect() {
      if (this.reportePagina == "cliente") {
        this.name = "ReporteVentasMesCliente";
      } else if (this.reportePagina == "vendedor") {
        this.name = "ReporteVentasMesVendedor";
      } else if (this.reportePagina == "categoria") {
        this.name = "ReporteVentasMesCategoria";
      } else {
        this.name = "PantallaVentasMes";
      }
    },
  },

  computed: {
    ...mapState(["sucursales"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  mounted() {
    this.$store.commit("setNavbarToggle", 0);
    this.$store.dispatch("getSucursales");
    this.$store.dispatch("getTipoMov");
    this.$store.dispatch("getArea");
  },
};
</script>
<style lang=""></style>
