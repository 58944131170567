<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-row justify="center" style="font-size: 17px"
      >Reporte de Redondeo Global</v-row
    >
    <v-row justify="start">
      <v-col cols="4" style="font-size: 12px">
        <v-row>Fecha Inicial: {{ formatDate(bodyForFetch.fecIni) }} </v-row>
      </v-col>
      <v-col cols="4" style="font-size: 12px">
        <v-row justify="center"
          >Fecha Final: {{ formatDate(bodyForFetch.fecFin) }}</v-row
        >
      </v-col>
      <v-col cols="4" style="font-size: 12px">
        <v-row justify="end">Fecha de Impresión: {{ dateNow }} </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="information"
          item-key="Sucursal"
          sort-by="Zona"
          group-by="Zona"
          class="row-height-50 elevation-1"
          show-group-by
          :items-per-page="information.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8"></v-col>
      <v-col
        ><b> Total: {{ total.toFixed(2) }}</b>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "ReporteRedondeoC",
  data() {
    return {
      subTotalPorZona: {},
      total: 0,
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      headers: [
        { text: "Zona", value: "Zona", align: "center" },
        { text: "Sucursal", value: "Sucursal", align: "center" },
        { text: "Redondeo", value: "Redondeo", align: "center" },
      ],

      information: [],
    };
  },
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    formatDate(fecha) {
      fecha = fecha.replace(" ", "T");
      fecha = fecha.replace(":", "");
      return moment(fecha).format("DD/MM/YYYY HH:mm");
    },
    downloadexcel() {
      let arr = this.information;

      let data = [
        {
          sheet: "Embarques",
          columns: [
            { label: "Zona", value: "Zona" },
            { label: "Sucursal", value: "Sucursal" },
            { label: "Redondeo", value: "Redondeo" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "RedondeoC", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    try {
      const response = await fetch(
        this.url +
          `/ventas/redondeo_global?fecIni=${this.bodyForFetch.fecIni}&fecFin=${this.bodyForFetch.fecFin}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      ////console.log(response);
      const obj = await response.json();
      ////console.log(obj);
      this.information = obj.resultado;
    } catch (error) {
      //console.log(error);
    }

    for (var counter = 0; counter < this.information.length; counter++) {
      if (
        !this.subTotalPorZona.hasOwnProperty(this.information[counter].Zona)
      ) {
        this.subTotalPorZona[this.information[counter].Zona] = 0;
      }
      this.subTotalPorZona[this.information[counter].Zona] +=
        this.information[counter].Redondeo;
      this.total += this.information[counter].Redondeo;
    }
    // //console.log(this.subTotalPorZona);
    // //console.log(this.total);

    for (let Zona in this.subTotalPorZona) {
      this.information.push({
        Zona: Zona,
        Sucursal: "Sub Total",
        Redondeo: this.subTotalPorZona[Zona].toFixed(2),
      });
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
