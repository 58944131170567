import router from "@/router";

export default {
  snackbar: (state, payload) => {
    state.showSnackbar = payload.snackbar;
    state.message = payload.text;
  },
  setIsAuthorized: (state, payload) => {
    state.isAuthorized = payload;
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setToken: (state, payload) => {
    state.token = payload;
  },
  setSucursales: (state, payload) => {
    state.sucursales = payload;
  },
  setCascos: (state, payload) => {
    state.cascos = payload;
  },
  setTipoMov: (state, payload) => {
    state.tipoMov = payload;
  },
  setArea: (state, payload) => {
    ////console.log(payload);
    state.area = payload;
  },
  setBodyForFetch: (state, payload) => {
    state.bodyForFetch = payload;
  },
  setNavbarToggle: (state, payload) => {
    state.navbarToggle = payload;
  },
  setNavDrawerToggle: (state, payload) => {
    state.navDrawerToggle = payload;
  },
  //change the state to acquire and channel the information into the values
  setsucSelected: (state, payload) => {
    state.sucSelected = payload;
  },
  setradioSelected: (state, payload) => {
    state.radioSelected = payload;
  },
  ExitApp: (state) => {
    state.isAuthorized = false;
    state.userInfo = {};
    state.token = "";
    window.localStorage.removeItem("vuex");
    window.localStorage.clear();
    state.navDrawerOpciones.administracion.children = [];
    state.navDrawerOpciones.reportesDeAlmacen.children = [];
    state.navDrawerOpciones.reportesDeVentas.children = [];
    state.navDrawerOpciones.Ventas.children = [];
    state.navDrawerOpciones.reportesCompras.children = [];
    state.navDrawerOpciones.reportesDeTraspasos.children = [];
    router.push({ name: "Login" });
    window.location.reload();
  },
  CargaMenu: (state, payload) => {
    state.permisos = payload;
    for (var i = 1; i < payload.length; i++) {
      var obj = {
        name: payload[i].Descripcion,
        url: payload[i].URL,
      };
      switch (payload[i].ID_PADRE) {
        case 1000:
          state.navDrawerOpciones.administracion.children.push(obj);
          break;
        case 2050:
          state.navDrawerOpciones.reportesDeAlmacen.children.push(obj);
          break;
        case 3000:
          state.navDrawerOpciones.Ventas.children.push(obj);
          break;
        case 3050:
          state.navDrawerOpciones.reportesDeVentas.children.push(obj);
          break;
        case 5050:
          state.navDrawerOpciones.reportesCompras.children.push(obj);
          break;
        case 7050:
          state.navDrawerOpciones.reportesDeTraspasos.children.push(obj);
          break;
      }
    }
  },
  setrefreshTimesstamp: (state, payload) => {
    state.refreshTimesstamp = payload;
  },
  setState: (state, payload) => {
    state.version = payload.version;
    state.navDrawerOpciones = payload.navDrawerOpciones;
    state.url = payload.url;
    state.color = payload.color;
    state.secondColor = payload.secondColor;
  },
};
