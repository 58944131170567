<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-card-title> Confiabilidad de Embarques</v-card-title>
              <v-row>
                <v-menu
                  v-model="menuFechaInicio"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="fechaIn"
                      label="Fecha Inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaIn"
                    @input="menuFechaInicio = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>
              <v-row>
                <v-menu
                  v-model="menuFechaFin"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="fechaFn"
                      label="Fecha Fin"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFn"
                    @input="menuFechaFin = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="abrirReporteEmbarqueTraspasos()"
                ><v-icon dense>mdi-printer</v-icon>Imprimir</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "PantallaVentasCall",
  data: () => ({
    fechaIn: "",
    fechaFn: "",
    menuFechaInicio: "",
    menuFechaFin: "",
  }),
  computed: {
    ...mapState(["sucursales"]),
    computedDateFormattedMomentjs() {
      return this.fechaIn ? moment(this.fechaIn).format("YYYY/MM/DD") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    computedDateFormattedMomentjss() {
      return this.fechaFn ? moment(this.fechaFn).format("YYYY/MM/DD") : "";
    },
  },
  methods: {
    abrirReporteEmbarqueTraspasos() {
      //console.log(moment(this.fechaInicio).format("YYYYmmdd") + " 00:00:00");
      this.bodyForFetch = {
        fechaIn: moment(this.fechaIn).format("YYYYMMDD") + " 00:00:00",
        fechaFn: moment(this.fechaFn).format("YYYYMMDD") + " 23:59:59",
      };
      let routeData = this.$router.resolve({
        name: "ReporteEmbarqueTraspasos",
      });
      window.open(routeData.href, "_blank");
    },
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.$store.dispatch("getSucursales");
    this.fechaIn = this.printDate();
    this.fechaFn = this.printDateFn();
  },
};
</script>
<style></style>
