<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center"><h1>Reporte Disponibilidad</h1></v-row>
    <v-row>
      <v-col>
        <v-row justify="start">Condiciones</v-row>
      </v-col>
      <v-col>
        <v-row justify="end">{{ dateNow }}</v-row>
      </v-col>
    </v-row>
    <v-row justify="start">Sucursal: {{ bodyForFetch.strSucursal }}</v-row>
    <br />
    <v-row justify="start">Proveedor: {{ nombre }}</v-row>
    <v-row justify="start">Existencia Cero: {{ existenciaShow() }}</v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="disponiList"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="1000"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>{{ sumaExist }}</v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
    headers: [
      { text: "Disponibilidad", value: "existencia" },
      { text: "Codigo", value: "itemno" },
      { text: "Descripcion", value: "descripcion" },
      { text: "Nivel", value: "nivel" },
    ],
    disponiList: [],
    nombre: "",
    sumaExist: 0,
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    existenciaShow() {
      if (this.bodyForFetch.intExistencia == 1) {
        return "SI";
      } else {
        return "NO";
      }
    },
    proveerList() {
      this.nombre = this.bodyForFetch.listRemove;
      delete this.bodyForFetch.listRemove;
    },
    downloadexcel() {
      let arr = this.disponiList;
      let data = [
        {
          sheet: "Disponibilidad",
          columns: [
            { label: "Disponibilidad", value: "existencia" },
            { label: "Codigo", value: "itemno" },
            { label: "Descripcion", value: "descripcion" },
            { label: "Nivel", value: "nivel" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "ReporteDisponibilidad", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    this.proveerList();
    const response = await fetch(
      this.url + "/inventario/reportar_disponibilidad_jdb",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      }
    );
    if (response.status == 200) {
      const value = await response.json();
      //console.log(value);
      for (var i = 0; i < value.length; i++) {
        this.sumaExist += value[i].existencia;
      }
      this.disponiList = value;
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
