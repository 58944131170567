<template lang="">
  <v-container>
    <v-card>
      <v-card-title>Reimpresion Embarque</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="title"
          v-on:keyup.enter="enableButton()"
          counter="25"
          hint="Folios solo tienen números"
          label="Folio Embarque"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-col align="end">
          <v-btn :disabled="enableSwitch" @click="abrirReimpresionEmbarque()">
            Imprimir
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      enableSwitch: true,
      title: "",
    };
  },
  computed: {
    ...mapState(["url", "token"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    async enableButton() {
      //console.log(
      //this.url + "/traspasos/validar_folio_embarque?folio=" + this.title
      //);
      const response = await fetch(
        this.url + "/traspasos/validar_folio_embarque?folio=" + this.title,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      //console.log(value);
      if (value.apiResponse == true) {
        this.enableSwitch = false;
      } else this.enableSwitch = true;
    },
    abrirReimpresionEmbarque() {
      this.bodyForFetch = { folio: this.title };
      let routeData = this.$router.resolve({
        name: "ReporteReimpresionEmbarque",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style lang=""></style>
