<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-row justify="stretch">
      <v-col cols="4" style="font-size: 12px">
        <v-row>No. Embarque: {{ information[0].NoEmbarque }}</v-row>
        <v-row>Maquina Embarco: {{ information[0].MaquinaEmbarca }}</v-row>
        <v-row>Embarco: {{ information[0].Embarco }}</v-row>
      </v-col>
      <v-col cols="4" style="font-size: 17px">
        <v-row justify="center"> Jomar Industrias S.A. de C.V. </v-row>
        <v-row justify="center">
          <h1>Reporte de Embarque</h1>
        </v-row>
      </v-col>
      <v-col cols="4" style="font-size: 12px">
        <v-row justify="end">
          {{ formatDate(information[0].HoraEmbarco) }} :Fecha</v-row
        >
        <v-row justify="end"> {{ information[0].Descripcion }} :Vehiculo</v-row>
        <v-row justify="end"> {{ information[0].Chofer }} :Chofer</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="information"
          item-key="SucursalDest"
          sort-by="SucursalDest"
          group-by="SucursalDest"
          class="row-height-50 elevation-1"
          show-group-by
          :items-per-page="information.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data() {
    return {
      headers: [
        {
          text: "Sucursal Dest",
          align: "start",
          value: "SucursalDest",
        },
        { text: "Empaque", value: "Empaque", align: "center" },
        { text: "Almacén", value: "Almacen", align: "center" },
        { text: "Tarima", value: "Tarima", align: "center" },
      ],

      information: [],
    };
  },
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    formatDate(HoraEmbarco) {
      return moment(HoraEmbarco).format("DD/MM/YYYY hh:mm A");
    },
    downloadexcel() {
      let arr = this.information;
      arr.forEach((element) => {
        element.HoraEmbarco = moment(element.HoraEmbarco).format(
          "DD/MM/YYYY HH:mm A"
        );
      });

      let data = [
        {
          sheet: "Embarques",
          columns: [
            { label: "Sucursal Dest", value: "SucursalDest" },
            { label: "Empaque", value: "Empaque" },
            { label: "Almacén", value: "Almacen" },
            { label: "Tarima", value: "Tarima" },
            { label: "No. Embarque", value: "NoEmbarque" },
            { label: "Maquina Embarco", value: "MaquinaEmbarca" },
            { label: "Embarco", value: "Embarco" },
            { label: "Fecha", value: "HoraEmbarco" },
            { label: "Vehículo", value: "Descripcion" },
            { label: "Chofer", value: "Chofer" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "Embarques", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    try {
      const response = await fetch(
        this.url + "/traspasos/reimpresion_embarque/" + this.bodyForFetch.folio,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      //console.log(response);
      const obj = await response.json();
      //console.log(obj);
      this.information = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
