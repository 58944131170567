<template lang="">
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Reporte de Gastos Contado </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-subtitle>Sucursal</v-card-subtitle>
                  <v-card-text>
                    <v-radio-group
                      dense
                      :disabled="sucursalIsolate()"
                      v-model="radioSelected"
                      column
                    >
                      <v-radio label="Todos" :value="1"></v-radio>
                      <v-radio label="Seleccionado" :value="0"></v-radio>
                    </v-radio-group>
                    <sucursal-simple></sucursal-simple>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-subtitle>Rango de Fechas</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="5">Fecha Inicial: </v-col>
                      <v-col cols="7">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">Fecha Final: </v-col>
                      <v-col cols="7">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          :return-value.sync="date1"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date1"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date1" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu3 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu3.save(date1)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col align="end">
              <v-btn @click="abrirReporte()">Reporte</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
var moment = require("moment");
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
  }),
  computed: {
    ...mapState(["sucursales", "url", "token", "userInfo", "sucSelected"]),
    radioSelected: {
      get: function () {
        return this.$store.state.radioSelected;
      },
      set: function (val) {
        this.$store.commit("setradioSelected", val);
      },
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    sucursalIsolate() {
      if (this.userInfo.ESGLOBAL == true) {
        return false;
      } else {
        this.radioSelected = 0;
        this.sucSelected = this.userInfo.sucursal;
        return true;
      }
    },
    abrirReporte() {
      this.bodyForFetch = {
        dtFechaInicio: this.date.replaceAll("-", ""),
        dtFechaFin:
          this.date == this.date1
            ? this.addDay()
            : this.date1.replaceAll("-", ""),
        strSucursal: this.sucSelected,
        TodaSucursal: this.radioSelected,
      };
      let routeData = this.$router.resolve({
        name: "ReporteGastosContado",
      });
      window.open(routeData.href, "_blank");
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      this.date1 = datef;
      datef = datef.replaceAll("-", "");
      //console.log(datef);
      return datef;
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", true);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
  },
};
</script>
<style lang=""></style>
