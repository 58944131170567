<template>
  <div>
    <v-container>
      <v-row><h1>Mantenimiento de Tecnicos LTH</h1></v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-text>
                      <v-row align="center">
                        <v-col lg="1" md="2">Sucursal: </v-col>
                        <v-col lg="2" md="3"
                          ><v-autocomplete
                            :items="sucList"
                            dense
                            v-model="sucursal"
                            hide-details
                            @change="buscar()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col lg="1" md="2"
                          ><v-btn
                            block
                            @click="summonUsuarios()"
                            :disabled="sucursal == ''"
                            >buscar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    class="row-height-50 elevation-1"
                    :mobile-breakpoint="0"
                    :items-per-page="50"
                    hide-default-footer
                    @click:row="handleRowClick"
                    ><template v-slot:item.act="{ item }">
                      <v-simple-checkbox
                        v-model="item.activo ? true : false"
                        disabled
                      ></v-simple-checkbox>
                    </template>
                    <template v-slot:item.last="{ item }">
                      <v-btn
                        :dark="item.ultimaUbicacionFecha != null"
                        :disabled="item.ultimaUbicacionFecha == null"
                        small
                        @click="AbrirUltimaUb(item)"
                        :color="color"
                      >
                        Ultima Ubicación
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-show="!buscarBtnswitch">
                <v-col>
                  <v-card>
                    <v-card-text>
                      <v-form ref="form" v-model="guardarValid" lazy-validation>
                        <v-row align="center">
                          <v-col md="2" cols="12">
                            <v-text-field
                              hide-details
                              label="ID Tecnico"
                              v-model="idtec"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col md="2" cols="6">
                            <v-text-field
                              hide-details
                              label="Nombre"
                              v-model="nombre"
                              :disabled="nuevoSwitch == 'NONE'"
                              :rules="nombreRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="2" cols="6">
                            <v-text-field
                              hide-details
                              label="Usuario"
                              v-model="usuario"
                              :disabled="nuevoSwitch == 'NONE'"
                              :rules="usuarioRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="2" cols="6">
                            <v-text-field
                              hide-details
                              label="Contraseña"
                              v-model="password"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="passwordRules"
                              :type="show ? 'text' : 'password'"
                              @click:append="show = !show"
                              :disabled="nuevoSwitch == 'NONE'"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="2" cols="6">
                            <v-checkbox
                              label="Activo"
                              dense
                              hide-details
                              v-model="activo"
                              :disabled="nuevoSwitch != 'MOD'"
                              required
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row justify="end">
                          <v-col md="2" cols="4">
                            <v-btn
                              block
                              @click="cleanup()"
                              :disabled="fieldCheckClean()"
                              >limpiar</v-btn
                            >
                          </v-col>
                          <v-col md="2" cols="4">
                            <v-btn
                              block
                              :disabled="sucursal == 'TODOS'"
                              @click="nuevoUsr()"
                              >nuevo
                            </v-btn>
                          </v-col>
                          <v-col md="2" cols="4">
                            <v-btn
                              block
                              @click="guardarUsuario()"
                              :disabled="fieldCheckSave()"
                              >guardar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog transition="fade-transition" v-model="dmapa" max-width="600px">
      <v-card>
        <div
          ref="mapContainer"
          id="map"
          style="height: 60vh; width: 100%; overflow: hidden"
        >
          <v-skeleton-loader
            v-if="skeleton"
            type="image, image, image"
            height="60vh"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
export default {
  data: () => ({
    sucursal: "",
    idtec: "",
    nombre: "",
    nombreRules: [(v) => !!v || "Nombre es requerido"],
    usuario: "",
    usuarioRules: [(v) => !!v || "Usuario es requerido"],
    password: "",
    passwordRules: [(v) => !!v || "Password es requerida"],
    show: false,
    activo: true,
    buscarBtnswitch: true,
    nuevoSwitch: "NONE",
    guardarValid: true,
    suctemp: "",
    headers: [
      { text: "Id Tecnico", value: "idTecnico" },
      { text: "Nombre", value: "nombre" },
      { text: "Sucursal", value: "sucursal" },
      { text: "Usuario", value: "usuario" },
      { text: "Contraseña", value: "password" },
      { text: "Activo", value: "act" }, //template con checkbox
      { text: "Ultima Ubicacion", value: "last" }, //template con boton
    ],
    items: [],
    sucList: ["TODOS", "LEO", "LT3", "LT4"],
    TokenMap:
      "pk.eyJ1Ijoiam9tYXJpbmR1c3RyaWFzIiwiYSI6ImNsNTlzY3llYTJmNWEza3FxcHJyMDZ4bjkifQ.GcFBgAtC6I4vN5eSH49LeA",
    lat: 0,
    lng: 0,
    map: null,
    dmapa: false,
    skeleton: true,
    marker: null,
  }),
  computed: {
    ...mapState(["url", "userInfo", "token", "color"]),
  },
  watch: {
    dmapa(value) {
      if (!value) {
        this.map.remove();
      }
    },
  },
  methods: {
    buscar() {
      this.nuevoSwitch = "NONE";
      this.buscarBtnswitch = true;
      this.items = [];
    },
    fieldCheckClean() {
      if (this.usuario != "" || this.nombre != "" || this.password != "") {
        return false;
      } else {
        return true;
      }
    },
    fieldCheckSave() {
      if (this.usuario != "" && this.nombre != "" && this.password != "") {
        return false;
      } else {
        return true;
      }
    },
    cleanup() {
      this.$refs.form.reset();
      this.idtec = "";
      this.nombre = "";
      this.usuario = "";
      this.password = "";
      this.activo = false;
      this.suctemp = "";
    },
    handleRowClick(value) {
      this.nuevoSwitch = "MOD";
      this.idtec = value.idTecnico;
      this.nombre = value.nombre;
      this.usuario = value.usuario;
      this.password = value.password;
      this.activo = value.activo;
      this.suctemp = value.sucursal;
    },
    nuevoUsr() {
      this.$refs.form.reset();
      this.idtec = "";
      this.nombre = "";
      this.usuario = "";
      this.password = "";
      this.nuevoSwitch = "NEW";
      this.suctemp = this.sucursal;
      this.activo = true;
    },
    async summonUsuarios() {
      if (this.sucursal == "TODOS") {
        const response = await fetch(
          this.url + "/administracion/lista_usuarios_all_lth",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.items = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } else {
        const response = await fetch(
          this.url + "/administracion/lista_usuarios_suc_lth",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              sucursal: this.sucursal,
            }),
          }
        );
        if (response.status == 200) {
          const value = await response.json();
          //console.log(value);
          this.items = value;
        } else {
          const value = await response.json();
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      }
      this.buscarBtnswitch = false;
    },
    async guardarUsuario() {
      if (this.$refs.form.validate()) {
        if (this.nuevoSwitch == "NEW") {
          const response = await fetch(
            this.url + "/administracion/nuevo_usuario_lth",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-access-token": this.token,
              },
              body: JSON.stringify({
                sucursal: this.suctemp,
                nombre: this.nombre,
                usuario: this.usuario,
                password: this.password,
                activo: this.activo ? 1 : 0,
              }),
            }
          );
          if (response.status == 200) {
            const value = await response.json();
            //console.log(value);
          } else {
            const value = await response.json();
            let body = {
              status: response.status,
              mensaje: value.mensaje,
            };
            this.$store.dispatch("responseError", body);
          }
        } else {
          const response = await fetch(
            this.url + "/administracion/cambiar_usuario_lth",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-access-token": this.token,
              },
              body: JSON.stringify({
                idTec: this.idtec,
                sucursal: this.suctemp,
                nombre: this.nombre,
                usuario: this.usuario,
                password: this.password,
                activo: this.activo ? 1 : 0,
              }),
            }
          );
          if (response.status == 200) {
            const value = await response.json();
            //console.log(value);
          } else {
            const value = await response.json();
            let body = {
              status: response.status,
              mensaje: value.mensaje,
            };
            this.$store.dispatch("responseError", body);
          }
        }
      }
      this.nuevoSwitch = "NONE";
      this.$refs.form.reset();
      this.cleanup();
      this.summonUsuarios();
    },
    AbrirUltimaUb(item) {
      this.dmapa = true;
      this.skeleton = true;
      if (this.marker) {
        this.marker.remove();
      }
      setTimeout(() => {
        this.skeleton = false;
        this.initializeMap();
        this.marker = new mapboxgl.Marker()
          .setLngLat([item.ultimaUbicacionLng, item.ultimaUbicacionLat])
          .addTo(this.map);
        this.map.setCenter([item.ultimaUbicacionLng, item.ultimaUbicacionLat]);
      }, 800);
    },
    initializeMap() {
      const div = this.$refs.mapContainer;
      mapboxgl.accessToken = this.TokenMap;
      this.map = new mapboxgl.Map({
        container: div,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [this.userInfo.Longitud, this.userInfo.Latitud],
        zoom: 16,
        projection: "globe",
      });
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 36px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
@media (max-width: 700px) {
  #map {
    height: 50vh !important;
  }
}
.mapboxgl-popup {
  max-width: 200px;
}
</style>
