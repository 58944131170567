<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-row justify="center">
      <v-col cols="4" style="font-size: 12px"> </v-col>
      <v-col cols="4" style="font-size: 17px">
        <v-row justify="center"> Jomar Industrias S.A. de C.V. </v-row>
        <v-row justify="center">
          <h1>Cotizaciones</h1>
        </v-row>
      </v-col>
      <v-col cols="4" style="font-size: 12px">
        <v-row style="height: 40px"></v-row>
        <v-row justify="end">FECHA: {{ dateNow() }}</v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="cotizaciones"
          class="row-height-50 elevation-1"
          :items-per-page="cotizaciones.length"
          hide-default-footer
          :loading="loading"
          loading-text="Cargando... Por favor, espere"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    headers: [
      { text: "Suc", value: "Sucursal", align: "start", sortable: false },
      {
        text: "Folio",
        value: "FolioCotizacion",
        align: "center",
        sortable: false,
      },
      { text: "Fecha", value: "Fecha", align: "center", sortable: false },
      { text: "Cliente", value: "CusNo", align: "center", sortable: false },
      { text: "Desc", value: "Descuento", align: "center", sortable: false },
      {
        text: "Pedido",
        value: "FolioPedido",
        align: "center",
        sortable: false,
      },
      {
        text: "Fecha Ped",
        value: "FechaPed",
        align: "center",
        sortable: false,
      },
      { text: "CondPag", value: "CondPago", align: "center", sortable: false },
      {
        text: "Razon Social",
        value: "RazonSocial",
        align: "center",
        sortable: false,
      },
      { text: "Vendedor", value: "Vendedor", align: "center", sortable: false },
      { text: "Articulo", value: "ItemNo", align: "center", sortable: false },
      {
        text: "Cantidad",
        value: "CantPedida",
        align: "center",
        sortable: false,
      },
      { text: "Precio", value: "PrecioUnit", align: "center", sortable: false },
      {
        text: "%Desc",
        value: "PctjeDescuento",
        align: "center",
        sortable: false,
      },
      {
        text: "Descripcion",
        value: "Descripcion",
        align: "center",
        sortable: false,
      },
      {
        text: "MotivoCierre",
        value: "MotivoCierreCotizacion",
        align: "center",
        sortable: false,
      },
      {
        text: "FechaCierre",
        value: "FechaCierre",
        align: "center",
        sortable: false,
      },
      { text: "Usr Cierre", value: "Nombre", align: "center", sortable: false },
    ],
    loading: false,
    cotizaciones: [],
  }),
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    dateNow() {
      return moment().format("DD/MM/YYYY HH:mm");
    },
    downloadexcel() {
      let arr = this.cotizaciones;
      let data = [
        {
          sheet: "Cotizaciones",
          columns: [
            { label: "Suc", value: "Sucursal" },
            { label: "Folio", value: "FolioCotizacion" },
            { label: "Fecha", value: "Fecha" },
            { label: "Cliente", value: "CusNo" },
            { label: "Desc", value: "Descuento" },
            { label: "Pedido", value: "FolioPedido" },
            { label: "Fecha Ped", value: "FechaPed" },
            { label: "CondPag", value: "CondPago" },
            { label: "Razon Social", value: "RazonSocial" },
            { label: "Vendedor", value: "Vendedor" },
            { label: "Articulo", value: "ItemNo" },
            { label: "Cantidad", value: "CantPedida" },
            { label: "Precio", value: "PrecioUnit" },
            { label: "%Desc", value: "PctjeDescuento" },
            { label: "Descripcion", value: "Descripcion" },
            { label: "MotivoCierre", value: "MotivoCierreCotizacion" },
            { label: "FechaCierre", value: "FechaCierre" },
            { label: "Usr Cierre", value: "Nombre" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "Cotizaciones", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const response = await fetch(this.url + "/ventas/rpt_cotizaciones", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(this.bodyForFetch),
      });
      //console.log(response);
      const obj = await response.json();
      this.loading = false;
      //console.log(obj);
      obj.resultado.forEach((element) => {
        element.Fecha = moment(element.Fecha).format("DD/MM/YYYY HH:mm");
        element.FechaPed =
          element.FechaPed == null
            ? ""
            : moment(element.FechaPed).format("DD/MM/YYYY HH:mm");
        element.FechaCierre =
          element.FechaCierre == null
            ? ""
            : moment(element.FechaCierre).format("DD/MM/YYYY HH:mm");
      });
      this.cotizaciones = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
