<template lang="">
  <v-container>
    <div class="noPrint">
      <v-row>
        <v-col cols="6" style="padding-left: 0">
          <v-btn block v-on:click="downloadexcel()">
            Exportar <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block v-on:click="print()">
            Imprimir <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center" style="font-size: 17px"
      >Reporte Diferencias de Cascos</v-row
    >
    <v-row justify="start" style="font-size: 12px"
      >Desde: {{ formatDate(bodyForFetch.fechaIni) }}
    </v-row>
    <v-row justify="start" style="font-size: 12px"
      >Sucursal: {{ bodyForFetch.sucursal }}
    </v-row>
    <v-row justify="start" style="font-size: 12px"
      >Articulo: {{ bodyForFetch.articulo }}
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="information"
          item-key="Fecha"
          sort-by="Fecha"
          class="row-height-50 elevation-1"
          show-group-by
          :items-per-page="information.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let xlsx = require("json-as-xlsx");
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data() {
    return {
      dateNow: moment().format("DD/MM/YYYY hh:mm:ss A"),
      headers: [
        { text: "Fecha", value: "Fecha", align: "center" },
        { text: "Ajuste Ant", value: "AjusteAnt", align: "center" },
        { text: "Ajuste", value: "Ajuste", align: "center" },
        { text: "Diferencia", value: "Dif", align: "center" },
      ],

      information: [],
    };
  },
  computed: {
    ...mapState(["url", "token", "bodyForFetch"]),
  },
  methods: {
    formatDate(fecha) {
      return moment(fecha).format("DD/MM/YYYY");
    },
    downloadexcel() {
      let arr = this.information;

      let data = [
        {
          sheet: "DiferenciasCascos",
          columns: [
            { label: "Fecha", value: "Fecha", align: "center" },
            { label: "Ajuste Ant", value: "AjusteAnt", align: "center" },
            { label: "Ajuste", value: "Ajuste", align: "center" },
            { label: "Diferencia", value: "Dif", align: "center" },
          ],
          content: arr,
        },
      ];
      let settings = {
        fileName: "DiferenciasCascos", // Name of the resulting spreadsheet
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    print() {
      window.print();
    },
  },
  async mounted() {
    try {
      const response = await fetch(
        this.url +
          `/traspasos/diferencias_cascos?sucursal=${this.bodyForFetch.sucursal}&fechaIni=${this.bodyForFetch.fechaIni}&articulo=${this.bodyForFetch.articulo}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      //console.log(response);
      const obj = await response.json();
      //console.log(obj);
      this.information = obj.resultado;
    } catch (error) {
      //console.log(error);
    }
    for (var counter = 0; counter < this.information.length; counter++) {
      this.information[counter].Fecha = this.information[counter].Fecha.replace(
        "T00:00:00.000Z",
        ""
      );
      this.information[counter].AjusteAnt =
        this.information[counter].AjusteAnt.toFixed(2);
      this.information[counter].Ajuste =
        this.information[counter].Ajuste.toFixed(2);
      this.information[counter].Dif = this.information[counter].Dif.toFixed(2);
    }
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
