<template lang="">
  <v-container>
    <v-row><h1>Reportes Inventario Articulos</h1></v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="1">Sucursal: </v-col>
              <v-col>
                <sucursal-simple></sucursal-simple>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">Fecha Inicial: </v-col>
              <v-col cols="11">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">Fecha Final: </v-col>
              <v-col cols="11">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Picker in menu"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu3 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu3.save(date1)"
                      >OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="existencia"
                  label="Existencia 0"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block @click="abrirReporteConteo()"
                  >Imprimir Contado
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  :disabled="!existencia"
                  @click="abrirReporteNoConteo()"
                  >Imprimir No Contado
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState } from "vuex";
export default {
  components: {
    sucursalSimple,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    existencia: false,
  }),
  computed: {
    ...mapState(["sucursales", "userInfo", "sucSelected"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  methods: {
    abrirReporteConteo() {
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        strFechaIn: this.date.replaceAll("-", ""),
        strFechaFin:
          this.date == this.date1
            ? this.addDay()
            : this.date1.replaceAll("-", ""),
        intCheck: 1,
      };
      let routeData = this.$router.resolve({
        name: "ReporteArticulosContado",
      });
      window.open(routeData.href, "_blank");
    },
    abrirReporteNoConteo() {
      this.bodyForFetch = {
        strSucursal: this.sucSelected,
        intExistencia: this.existencia ? 1 : 0,
        strFechaIn: this.date.replaceAll("-", ""),
        strFechaFin:
          this.date == this.date1
            ? this.addDay()
            : this.date1.replaceAll("-", ""),
        intCheck: 1,
      };
      let routeData = this.$router.resolve({
        name: "ReporteArticulosNoContado",
      });
      window.open(routeData.href, "_blank");
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      //console.log(datef);
      this.date1 = datef;
      datef = datef.replaceAll("-", "");

      return datef;
    },
  },
  async mounted() {
    this.$store.commit("setNavbarToggle", 0);
    await this.$store.dispatch("getSucursales", false);
    this.$store.commit("setsucSelected", this.userInfo.sucursal);
    this.$store.commit("setradioSelected", 0);
    this.$store.dispatch("getTipoMov");
    this.$store.dispatch("getArea");
  },
};
</script>

<style lang=""></style>
