<template lang="">
  <v-container>
    <v-row><h1>Permisos por Aplicación</h1></v-row>
    <v-row>
      <v-col cols="2" sm="1">Menu</v-col>
      <v-col cols="10" sm="11"
        ><v-autocomplete
          dense
          outlined
          solo
          filled
          hide-details
          v-model="menu"
          :items="menuItems"
          item-text="Text"
          @change="fillTitulos(menu)"
        ></v-autocomplete
      ></v-col>
    </v-row>
    <v-row><br /></v-row>
    <v-row>
      <v-col cols="2" sm="1">Titulo</v-col>
      <v-col cols="10" sm="11"
        ><v-autocomplete
          dense
          outlined
          solo
          filled
          hide-details
          v-model="titulo"
          :items="tituloItems"
          item-text="Text"
          :disabled="tituloDisable"
          @change="fillLists(titulo)"
        ></v-autocomplete
      ></v-col>
    </v-row>
    <v-row><br /></v-row>
    <v-row align="center">
      <v-col cols="5">
        <v-row justify="center">
          <template>
            <v-card class="mx-auto" min-width="95%" tile>
              <v-list dense>
                <v-subheader>Usuarios sin permisos</v-subheader>
                <v-list-item-group v-model="selectedItemA" color="primary">
                  <v-list-item v-for="(item, i) in itemsSin" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </template>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row justify="center" min-width="90%"
          ><v-btn block @click="moverSinaCon(selectedItemA)">{{
            boton1text
          }}</v-btn></v-row
        >
        <v-row justify="center" min-width="90%"
          ><v-btn block @click="moverConaSin(selectedItemB)">{{
            boton2text
          }}</v-btn></v-row
        >
      </v-col>
      <v-col cols="5">
        <v-row justify="center">
          <template>
            <v-card class="mx-auto" min-width="95%" tile>
              <v-list dense>
                <v-subheader>Usuarios con permisos</v-subheader>
                <v-list-item-group v-model="selectedItemB" color="primary">
                  <v-list-item v-for="(item, i) in itemsCon" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    menu: "",
    menuItems: [],
    titulo: "",
    tituloItems: [],
    tituloDisable: true,
    idUsuario: 0,
    boton1text: ">>",
    boton2text: "<<",
    selectedItemA: -1,
    itemsSin: [],
    selectedItemB: -1,
    itemsCon: [],
    strTitulo: 0,
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
  },
  methods: {
    async fillTitulos(menu) {
      var menuTemp = "";
      var strMenu = 0;
      for (var i = 0; i < 4; i++) {
        menuTemp += menu[i];
      }
      strMenu = parseInt(menuTemp);
      const response = await fetch(this.url + "/administracion/traer_titulo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify({
          menu: strMenu,
        }),
      });
      if (response.status == 200) {
        const value = await response.json();
        this.tituloItems = value;
        this.tituloDisable = false;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    fillLists(titulo) {
      var tituloTemp = "";

      for (var i = 0; i < 4; i++) {
        tituloTemp += titulo[i];
      }
      this.strTitulo = parseInt(tituloTemp);
      this.ListNoPermiso(tituloTemp);
      this.ListSiPermiso(tituloTemp);
      this.selectedItemA = -1;
      this.selectedItemB = -1;
    },
    async ListNoPermiso(tituloTemp) {
      this.itemsSin = [];
      const response = await fetch(
        this.url + "/administracion/llena_lista_no_permiso",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            titulo: tituloTemp,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.itemsSin = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async ListSiPermiso(tituloTemp) {
      this.itemsConn = [];
      const response = await fetch(
        this.url + "/administracion/llena_lista_permiso",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            titulo: tituloTemp,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.itemsCon = value;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async seleccionaID(elemento) {
      var placeholder = elemento;
      const response = await fetch(
        this.url + "/administracion/selecciona_id_usuario",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            elemento: placeholder,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        this.idUsuario = value[0].usuario_id;
        //console.log(this.idUsuario);
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async moverSinaCon(itemlistaSinPermiso) {
      var item = this.itemsSin[itemlistaSinPermiso].text;
      this.seleccionaID(item);
      const response = await fetch(
        this.url + "/administracion/inserta_permiso_usuario",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idUsuario: this.idUsuario,
            idAplicacion: this.strTitulo,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log("Se ha dado permiso");
        this.fillLists(this.titulo);
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async moverConaSin(itemlistaConPermiso) {
      var item = this.itemsCon[itemlistaConPermiso].text;
      await this.seleccionaID(item);
      const response = await fetch(
        this.url + "/administracion/elimina_permiso_usuario",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idUsuario: this.idUsuario,
            idAplicacion: this.strTitulo,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log("Se ha eliminado permiso");
        this.fillLists(this.titulo);
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
  },
  async mounted() {
    const response = await fetch(this.url + "/administracion/traer_menu", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": this.token,
      },
    });
    if (response.status == 200) {
      const value = await response.json();
      this.menuItems = value;
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      this.$store.dispatch("responseError", body);
    }
  },
};
</script>
<style scoped>
.v-list {
  height: 500px; /* or any height you want */
  overflow-y: auto;
}
@media (max-width: 600px) {
  #buttons {
    margin-top: 15px;
  }
}
</style>
