<template lang="">
  <v-container>
    <v-row>
      <v-col cols="8">Asistencia Motos</v-col>
      <v-col cols="4" align="end">
        <v-btn x-small @click="llenarTabla()">
          <v-icon dense>mdi-sync</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="repartidores"
          :mobile-breakpoint="0"
          hide-default-footer
          disable-pagination
          class="elevation-1"
        >
          <template v-slot:[`item.Boton`]="{ item }">
            <v-row>
              <v-col cols="12" sm="4" id="BotonCheckin"
                ><v-btn
                  block
                  :dark="item.veces == 0"
                  color="#003479"
                  :disabled="item.veces != 0"
                  @click="logData(item.idRepartidor, status1)"
                >
                  Check-In
                </v-btn></v-col
              >
              <v-col cols="12" sm="4"
                ><v-btn
                  block
                  :dark="item.veces == 1"
                  color="#003479"
                  :disabled="item.veces != 1"
                  @click="logData(item.idRepartidor, status2)"
                >
                  Check-Out
                </v-btn></v-col
              >
              <v-col cols="12" sm="4" id="BotonInci">
                <v-btn
                  block
                  :dark="item.veces != 2"
                  color="#003479"
                  :disabled="item.veces == 2"
                  @click="activaDialog(item)"
                >
                  Incidencia
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Incidencia </v-card-title>

        <v-card-text>
          <v-textarea v-model="problem"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="sendButton()"
            text
            @click="logData(dialogRepa, status3)"
          >
            Enviar
          </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dialog: false,
    problem: "",
    dialogRepa: "",
    status1: "checkin",
    status2: "checkout",
    status3: "incidencia",
    headers: [
      {
        text: "Repartidor",
        value: "nombre",
      },
      {
        text: "Acciones",
        value: "Boton",
      },
    ],
    repartidores: [],
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
  },
  async mounted() {
    await this.llenarTabla();
  },
  methods: {
    async llenarTabla() {
      try {
        const response = await fetch(
          this.url + "/administracion/trae_bitacora_repartidores",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Sucursal: this.userInfo.sucursal,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.repartidores = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },

    activaDialog(repartidor) {
      this.problem = "";
      this.dialogRepa = repartidor.idRepartidor;
      this.dialog = true;
    },
    async logData(idRepartidor, status) {
      //console.log(idRepartidor);
      //console.log(status);
      //console.log(this.problem);
      try {
        const response = await fetch(
          this.url + "/administracion/cambia_bitacora_repartidores",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              idRepa: idRepartidor,
              tipoIn: status,
              descripcionIn: this.problem,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
      this.problem = "";
      this.dialog = false;
      this.llenarTabla();
    },
    sendButton() {
      if (this.problem.length) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  #BotonCheckin {
    margin-top: 12px;
  }
  #BotonInci {
    margin-bottom: 12px;
  }
}
</style>
