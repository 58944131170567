<template>
  <div>
    <v-container>
      <v-row><h1>Consulta de Peticiones LTH</h1></v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row align="center">
                <v-col md="2" sm="6" cols="12">
                  <v-row><v-col>Sucursal</v-col></v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        :items="sucList"
                        dense
                        v-model="sucursal"
                        hide-details
                        :disabled="deshabilitarSucursal"
                        @change="summonUsuarios()"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="2" sm="6" cols="12">
                  <v-row><v-col>Tecnicos</v-col></v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        dense
                        v-model="idTec"
                        hide-details
                        :items="tecnicos"
                        :disabled="sucursal == ''"
                        item-text="nombre"
                        item-value="idTecnico"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <v-row><v-col>Rango de Fechas (Fecha de Alta)</v-col></v-row>
                  <v-row>
                    <v-col sm="2" cols="3">Fecha Inicial: </v-col>
                    <v-col sm="4" cols="9">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Picker in menu"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm="2" cols="3">Fecha Final: </v-col>
                    <v-col sm="4" cols="9">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="date1"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date1"
                            label="Picker in menu"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date1" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu3.save(date1)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="2" sm="6" cols="12">
                  <v-row><v-col>Estatus</v-col></v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        dense
                        v-model="idEstatus"
                        hide-details
                        :items="Estatus"
                        item-text="descripcion"
                        item-value="idEstatus"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2" sm="12" cols="12">
                  <v-btn
                    block
                    :disabled="idTec === ''"
                    @click="buscarPeticiones()"
                    >buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            class="row-height-50 elevation-1"
            :mobile-breakpoint="0"
            :items-per-page="50"
            :item-class="itemRowBackground"
          >
            <template v-slot:item.acciones="{ item }">
              <v-btn
                class="tablebutton"
                small
                dark
                @click="notas(item.idPeticion)"
                >notas tecnicos
              </v-btn>
              <v-btn
                class="tablebutton"
                small
                dark
                @click="info(item.idPeticion)"
                >info cliente
              </v-btn>
              <v-btn
                v-if="item.descripcion === 'Eliminado'"
                class="tablebutton"
                small
                dark
                @click="reactivar(item.idPeticion)"
                >Reactivar
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="notasCard" max-width="300px">
      <v-card>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text>
          <template>
            <v-row class="lst">Folio Servicio: {{ note.folio }}</v-row>
            <v-row class="lst">Servicios:</v-row>
            <v-row class="lst" v-for="servicio in note.serv" :key="servicio">
              - {{ servicio }}
            </v-row>
            <v-row class="lst">Notas: {{ note.notas }}</v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoCard" max-width="400">
      <v-card>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text>
          <v-row class="lst">Nombre de Cliente: {{ inf.nombre }}</v-row>
          <v-row class="lst">Telefono: {{ inf.tp }}</v-row>
          <v-row class="lst">Carro: {{ inf.car }}</v-row>
          <v-row class="lst">Direccion:</v-row>
          <v-row class="lst">{{ inf.dir }}</v-row>
          <v-row class="lst">Entre Calles: {{ inf.ntrcalles }}</v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
var moment = require("moment");
import { mapState } from "vuex";
export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    sucursal: "",
    idTec: "",
    items: [],
    headers: [
      { text: "#Peti", value: "idPeticion" },
      { text: "Folio", value: "folioManual" },
      { text: "Suc.", value: "sucursal" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Asigno", value: "NombreUsuario" },
      { text: "Estatus", value: "descripcion" },
      { text: "Tecnico", value: "NombreTecnico" },
      { text: "Programacion", value: "Programacion" },
      { text: "Fecha Alta", value: "fechaAlta" },
      { text: "Fecha Asignada", value: "fechaAsignada" },
      { text: "Fecha Inicio", value: "fechaInicio" },
      { text: "Fecha Finalizo", value: "fechaFinalizo" },
      { text: "", value: "acciones" },
    ],
    sucList: ["TODOS", "LEO", "LT3", "LT4"],
    tecnicos: [],
    servScrpt: "",
    servList: [],
    note: {
      folio: "",
      serv: [],
      notas: "",
    },
    inf: {
      tp: 0,
      nombre: "",
      calle: "",
      ntrcalles: "",
      colon: "",
      estamuni: "",
      num: "",
      ref: "",
      car: "",
      dir: "",
    },
    infoCard: false,
    notasCard: false,
    deshabilitarSucursal: false,
    idEstatus: 0,
    Estatus: [
      { idEstatus: 0, descripcion: "Todos" },
      { idEstatus: 1, descripcion: "Pendientes" },
      { idEstatus: 3, descripcion: "Finalizados" },
      { idEstatus: 4, descripcion: "Eliminados" },
      { idEstatus: 5, descripcion: "Cliente Acude" },
    ],
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
  },
  mounted() {
    this.sucursal = this.userInfo.sucursal;
    if (!this.userInfo.ESGLOBAL) {
      this.deshabilitarSucursal = true;
    }
    this.summonUsuarios();
  },
  methods: {
    async summonUsuarios() {
      if (this.sucursal == "TODOS") {
        const response = await fetch(
          this.url + "/administracion/lista_usuarios_all_lth",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.tecnicos.push({
            activo: null,
            idTecnico: 0,
            nombre: "TODOS",
            password: null,
            sucursal: null,
            ultimaUbicacionFecha: null,
            ultimaUbicacionLat: null,
            ultimaUbicacionLng: null,
            usuario: null,
          });
          for (var i = 0; i < value.resultado.length; i++) {
            this.tecnicos.push(value.resultado[i]);
          }
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } else {
        const response = await fetch(
          this.url + "/administracion/lista_usuarios_suc_lth",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              sucursal: this.sucursal,
            }),
          }
        );
        if (response.status == 200) {
          const value = await response.json();
          this.tecnicos = value;
          this.tecnicos.push({
            activo: null,
            idTecnico: 0,
            nombre: "TODOS",
            password: null,
            sucursal: null,
            ultimaUbicacionFecha: null,
            ultimaUbicacionLat: null,
            ultimaUbicacionLng: null,
            usuario: null,
          });
        } else {
          const value = await response.json();
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      }
    },
    addDay() {
      var datef = new Date(this.date1);
      datef.setDate(datef.getDate() + 1);
      datef = datef.toISOString().split("T")[0];
      //console.log(datef);
      //this.date1 = datef;
      datef = datef.replaceAll("-", "");
      return datef;
    },
    itemRowBackground: function (item) {
      return item.descripcion == "Finalizado"
        ? "style-1"
        : item.descripcion == "Eliminado"
        ? "style-3"
        : item.descripcion == "Cliente Acude"
        ? "style-1"
        : "style-2";
    },
    async buscarPeticiones() {
      const response = await fetch(
        this.url + "/administracion/consultar_peticiones_lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            sucursal: this.sucursal == "TODOS" ? "" : this.sucursal,
            todossuc: this.sucursal == "TODOS" ? 1 : 0,
            idTec: this.idTec,
            todostecs: this.idTec == 0 ? 1 : 0,
            fechaini: this.date.replaceAll("-", ""),
            fechafin:
              this.date == this.date1
                ? this.addDay()
                : this.date1.replaceAll("-", ""),
            idEstatus: this.idEstatus,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        //console.log(value);
        for (var i = 0; i < value.length; i++) {
          value[i].fechaAlta = moment(value[i].fechaAlta)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss");
          value[i].fechaAsignada =
            value[i].fechaAsignada == "1900-01-01T00:00:00.000Z"
              ? ""
              : moment(value[i].fechaAsignada)
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss");
          value[i].fechaInicio =
            value[i].fechaInicio == "1900-01-01T00:00:00.000Z"
              ? ""
              : moment(value[i].fechaInicio)
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss");
          value[i].fechaFinalizo =
            value[i].fechaFinalizo == "1900-01-01T00:00:00.000Z"
              ? ""
              : moment(value[i].fechaFinalizo)
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss");
        }

        this.items = value;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async listaServiciosLTH() {
      try {
        const response = await fetch(
          this.url + "/repartos/traer_servicios_realizados",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.servList = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async notas(idPeticionItem) {
      const response = await fetch(
        this.url + "/administracion/trae_notas_tecnico",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeti: idPeticionItem,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        await this.listaServiciosLTH();
        var tempServlst = [];
        this.note.serv = [];
        this.note.folio = "";
        this.note.notas = "";
        tempServlst = value[0].servicioRealizadoTecnico.split(",");
        for (var i = 0; i < tempServlst.length - 1; i++) {
          for (var j = 0; j < 6; j++) {
            if (this.servList[j].IdServicio == tempServlst[i]) {
              this.note.serv.push(this.servList[j].Descripcion);
            }
          }
        }
        this.note.folio = value[0].folioServicio;
        this.note.notas = value[0].notasTecnicoLTH;
        this.notasCard = true;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async traerMunicipios(esta, IDmuni) {
      const response = await fetch(
        this.url + "/administracion/lista_municipios",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            abrev: esta,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        var muni = "";
        for (var i = 0; i < value.length; i++) {
          if (value[i].IdMunicipio == IDmuni) {
            muni = value[i].Descripcion;
          }
        }
        this.inf.estamuni = esta + ", " + muni;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async marcasdeVehiculo(marcaID, modelo, año, color) {
      const response = await fetch(
        this.url + "/administracion/marcas_vehiculos ",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        //console.log(value);
        var marca = "";
        for (var i = 0; i < value.length; i++) {
          if (value[i].MakeID == marcaID) {
            marca = value[i].MakeName;
          }
        }
        this.inf.car = marca + " " + modelo + " " + año + " " + color;
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async info(idPeticionItem) {
      const response = await fetch(
        this.url + "/administracion/trae_info_cliente",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeti: idPeticionItem,
          }),
        }
      );
      if (response.status == 200) {
        const value = await response.json();
        console.log(value);
        this.inf.nombre = value[0].nombreCliente;
        this.inf.tp = value[0].telefono;
        this.inf.calle = value[0].calle;
        this.inf.ntrcalles =
          value[0].entreCalles1 + " y " + value[0].entreCalles2;
        this.inf.colon = value[0].colonia;
        this.inf.num = value[0].numero;
        this.inf.ref = value[0].referencia;
        await this.traerMunicipios(value[0].estado, value[0].municipio);
        await this.marcasdeVehiculo(
          value[0].marca,
          value[0].modelo,
          value[0].año,
          value[0].color
        );
        this.inf.dir = value[0].direccion;
        this.infoCard = true;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async reactivar(idPeticion) {
      const response = await fetch(
        this.url + "/administracion/reactiva-peticion-lth",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idPeticion: idPeticion,
          }),
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        console.log(value);
        if (value[0].resultado == 1) {
          this.buscarPeticiones();
        } else {
          commit("snackbar", {
            snackbar: true,
            text: value[0].mensaje,
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
  },
};
</script>
<style>
.style-1 {
  background-color: rgb(166, 235, 157);
}
.style-2 {
  background-color: rgb(252, 247, 139);
}
.style-3 {
  background-color: rgb(230, 147, 147);
}
.style-1:hover {
  background-color: rgb(138, 214, 129) !important;
}
.style-2:hover {
  background-color: rgb(222, 217, 104) !important;
}
.style-3:hover {
  background-color: rgb(214, 126, 126) !important;
}
.tablebutton {
  margin: 2px;
}
</style>
