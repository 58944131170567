<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col xl="6" lg="6" md="8" cols="12" align="center" justify="center">
          <v-img
            max-height="160"
            max-width="160"
            src="../assets/logo-grupo-jomar.png"
          ></v-img>
        </v-col>
      </v-row>
      <h4>Política de privacidad</h4>
      <p>
        La aplicación JomarApp es propiedad de Jomar Industrias S.A. de C.V.,
        que es un controlador de datos de tus datos personales.
      </p>
      <p>
        Hemos adoptado esta Política de privacidad, que determina cómo
        procesamos la información recopilada por JomarApp, que también
        proporciona las razones por las que debemos recopilar ciertos datos
        personales sobre ti. Por lo tanto, debes leer esta Política de
        privacidad antes de usar la aplicación de JomarApp.
      </p>
      <p>
        Cuidamos tus datos personales y nos comprometemos a garantizar su
        confidencialidad y seguridad.
      </p>
      <h4>Información personal que recopilamos:</h4>
      <p>
        Cuando usas JomarApp, recopilamos automáticamente cierta información
        sobre tu dispositivo, incluida información sobre tu navegador web,
        dirección IP, zona horaria y algunas de las cookies instaladas en tu
        dispositivo. Además, a medida que navegas por el sitio, recopilamos
        información sobre las páginas web individuales o los productos que ves,
        qué sitios web o términos de búsqueda te remitieron al sitio y cómo
        interactúas con él. Nos referimos a esta información recopilada
        automáticamente como "Información del dispositivo". Además, podemos
        recopilar los datos personales que nos proporcionas (incluidos, entre
        otros, nombre, apellido, etc.) durante el registro para poder cumplir
        con el acuerdo.
      </p>
      <h4>¿Por qué procesamos tus datos?</h4>
      <p>
        Nuestra máxima prioridad es la seguridad de los datos del cliente y,
        como tal, podemos procesar solo los datos mínimos del usuario, solo en
        la medida en que sea absolutamente necesario para mantener la
        aplicación. La información recopilada automáticamente se utiliza solo
        para identificar casos potenciales de abuso y establecer información
        estadística sobre el uso de la aplicación. Esta información estadística
        no se agrega de tal manera que identifique a ningún usuario en
        particular del sistema.
      </p>
      <p>
        Puedes visitar el sitio sin decirnos quién eres ni revelar ninguna
        información por la cual alguien pueda identificarte como una persona
        específica. Sin embargo, si deseas utilizar algunas de las funciones del
        sitio web, o deseas recibir nuestro boletín informativo o proporcionar
        otros detalles al completar un formulario, puedes proporcionarnos datos
        personales, como tu correo electrónico, nombre, apellido, ciudad de
        residencia, organización y número de teléfono. Puedes optar por no
        proporcionar tus datos personales, pero es posible que no puedas
        aprovechar algunas de las funciones de la aplicación. Por ejemplo, no
        podrás recibir nuestro boletín ni contactarnos directamente desde el
        sitio web. Los usuarios que no estén seguros de qué información es
        obligatoria pueden ponerse en contacto con nosotros a través de
        ayuda@jomarmx.com.
      </p>
      <h4>Tus derechos:</h4>
      <p>
        Tienes los siguientes derechos relacionados con tus datos personales:
      </p>
      <ul>
        <li>El derecho a ser informado.</li>
        <li>El derecho de acceso.</li>
        <li>El derecho a la rectificación.</li>
        <li>El derecho a borrar.</li>
        <li>El derecho a restringir el procesamiento.</li>
        <li>El derecho a la portabilidad de datos.</li>
        <li>El derecho a oponerte.</li>
        <li>
          Derechos en relación con la toma de decisiones automatizada y la
          elaboración de perfiles.
        </li>
      </ul>
      <p>
        Si deseas ejercer este derecho, comunícate con nosotros a través de la
        información de contacto a continuación.
      </p>
      <h4>Seguridad de la información:</h4>
      <p>
        Aseguramos la información que proporcionas en servidores informáticos en
        un entorno controlado y seguro, protegido del acceso, uso o divulgación
        no autorizados. Mantenemos medidas de seguridad administrativas,
        técnicas y físicas razonables para proteger contra el acceso no
        autorizado, el uso, la modificación y la divulgación de datos personales
        bajo su control y custodia. Sin embargo, no se puede garantizar la
        transmisión de datos a través de Internet o redes inalámbricas.
      </p>
      <h4>Divulgación legal:</h4>
      <p>
        Divulgaremos cualquier información que recopilemos, usemos o recibamos
        si así lo requiere o lo permite la ley, como para cumplir con una
        citación o un proceso legal similar, y cuando creemos de buena fe que la
        divulgación es necesaria para proteger nuestros derechos, proteger tu
        seguridad o la seguridad de los demás, investigar el fraude o responder
        a una solicitud del gobierno.
      </p>
      <h4>Información de contacto:</h4>
      <p>
        Si deseas comunicarte con nosotros para comprender más sobre esta
        Política o deseas comunicarte con nosotros en relación con cualquier
        asunto sobre los derechos individuales y tu información personal, puedes
        enviarnos un correo electrónico a ayuda@jomarmx.com.
      </p>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
