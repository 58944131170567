<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <v-card-title><h2>Eventos de Pedido</h2></v-card-title>
          <v-card-title>Criterios</v-card-title>
          <v-row>
            <v-col cols="1">Status:</v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="statusSelect"
                :items="listaStatus"
                item-text="Estatus"
                item-value="Estatus"
                dense
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menuFechaInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaIn"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaIn"
                  @input="menuFechaInicio = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menuFechaFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaFn"
                    label="Fecha Fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFn"
                  @input="menuFechaFin = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">Vendedor:</v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="venselect"
                dense
                solo
                :items="itemsVend"
                item-text="Nombre"
                item-value="Nombre"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card>
          <v-card-title>Acciones</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="1">Cambio de Status:</v-col>
                <v-col cols="3">
                  <v-autocomplete
                    dense
                    solo
                    :items="itemsCambStatus"
                    item-text="Descripcion"
                    item-value="Descripcion"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-btn @click=""
                    ><v-icon dense>mdi-lightning-bolt</v-icon>Aplicar</v-btn
                  >
                </v-col>
                <v-col cols="2">
                  <v-btn v-on:click="print()"
                    ><v-icon dense>mdi-printer</v-icon>Imprimir</v-btn
                  >
                </v-col>
                <v-col cols="2">
                  <v-btn @click="llenarTabla()"
                    ><v-icon dense>mdi-magnify</v-icon>Buscar</v-btn
                  >
                </v-col>
                <v-col cols="2">
                  <v-btn @click=""
                    ><v-icon dense>mdi-exit-to-app</v-icon>Salir</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card-title>Historial de Registros</v-card-title>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headersLlam" :items="itemsPedido">
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import sucursalSimple from "@/components/sucursalSimple";
import { mapState, mapActions } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    statusSelect: "",
    fechaIn: "",
    fechaFn: "",
    venselect: "",
    menuFechaInicio: "",
    menuFechaFin: "",
    listaStatus: [],
    itemsVend: [],
    itemsCambStatus: [],
    itemsPedido: [],
    headersLlam: [
      {
        text: "Pedido",
        value: "Pedido",
      },
      {
        text: "Almacen",
        value: "Almacen",
      },
      {
        text: "Fecha",
        value: "Fecha",
      },
      {
        text: "Vendedor",
        value: "Vendedor",
      },
      {
        text: "Cliente",
        value: "Cliente",
      },
      {
        text: "Usuario",
        value: "Usuario",
      },
    ],
  }),
  computed: {
    ...mapState(["sucursales", "url", "userInfo", "token", "sucSelected"]),
    computedDateFormattedMomentjs() {
      return this.fechaIn ? moment(this.fechaIn).format("YYYY/MM/DD") : "";
    },
    computedDateFormattedMomentjss() {
      return this.fechaFn ? moment(this.fechaFn).format("YYYY/MM/DD") : "";
    },
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
  },
  async mounted() {
    this.traerListaVendedoresVigente();
    this.traerEstatus();
    this.traerEvento();
    this.fechaIn = this.printDate();
    this.fechaFn = this.printDateFn();
  },
  methods: {
    printDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    printDateFn: function () {
      return moment().format("YYYY-MM-DD");
    },
    async llenarTabla() {
      try {
        ////console.log(this.token);
        const response = await fetch(
          this.url + "/ventas/ReporteEventosPedido",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Evento: this.statusSelect,
              Vendedor: this.venselect,
              FechaIn: moment(this.fechaIn).format("YYYYMMDD"),
              FechaFn: moment(this.fechaFn).format("YYYYMMDD"),
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.itemsPedido = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async traerListaVendedoresVigente() {
      const response = await fetch(this.url + "/ventas/lista_vendedorvigente", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
      });
      const value = await response.json();
      //console.log(value);
      this.itemsVend = value.resultado;
      ////console.log(this.date);
    },
    async traerEstatus() {
      const response = await fetch(this.url + "/ventas/lista_estatus", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
      });
      const value = await response.json();
      //console.log(value);
      this.listaStatus = value.resultado;
      ////console.log(this.date);
    },
    async traerEvento() {
      const response = await fetch(this.url + "/ventas/lista_evento", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
      });
      const value = await response.json();
      //console.log(value);
      this.itemsCambStatus = value.resultado;
      ////console.log(this.date);
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 23px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}

.v-data-table.row-height-50 th {
  height: 26px !important;
  font-size: 11.5px !important;
  padding: 0 0 0 7px !important;
}
</style>
