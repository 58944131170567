<template>
  <div>
    <v-container>
      <h2>Asignación de Rutas</h2>
      <v-row>
        <v-col cols="12" xl="4" lg="4" md="6">
          <v-autocomplete
            v-model="repaSelect"
            :items="listRespas"
            dense
            item-text="usuario"
            return-object
            hide-details
            outlined
            label="Lista Repartidores"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            elevation="2"
            fab
            small
            dark
            :color="color"
            :loading="refresh"
            v-on:click="TraerPeticionesPendientes()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-data-table
            :headers="headersPendientes"
            :items="tablaPendientes"
            class="elevation-1"
            mobile-breakpoint="0"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                elevation="1"
                :disabled="!bloquearBoton"
                small
                :color="color"
                :dark="bloquearBoton"
                v-on:click="AsignaPeticion(item)"
              >
                Asignar
              </v-btn>
              <v-btn
                elevation="1"
                small
                :color="color"
                dark
                v-on:click="OpenDialog(item)"
              >
                Eliminar
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row ref="fila"> </v-row>
      <v-row>
        <v-col cols="12">
          <v-container id="map" style="height: 60vh"></v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dEliminar" max-width="400" persistent>
      <v-card>
        <v-card-title> Eliminar Petición </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="margin-top: 15px">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="motivo"
                label="Motivo"
                auto-grow
                outlined
                rows="3"
                row-height="25"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :color="color" text @click="dEliminar = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="color" text v-on:click="EliminarPeticion()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dAlerta" max-width="400" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="margin-top: 15px">
          <v-row>
            <v-col>
              <sweetalert-icon v-if="tipoAlerta" icon="success" />
              <sweetalert-icon v-else icon="error" />
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h2>{{ mensajeEliminacion }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :color="color" text v-on:click="refreshTable()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapState, mapActions } from "vuex";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
var moment = require("moment");
import ListPendRep from "@/components/ListPendRep.vue";
import Vue from "vue";

export default {
  name: "MonitorPetRepartidor",
  components: {
    ListPendRep,
    draggable,
  },
  data: () => ({
    listRespas: [],
    repaSelect: false,
    tablaPendientes: [],
    headersPendientes: [
      { text: "#Petición", value: "folioPeticion", sortable: false },
      { text: "Tipo Petición", value: "TipoPeticion", sortable: false },
      { text: "Folio", value: "folio", sortable: false },
      { text: "Destino", value: "destino", sortable: false },
      { text: "Fecha Alta", value: "fechaAlta", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    TokenMap:
      "pk.eyJ1Ijoiam9tYXJpbmR1c3RyaWFzIiwiYSI6ImNsNTlzY3llYTJmNWEza3FxcHJyMDZ4bjkifQ.GcFBgAtC6I4vN5eSH49LeA",
    lat: 0,
    lng: 0,
    map: null,
    refresh: false,
    bloquearBoton: false,
    listaRepPend: [],
    dEliminar: false,
    petAEliminar: null,
    motivo: "",
    dAlerta: false,
    tipoAlerta: false,
    mensajeEliminacion: "",
    markers: [],
  }),
  computed: {
    ...mapState(["url", "userInfo", "token", "secondColor", "color"]),
  },
  watch: {
    repaSelect(val) {
      ////console.log(val);
      if (val != null) {
        this.bloquearBoton = true;
        if (this.markers.length > 0) {
          for (const marker of this.markers) {
            marker.remove();
          }
          this.markers = [];
        }
        this.TraerPendientesRepartidor();
      } else {
        this.bloquearBoton = false;
        this.DeleteCompList();
      }
    },
  },
  async mounted() {
    this.TraerRepartidores();
    this.TraerPeticionesPendientes();
    mapboxgl.accessToken = this.TokenMap;
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.userInfo.Longitud, this.userInfo.Latitud],
      zoom: 16,
      projection: "globe",
    });
  },
  methods: {
    async TraerRepartidores() {
      try {
        ////console.log(this.token);
        const response = await fetch(
          this.url + "/administracion/traer_repartidores",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Sucursal: this.userInfo.sucursal,
              SoloActivos: 1,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.listRespas = value;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async TraerPeticionesPendientes() {
      try {
        this.tablaPendientes = [];
        this.refresh = true;
        const response = await fetch(
          this.url + "/administracion/consulta_peticiones_pendientes",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              sucursal: this.userInfo.sucursal,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        this.refresh = false;
        if (response.status == 200) {
          //console.log(value);
          if (value.length > 0) {
            value.forEach((obj) => {
              obj.fechaAlta = moment(obj.fechaAlta).format("DD/MM/YYYY HH:mm");
            });
            this.tablaPendientes = value;
          } else {
            this.tablaPendientes = [];
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontraron pendientes, intente mas tarde.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    async TraerPendientesRepartidor() {
      try {
        this.DeleteCompList();
        const response = await fetch(
          this.url + "/administracion/traer_pendientes_repartidor",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              idRepartidor: this.repaSelect.idRepartidor,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.listaRepPend = value;
            const propsData = {
              repaUser: this.repaSelect.usuario,
              list: value,
            };
            const childComponent = new Vue({
              render: (h) => h(ListPendRep, { props: propsData }),
            });

            childComponent.$mount();
            this.$refs.fila.appendChild(childComponent.$el);
            this.AgregarMarcasMapa();
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontraron pendientes.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    DeleteCompList() {
      const container = this.$refs.fila;

      while (container.firstChild) {
        const child = container.removeChild(container.firstChild);

        if (child.__vue__) {
          child.__vue__.$destroy();
        }
      }
    },
    async AsignaPeticion(peticion) {
      try {
        const response = await fetch(
          this.url + "/administracion/asignar_peticion",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              idRepartidor: this.repaSelect.idRepartidor,
              folioPeticion: peticion.folioPeticion,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          //console.log(value);
          this.TraerPeticionesPendientes();
          this.TraerPendientesRepartidor();
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    OpenDialog(item) {
      this.petAEliminar = item;
      this.motivo = "";
      this.dEliminar = true;
    },
    async EliminarPeticion() {
      //console.log(this.petAEliminar);
      //console.log(this.motivo);
      try {
        const response = await fetch(
          this.url + "/administracion/eliminar_peticion",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              folioPeticion: this.petAEliminar.folioPeticion,
              motivo: this.motivo,
              idUsuario: this.userInfo.idUsuario,
            }),
          }
        );
        //console.log(response);
        const value = await response.json();
        //console.log(value);
        if (response.status == 200) {
          this.dEliminar = false;
          this.motivo = "";
          this.petAEliminar = null;
          this.dAlerta = true;
          if (value[0].respuesta == 1) {
            this.tipoAlerta = true;
            this.mensajeEliminacion = value[0].mensaje;
          } else {
            this.tipoAlerta = false;
            this.mensajeEliminacion = value[0].mensaje;
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    refreshTable() {
      this.dAlerta = false;
      this.TraerPeticionesPendientes();
    },
    async AgregarMarcasMapa() {
      var marker;
      for (const peticion of this.listaRepPend) {
        if (peticion.destinoLng != 0 && peticion.destinoLat != 0) {
          marker = new mapboxgl.Marker()
            .setLngLat([peticion.destinoLng, peticion.destinoLat])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setText(
                `folio Petición: ${peticion.folioPeticion}`
              )
            )
            .addTo(this.map);
        } else {
          const localOrig = peticion.destino.replaceAll(" ", "%20");
          const origenResponse = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${localOrig}.json?access_token=${this.TokenMap}`
          );
          const origenInfo = await origenResponse.json();
          var lat = origenInfo.features[0].center[1];
          var lng = origenInfo.features[0].center[0];
          marker = new mapboxgl.Marker()
            .setLngLat([lng, lat])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setText(
                `folio Petición: ${peticion.folioPeticion}`
              )
            )
            .addTo(this.map);
        }
        this.markers.push(marker);
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 700px) {
  #map {
    height: 50vh !important;
  }
}
.mapboxgl-popup {
  max-width: 200px;
}
</style>
